import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import { App, Alert, Breadcrumb, Button, Col, Divider, Form, Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useManualQuery } from 'graphql-hooks'
import isEqual from 'lodash/isEqual'

import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../../../services/logger'
import { selectAuthCompanySlice } from '../../../../store/auth-company-slice'
import { selectFeatureFlagsSlice } from '../../../../store/feature-flags-slice'
import { useAppSelector } from '../../../../store/hooks'
import { getTeamById, getTotalTeamsAndUsers } from '../../../../graphql/custom-queries'

import IntlMessages from '../../../../util/IntlMessages'
import CircularProgress from '../../../../components/circular-progress'
import TeamForm from '../../../../components/team-form'
import { notificationStore } from '../../../../context/notificationsContext/store'
import { buildDepartmentTree } from '@vacationtracker/shared/functions/team-tree'
import { NUMBER_OF_TEAMS_LIMIT } from '@vacationtracker/shared/data/app-parameters'

import { IGetTeamByIdData, IGetTotalTeamsAndUsers, IGetUserListShort, ITeamEventV2, IEditTeamById, ITeamApproversWithLevel } from '../../../../types/teams'
import { SubscriptionPlan, SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { Platform } from '@vacationtracker/shared/data/platforms'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { ApproversLevelEnum, ITeamShort } from '@vacationtracker/shared/types/team'
import { TeamTree } from '@vacationtracker/shared/functions/team-tree/types'
import { FrontendUrls } from '../../../../types/urls'

interface ITeamsFormPage {
  match: {
    params: {
      id: string
    }
  }
}

const TeamsFormPage = ({ match }: ITeamsFormPage): React.ReactElement => {
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const history = useHistory()
  const { notification } = App.useApp()

  const [team, setTeam] = useState<IEditTeamById>({
    id: '',
    name: '',
    timestamp: '',
    users: [],
    approverIdsFirstLevel: [],
    approverIdsSecoundLevel: [],
    approvalLevel: ApproversLevelEnum.FIRST,
    isDefault: false,
    parentId: null,
  })
  const [teams, setTeams] = useState<ITeamShort[]>([])
  const [isTeamLoading, setTeamLoading] = useState(true)
  const [users, setUsers] = useState<IGetUserListShort[]>([])
  const [isLoading, setIsloading] = useState(true)
  const [totalTeams, setTotalTeam] = useState<number>(1)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [correlationId, setCorrelationId] = useState('')
  const [correlationExist, setCorrelationExist] = useState(false)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)

  const [ getTotalTeamsAndUsersQuery ] = useManualQuery<IGetTotalTeamsAndUsers>(getTotalTeamsAndUsers)
  const [ getTeamByIdQuery ] = useManualQuery<IGetTeamByIdData, { id: string }>(getTeamById)

  useEffect(() => {
    if (match.params.id) {
      fetchTeam(match.params.id)
    }
    fetchTotalTeamsAndUsers()
  }, [match.params.id])

  useEffect(() => {
    if (!correlationExist) {
      const collerationStatus = actionNotifications.includes(correlationId)
      setCorrelationExist(collerationStatus)
      setSubmitLoader(collerationStatus)
    }
    if (correlationExist && actionNotifications && !actionNotifications.includes(correlationId)) {
      setCorrelationId('')
      backToTeams()
    }
  }, [actionNotifications, correlationExist, correlationId])

  const removeChildrenAndFlatten = (teamTree: TeamTree[], targetParentId): ITeamShort[] => {
    const result: TeamTree[] = []

    const processItem = (item) => {
      // Check if the current item's parentId matches the targetParentId
      if (item.parentId === targetParentId) {
        return
      }

      // Add the current item to the result teamTree
      result.push({ ...item, children: [] } as TeamTree)

      // Recursively process children if they exist
      if (item.children && item.children.length > 0) {
        for (const child of item.children) {
          processItem(child)
        }
      }
    }

    for (const item of teamTree) {
      processItem(item)
    }

    return result.map(item => {
      return {
        id: item.id,
        name: item.name,
        parentId: item.parentId,
      } as ITeamShort
    })
  }

  const fetchTotalTeamsAndUsers = async () => {
    try {
      const response = await getTotalTeamsAndUsersQuery()
      if (!response.data || response.error) throw response.error

      if (match.params.id) {
        setTeams(removeChildrenAndFlatten(buildDepartmentTree(response.data.getTeamList), match.params.id))
      } else {
        setTeams(response.data.getTeamList)
      }
      setTotalTeam(response.data.getTeamList.length)
      setUsers(response.data.getUsersFiltered.users)
      setIsloading(false)
    } catch (err) { logger.error('error fetching total teams and users', err) }
  }

  const fetchTeam = async (teamId: string) => {
    try {
      const response = await getTeamByIdQuery({ variables: { id: teamId }})
      if (!response.data || response.error) throw response.error
      const team = response.data.getTeam
      if (team === null) {
        history.push('/app/settings/departments')
        return
      }
      const users = team.users.map(({ id }) => id)
      const approvers: ITeamApproversWithLevel[] = team.approvers && typeof team.approvers[0] === 'string' ?
        team.approvers.map(({ id }) => {
          return {
            userId: id,
            approverLevels: [ApproversLevelEnum.FIRST],
          }
        }) :
        team.approvers.map((approver) => {
          return {
            userId: approver.id,
            approverLevels: approver.approverLevels as ApproversLevelEnum[],
          }
        })

      setTeam({
        id: team.id,
        name: team.name,
        timestamp: team.timestamp,
        isDefault: team.isDefault,
        parentId: team.parentId,
        users,
        approverIdsFirstLevel: approvers.filter(approver => approver.approverLevels.includes(ApproversLevelEnum.FIRST)).map(approver => approver.userId),
        approverIdsSecoundLevel: approvers.filter(approver => approver.approverLevels.includes(ApproversLevelEnum.SECOND)).map(approver => approver.userId),
        approvalLevel: approvers.find(approver => approver.approverLevels.includes(ApproversLevelEnum.SECOND)) ? ApproversLevelEnum.SECOND : ApproversLevelEnum.FIRST,
      })
      form.resetFields()
      setTeamLoading(false)
    } catch (err) { logger.error('error fetching team', err) }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleErrorNotification = (error: any, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'notifications.handleSubmitError' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.notificationGeneral' }),
      description,
      duration: 0,
    })
  }

  const onFinish = async () => {
    let response
    try {
      const values = await form.validateFields()

      setSubmitLoader(true)
      const correlationIds: string[] = []

      if (team.name !== values.name || 
          !isEqual(team.approverIdsFirstLevel, values.approverIdsFirstLevel) || 
          !isEqual(team.approverIdsSecoundLevel, values.approverIdsSecoundLevel) || 
          !isEqual(team.users, values.users) || 
          !isEqual(team.isDefault, values.isDefault) || 
          !isEqual(team.parentId, values.parentId)
      ) {
        const approvers = values.approverIdsFirstLevel.map(approverId => {
          return {
            userId: approverId,
            approverLevels: [ApproversLevelEnum.FIRST],
          }
        })

        if (values?.approverIdsSecoundLevel) {
          values.approverIdsSecoundLevel.forEach((approverId) => {
            if (approvers.find(({ userId }) => userId === approverId)) {
              approvers.find(({ userId }) => userId === approverId).approverLevels.push(ApproversLevelEnum.SECOND)
            } else {
              approvers.push({
                userId: approverId,
                approverLevels: [ApproversLevelEnum.SECOND],
              })
            }
          })
        }

        const body: ITeamEventV2 = {
          eventType: match.params.id ? 'TEAM_UPDATED' : 'TEAM_CREATED',
          eventGroup: 'TEAM',
          name: values.name,
          approvers: approvers,
          isDefault: values.isDefault,
          users: values.users,
          ...(values.parentId && { parentId: values.parentId }),
          version: 2,
        }

        if (match.params.id) {
          body.teamId = match.params.id
        }
        response = await Api.post('/core/event', body)

        const correlationId: string = response.correlationId

        notification.open({
          key: correlationId,
          message: match.params.id ?
            formatMessage({ id: 'team.updateInProgress' }, { teamName: response.name }) :
            formatMessage({ id: 'team.createInProgress' }, { teamName: response.name }),
          icon: (<LoadingOutlined />),
          duration: 0,
        })

        correlationIds.push(correlationId)
        setCorrelationId(correlationId)
      }

      setActionNotifications([
        ...actionNotifications,
        ...correlationIds,
      ])
    } catch (error) {
      if (response?.correlationId) {
        handleErrorNotification(error, response.correlationId as string)
      }
      logger.error(error)
      setSubmitLoader(false)
      handleErrorNotification(error, undefined, formatMessage({ id: 'error.somethingWentWrong' }))
    }
  }

  const backToTeams = () => {
    history.push('/app/settings/departments')
  }

  const shouldDisableCreatingNewTeam = () => {
    if (featureFlags.includes(FeatureFlagEnum.noLimitTeams)) {
      return false
    }
    return authCompany?.plan === SubscriptionPlanEnum.core && totalTeams >= NUMBER_OF_TEAMS_LIMIT
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  return (
    <div className="main-content form-background">
      {((match.params.id && isTeamLoading) || isLoading) ?
        <CircularProgress /> :
        <>
          <div className="main-content-header">
            <div className="main-content-header-title" data-qa='create-department-title'>
              <span>
                {team.id ? team.name : <IntlMessages id="teamForm.createTeam" />}
              </span>
            </div>
            <div className="main-content-header-breadcrumb">
              <Breadcrumb
                items={[
                  {
                    title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
                  },
                  {
                    title: <IntlMessages id="sidebar.settings" />,
                  },
                  {
                    title: <Link to='/app/settings/departments'><IntlMessages id="app.departments" /></Link>,
                  },
                  {
                    title: team.id ? team.name : <IntlMessages id="teamForm.createTeam" />,
                  },
                ]}
              />
            </div>
          </div>
          <div className="main-content-body">
            <div className="form-layout">
              {!team.id && shouldDisableCreatingNewTeam() &&
                <Alert style={{ marginBottom: 20 }} 
                  message={
                    <IntlMessages 
                      id="teamForm.teamLimitReached" 
                      values={{ limitNumber: NUMBER_OF_TEAMS_LIMIT, billingPage: (...chunks) => (<Link to="/app/settings/billing">{chunks}</Link>) }}
                    />
                  }
                  type="warning" 
                />
              }
              <Form
                {...layout}
                form={form}
                layout="vertical"
                name="teamForm"
                initialValues={team}
                className="team-from"
                onFinish={() => { onFinish() }}
                disabled={!team.id && shouldDisableCreatingNewTeam()}
                size="large"
                requiredMark="optional"
                scrollToFirstError
              >
                <TeamForm 
                  team={team}
                  teams={teams}
                  totalTeams={totalTeams}
                  users={users}
                  formType={match.params.id ? 'update' : 'create'}
                  plan={authCompany?.plan as SubscriptionPlan}
                  platform={authCompany?.platform as Platform}
                  allowMultiLevelApproval={Boolean(featureFlags.includes(FeatureFlagEnum.multiLevelApproval))}
                />

                <Divider />

                <Row gutter={{
                  xs: 8,
                  sm: 16,
                  md: 24,
                  lg: 32,
                }}>
                  <Col span={24}>
                    <Form.Item style={{ textAlign: 'right' }}>
                      <Button className="button-right" type="default" onClick={backToTeams}>
                        <IntlMessages id="app.cancel" />
                      </Button>
                      <Button
                        className="button-right"
                        htmlType="submit"
                        type="primary"
                        loading={submitLoader}
                        data-qa='submit-new-department'
                      >
                        {team.id ? <IntlMessages id="teamForm.updateTeam" /> : <IntlMessages id="teamForm.createTeam" />}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default TeamsFormPage
