import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Typography, Button, Descriptions, Alert } from 'antd'
import { ContainerOutlined, EyeOutlined, ScheduleOutlined } from '@ant-design/icons'
import Api from '@vacationtracker/shared/services/api'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { FeedbackModal } from '@vacationtracker/shared/components/feedback-modal'
import { FrontendUrls } from '../../types/urls'
import { useAppSelector } from '../../store/hooks'
import { selectUserIdSlice } from '../../store/user-id-slice'
import { useIntl } from 'react-intl'
import { NotificationType } from '@vacationtracker/shared/types/notification'

const { Paragraph } = Typography

const ReportsPage = () => {

  const toggleFeedbackModal = () => setShowFeedbackModal(!showFeedbackModal)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const { userId } = useAppSelector(selectUserIdSlice)
  const { formatMessage } = useIntl()

  const sendFeedback = async (data) => { 
    await Api.post('/core/event', {
      eventGroup: 'FEEDBACK',
      eventType: 'USER_FEEDBACK',
      userId,
      ...data,
    })
    setShowFeedbackModal(false)
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id="sidebar.reports" />
        </div>
      </div>
      <div className="main-content-body" id="vt-cards">
        <Alert 
          message={formatMessage({id: 'components.reports.introPre'})}
          type="info"
          description={
            <Link to={`${FrontendUrls.export}#${NotificationType.leaveHistoryReport}`}>
              <Button type="primary" size="small"><IntlMessages id="components.reports.introOldOnes" /></Button>
            </Link>   
          }
        />
        <br />
        <Paragraph className='vt-cards-intro'><IntlMessages id="components.reports.intro" /></Paragraph>
        <div className='vt-cards-container'>
          <ReportDescription
            header={<IntlMessages id="export.leaveHistoryReport" />}
            content={<IntlMessages id="export.leaveHistoryReportDescription" />}
            path={`${FrontendUrls.reports}/leave-history-report`}
            actionText={<IntlMessages id="components.reports.generate"/>}
            actionIcon={<ContainerOutlined />}
          />
          <ReportDescription
            header={<IntlMessages id="export.leaveBalanceReport" />}
            content={<IntlMessages id="export.leaveBalanceReportDescription" />}
            path={`${FrontendUrls.reports}/leave-balance-report`}
            actionText={<IntlMessages id="components.reports.generate"/>}
            actionIcon={<ContainerOutlined />}
          />
          <ReportDescription
            header={<IntlMessages id="export.monthlyLeaveBalanceReport" />}
            content={<IntlMessages id="export.monthlyLeaveBalanceReportDescription" />}
            // eslint-disable-next-line max-len
            path={`${FrontendUrls.reports}/leave-balance-monthly-report`}
            actionText={<IntlMessages id="components.reports.generate"/>}
            actionIcon={<ContainerOutlined />}
          />
          <ReportDescription
            header={<IntlMessages id="components.reports.companyLogs" />}
            content={<IntlMessages id="components.reports.companyLogsDescription" />}
            path={FrontendUrls.companyLogs}
            actionText={<IntlMessages id="app.view"/>}
            actionIcon={<EyeOutlined />}
          />
          <ReportDescription
            header={<IntlMessages id="sidebar.scheduledReports" />}
            content={<IntlMessages id="components.reports.scheduledReportsDescription" />}
            path={FrontendUrls.scheduledReports}
            actionText={<IntlMessages id="scheduledReportForm.saveReport"/>}
            actionIcon={<ScheduleOutlined />}
          />
        </div>
        <Paragraph className='cards-feedback'>
          <IntlMessages id="components.reports.feedback" />
          <Button type='primary' onClick={toggleFeedbackModal}>
            <IntlMessages id='components.feedbackModal.button' />
          </Button>
        </Paragraph>
        <FeedbackModal visible={showFeedbackModal} onCancel={toggleFeedbackModal} onSave={sendFeedback}/>
      </div>
    </div>
  )
}

export default ReportsPage

const ReportDescription = ({ header, content, path, actionText, actionIcon }) => (
  <Link to={path} style={{ textDecoration: 'none' }}>
    <Card
      className='vt-card'
      hoverable
      key={path}
      actions={[<div key={path}>{actionIcon}&nbsp;{actionText}</div>]}
      title={header}
    >
      <Descriptions layout="horizontal" size="small" column={1}>
        <Descriptions.Item key="desc">
          {content}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  </Link>
)