import React, { useState } from 'react'
import sortBy from 'lodash/sortBy'
import ReportTable from '../../components/reports/table'
import ReportFilter from '../../components/reports/filters'
import ReportPeriodFilter from '../../components/reports/filter-period'
import ReportDisplay from '../../components/reports/display'
import { ILeaveBalanceReportProps } from './types'
import { leaveBalanceReportColumns, ReportPeriod } from '@vacationtracker/shared/types/export'
import BalancePeriod from './balance-period'
import { UserStatus } from '@vacationtracker/shared/types/user'
import { capitalize } from 'lodash'

const LeaveBalanceReport: React.FC<ILeaveBalanceReportProps> = ({
  companyData,
  defaultDates,
  data,
  total,
  page,
  loading,
  display,
  onFiltersChange,
  onPeriodChange,
  onDisplayChange,
  loadNext,
  loadPrevious,
  download,
}) => {

  const [showPeriodFilters, setShowPeriodFilters] = useState<boolean>(false)

  const filters = [
    { type: 'locationIds', options: companyData.getLocationList, label: 'Locations' },
    { type: 'departmentIds', options: companyData.getTeamList, label: 'Departments'},
    { type: 'labelIds', options: companyData.getLabels, label: 'Labels' },
    { type: 'userIds', options: companyData.getUsersFiltered.users, label: 'Users' },
    { type: 'leaveTypeIds', options: sortBy(companyData.getLeaveTypeList, 'position'), label: 'Leave Types' },
    { 
      type: 'userStatuses',
      options: (['ACTIVE', 'INACTIVE', 'DELETED'] as UserStatus[]).map(status => ({ id: status, name: capitalize(status) })),
      label: 'User Status',
    },
  ]

  const onBalancePeriodChange = (period: ReportPeriod) => {
    setShowPeriodFilters(period === 'CUSTOM_DAYS')
    onPeriodChange(defaultDates, period)
  }

  const groupBalancesByUserId = (data: any) => {
    const groupedData = {}

    data.forEach(item => {
      if (!groupedData[item.userId]) {
        groupedData[item.userId] = {
          user: item.user,
          id: item.userId,
          children: [],
        }
      }
      const { ...data } = item
      data.id = item.id
      groupedData[item.userId].children.push(data)
    })

    return Object.values(groupedData)
  }

  return (
    <div>
      <BalancePeriod onChange={onBalancePeriodChange} />
      {showPeriodFilters && <ReportPeriodFilter dateFormat={'YYYY-MM-DD'} defaultDates={defaultDates} onFilterChange={onPeriodChange}/>}
      <ReportDisplay defaultDisplay={display} onDisplayChange={onDisplayChange}/>
      <ReportFilter filters={filters} onFilterChange={onFiltersChange} defaultSelectedValues={{userStatuses: ['ACTIVE']}}/>
      <ReportTable 
        expandableKey={'user'}
        columnNames={leaveBalanceReportColumns}
        data={groupBalancesByUserId(data)}
        total={groupBalancesByUserId(data).length === 0 ? 0 : total}
        loadNext={loadNext}
        loadPrevious={loadPrevious}
        download={download}
        loading={loading}
        dateFormat={'YYYY-MM-DD'}
        display={display}
        page={page}
        reportType='leave-balance-report'
        editableColumns={true}
      />
    </div>
  )
}

export default LeaveBalanceReport
