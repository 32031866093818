import React, { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { camelCase } from 'lodash'
import IntlMessages from '../../util/IntlMessages'
import { IExportFilterProps, IReportFilterProps } from './types'
import FilterPopover from './filter-popover'

const { Text } = Typography

const ReportFilter: React.FC<IReportFilterProps> = ({ filters, onFilterChange, defaultSelectedValues = {} }) => {
  const [selectedValues, setSelectedValues] = useState<{ [key: string]: string[] }>(defaultSelectedValues)
  const { formatMessage } = useIntl()

  const handleApply = useCallback((filterType: string, values: string[]) => {
    const newSelectedValues = {
      ...selectedValues,
      [filterType]: values,
    }
    setSelectedValues(newSelectedValues)
    onFilterChange(newSelectedValues)
  }, [selectedValues, onFilterChange])

  const handleReset = useCallback((filterType: string) => {
    const newSelectedValues = {
      ...selectedValues,
      [filterType]: [],
    }
    setSelectedValues(newSelectedValues)
    onFilterChange(newSelectedValues)
  }, [selectedValues, onFilterChange])

  const handleClear = useCallback(() => {
    if (Object.keys(selectedValues).length === 0) return
    const newSelectedValues = {}
    setSelectedValues(newSelectedValues)
    onFilterChange(newSelectedValues)
  }, [selectedValues, onFilterChange])

  const renderFilter = (filterType: string, options: IExportFilterProps[], label: string) => (
    <div key={filterType} className='report-filter'>
      <FilterPopover
        filterType={filterType}
        options={options}
        label={label}
        selectedValues={selectedValues[filterType] || []}
        onApply={handleApply}
        onReset={handleReset}
        triggerIcon={
          <div className="popover-trigger-container">
            {selectedValues[filterType]?.length > 0 ? (
              <MinusCircleOutlined />
            ) : (
              <PlusCircleOutlined />
            )}
            <label>
              <IntlMessages id={`app.${camelCase(label)}`} />
              {selectedValues[filterType]?.length > 0 ? `(${selectedValues[filterType].length} ${formatMessage({id: 'app.selected'})})` : ''}
            </label>
          </div>
        }
      />
    </div>
  )

  return (
    <div id="report-filters-container" className="filters-container">
      {filters.map(({ type, options, label }) => (
        <div key={type} className="report-filter-wrapper">
          {renderFilter(type, options, label)}
        </div>
      ))}
      <div className='report-filter-wrapper'>
        <div className='report-filter' onClick={handleClear}>
          <div className="popover-trigger-container">
            <Text>
              <IntlMessages id="app.clearFilters" />
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportFilter
