import { findIndex } from 'lodash'
import { findOverlappingLeaves } from '../helpers'

export const findOverlaps = (data, addError) => {
  const overlaps = findOverlappingLeaves(data).overlappingPairs.flat()
  overlaps.forEach((overlap) => {
    const index = findIndex(data, overlap)
    if (index > -1) {
      const row = data[index]
      const error = `Leave date overlaps with existing leave from ${JSON.stringify(
        row.dateFrom
      )} to ${JSON.stringify(row.dateTo)}.`
      addError(index, 'dateFrom', { message: error, level: 'error' })
    }
  })
  return data
}

export const findDuplicateEmails = (data, addError) => {
  const emailMap: { [email: string]: any[] } = {}

  data.forEach((item) => {
    const email = item.email.toLowerCase()
    if (emailMap[email]) {
      emailMap[email].push(item)
    } else {
      emailMap[email] = [item]
    }
  })

  Object.values(emailMap).forEach((items) => {
    if (items.length > 1) {
      items.forEach((item) => {
        const index = data.findIndex(dataItem => dataItem.id === item.id)
        if (index > -1) {
          const error = `Duplicate email found: ${item.email}`
          addError(index, 'email', { message: error, level: 'error' })
        }
      })
    }
  })
  return data
}

export const findDuplicateLeaveTypes = (data, addError) => {
  const emailLeaveTypeMap: { [email: string]: any[] } = {}

  data.forEach((item) => {
    const emailLeaveType = `${item.email}-${item.leaveType}`
    if (emailLeaveTypeMap[emailLeaveType]) {
      emailLeaveTypeMap[emailLeaveType].push(item)
    } else {
      emailLeaveTypeMap[emailLeaveType] = [item]
    }
  })

  Object.values(emailLeaveTypeMap).forEach((items) => {
    if (items.length > 1) {
      items.forEach((item) => {
        const index = data.findIndex(dataItem => dataItem.id === item.id)
        if (index > -1) {
          const error = `Duplicate record found: ${item.email}`
          addError(index, 'email', { message: error, level: 'error' })
        }
      })
    }
  })
  return data
}

export const findDuplicateToils = (data, addError) => {

  function datesOverlap(dateFrom1: string, dateTo1: string, dateFrom2: string, dateTo2: string) {
    return (
      (new Date(dateFrom1) <= new Date(dateTo2)) &&
      (new Date(dateTo1) >= new Date(dateFrom2))
    )
  }

  // Group data by email and leaveType, and also store the index of each record
  const groupByEmailAndLeaveType = data.reduce((acc, record, index) => {
    const key = `${record.email}-${record.leaveType}`
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push({ record, index }) 
    return acc
  }, {})

  for (const key in groupByEmailAndLeaveType) {
    const records = groupByEmailAndLeaveType[key]

    for (let i = 0; i < records.length - 1; i++) {
      for (let j = i + 1; j < records.length; j++) {
        const record1 = records[i].record
        const record2 = records[j].record

        if (datesOverlap(record1.dateFrom as string, record1.dateTo as string, record2.dateFrom as string, record2.dateTo as string)) {
          const error = `Duplicate record found: ${record1.email}`
          // Add error to the specific indices of both overlapping records
          addError(records[i].index, 'dateFrom', { message: error, level: 'error' })
          addError(records[j].index, 'dateFrom', { message: error, level: 'error' })
        }
      }
    }
  }

  return data
}