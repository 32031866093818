import React from 'react'
import * as _ from 'lodash'
import { Button, Modal } from 'antd'
import IntlMessages from '../../util/IntlMessages'
import { CloseCircleOutlined, DownloadOutlined } from '@ant-design/icons'
import { ImportType } from '../../routes/ImportPage/types'

type TemplateModalProps = {
  isOpen: boolean
  templateFolderUrl: string
  importType: ImportType
  onClose: () => void
}

const TemplateModal = ({
  isOpen,
  templateFolderUrl,
  importType,
  onClose,
}: TemplateModalProps): React.ReactElement => {
  
  const getTemplatePath = (importType: ImportType, extension: string): string => {
    const fileMap: Record<ImportType, string> = {
      [ImportType.leaves]: 'leaves',
      [ImportType.details]: 'users-data',
      [ImportType.quotas]: 'quotas',
      [ImportType.toils]: 'leaves',
    }
    return `${templateFolderUrl}/vacation-tracker-import-${fileMap[importType]}-template.${extension}?v=1`
  }

  return (
    <Modal
      title={<IntlMessages id={'app.importFormatTitle'} />}
      open={isOpen}
      onCancel={onClose}
      closeIcon={<CloseCircleOutlined />}
      footer={null}
    >
      <div style={{ marginBottom: '10px' }}><IntlMessages id={'app.downloadTemplateAs'} /> :</div>
      <div>
        <Button href={getTemplatePath(importType, 'xlsx')} onClick={onClose} target='_blank' icon={<DownloadOutlined />} style={{ width: '90px', marginRight: '10px' }}>
          {<IntlMessages id='export.fileTypeExcelXlsx' />}
        </Button>
        <Button href={getTemplatePath(importType, 'csv')} onClick={onClose} target='_blank' icon={<DownloadOutlined />} style={{ width: '90px'}}>
          {<IntlMessages id='export.fileTypeCsv' />}
        </Button>
      </div>
    </Modal>
  )
}

export default TemplateModal
