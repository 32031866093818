import { isAfter, isBefore, isValid, parseISO } from 'date-fns'
import { CALCULATION_START_YEAR_LIMIT } from '../../constants/calculation-start-year'

export const validateCalculationStartYear = (year: string | undefined | null): boolean => {
  if (!year) {
    return false
  }

  try {
    const current = parseISO(year)
    if (!isValid(current)) {
      return false
    }
    return !(isAfter(current, new Date()) || isBefore(current, parseISO(CALCULATION_START_YEAR_LIMIT)))
  } catch {
    return false
  }
}