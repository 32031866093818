import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { App, Form, Button, Typography, Modal, Select, Radio, Spin, Tabs, Switch, RadioChangeEvent, Alert, TabsProps } from 'antd'
import Icon, { ExclamationCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { useManualQuery } from 'graphql-hooks'
import * as Sentry from '@sentry/react'
import { difference, find } from 'lodash'
import jwtDecode from 'jwt-decode'

import * as logger from '../../../services/logger'
import Api from '@vacationtracker/shared/services/api'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice, setAuthUser } from '../../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { notificationStore } from '../../../context/notificationsContext/store'
import { ThemeContext } from '../../../context/themeContext'
import { GoogleAuth } from '../../../services/auth/google/google'
import { MicrosoftAuth } from '../../../services/auth/microsoft/microsoft'
import { availableLanguages } from '@vacationtracker/shared/i18n'
import { selectLocaleSlice, setLocale } from '../../../store/locale-slice'
import { track } from '../../../services/analytics/analytics'

import { getConnectedCalendars, getLeaveTypesList, getOutOfOfficeSettings } from '../../../graphql/custom-queries'
import { getHourFormatBasedOnTimezone } from '@vacationtracker/shared/functions/convert-between-hour-formats'
import googleCalendarIconSvg from '../../../assets/images/google-calendar.svg'
import office365CalendarIconSvg from '../../../assets/images/microsoft-office365-outlook.svg'
import { getCognitoErrors } from '../../../components/mfa-code/errors'

import IntlMessages from '../../../util/IntlMessages'
import CircularProgress from '../../../components/circular-progress'
import { FrontendUrls } from '../../../types/urls'
import EnableMFAModal from '../../../components/mfa-enable-modal'
import SetPassword from '../../../components/set-password'
import ForgotPasswordModal from '../../../components/modal-forgot-password'
import { logout } from '../../../services/auth/logout-handler'

import { IGoogleOAuthTokens } from '@vacationtracker/shared/types/google'
import { IGetConnectedCalendarsResponse, IGetUserOutOfOfficeSettingsResponse } from '../../../types/custom-queries'
import { IConnectedGoogleCalendar, IConnectedOutlookCalendar, IGoogleCalendar, IOutlookCalendar } from '@vacationtracker/shared/types/calendar'
import { HourFormatEnum, IOutOfOfficeSettingsParams } from '@vacationtracker/shared/types/user'
import { EventTypeEnum, Platform } from '@vacationtracker/shared/types/core-event'
import { IGetLeaveTypesList } from '../../../types/leave-types'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { DARK_THEME, LIGHT_THEME } from '@vacationtracker/shared/constants/ThemeSetting'
import { ITokens } from '../../../types/microsoft'
import { getPreferredMFA, setupMfa, updateMfaPreference, updatePassword, verifyTotpToken } from '@vacationtracker/shared/services/auth'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'


const { Option } = Select

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID) {
  throw new Error('Client IDs are required')
}

interface IChangePasswordForm {
  oldPassword: string
  password: string
  repeatPassword: string
}

type SnoozedLeaveTypeOption = {
  label: string
  value: string
}

const { Paragraph, Text, Title } = Typography
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)
const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)

enum AccountPageActiveTab {
  settings = 'settings',
  preferences = 'preferences',
}

const AccountSettings: React.FC = () => {
  const {theme, setThemeHandler } = useContext(ThemeContext)
  const { modal, notification } = App.useApp()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const dispatch = useAppDispatch()
  const { locale } = useAppSelector(selectLocaleSlice)

  const [changePasswordForm] = Form.useForm<IChangePasswordForm>()
  const { formatMessage } = useIntl()
  const history = useHistory()

  // Posthog feature flags
  const enableSpanishLanguage = useFeatureFlagEnabled(FeatureFlagEnum.spanishLanguage)
  const enablePortugueseLanguage = useFeatureFlagEnabled(FeatureFlagEnum.portugueseLanguage)
  const enableGermanLanguage = useFeatureFlagEnabled(FeatureFlagEnum.germanLanguage)
  const enableItalianLanguage = useFeatureFlagEnabled(FeatureFlagEnum.italianLanguage)
  const enablePolishLanguage = useFeatureFlagEnabled(FeatureFlagEnum.polishLanguage)
  const enableTurkishLanguage = useFeatureFlagEnabled(FeatureFlagEnum.turkishLanguage)
  const enableSwedishLanguage = useFeatureFlagEnabled(FeatureFlagEnum.swedishLanguage)

  const [changePasswordSubmitLoader, setChangePasswordSubmitLoader] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [shouldReloadConnectedCalendars, setShouldReloadConnectedCalendars] = useState(false)
  const [forgotPasswordModalVisible, showForgotPasswordModal] = useState(false)

  const [showCalendarListModal, setShowCalendarListModal] = useState(false)
  const [googleCalendarList, setGoogleCalendarList] = useState<IGoogleCalendar[] | null>(null)
  const [googleCalendarTokens, setGoogleCalendarTokens] = useState<Pick<IGoogleOAuthTokens, 'accessToken' | 'refreshToken'> | null>(null)
  const [connectedGoogleCalendar, setConnectedGoogleCalendar] = useState<IConnectedGoogleCalendar | string| null>(null)
  const [selectedGoogleCalendar, setSelectedGoogleCalendar] = useState<IGoogleCalendar | null>(null)

  const [outlookCalendarList, setOutlookCalendarList] = useState<IOutlookCalendar[] | null>(null)
  const [selectedOutlookCalendar, setSelectedOutlookCalendar] = useState<IOutlookCalendar | null>(null)
  const [outlookCalendarTokens, setOutlookCalendarTokens] = useState<{accessToken: string; refreshToken: string; idToken: string} | null>(null)
  const [connectedOutlookCalendar, setConnectedOutlookCalendar] = useState<IConnectedOutlookCalendar | string | null>(null)
  const [preferredOutlookTimezone, setPreferredOutlookTimezone] = useState<string | null>(null)

  const [userOutOfOfficeSettings, setUserOutofOfficeSettings] = useState<IOutOfOfficeSettingsParams | null>()
  const [leaveTypes, setLeaveTypes] = useState<any[]>([])
  const {actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [isOutOfOfficeActive, setIsOutOfOfficeActive] = useState(true)
  const [isUpdatingUserOutOfOfficeSettings, setIsUpdatingUserOutOfOfficeSettings] = useState(false)
  const [isOutOfOfficeSettingsLoading, setIsOutOfOfficeSettingsLoading] = useState(false)
  const [activeOutOfOfficeLeaveTypes, setActiveOutOfOfficeLeaveTypes] = useState<SnoozedLeaveTypeOption[]>([])
  const [showChangeOOOModal, setShowChangeOOOModal] = useState(false)
  const [newOOOEmail, setnewOOOEmail] = useState('')
  const [googleUser, setGoogleUser] = useState('')
  const [googleTokens, setGoogleTokens] = useState<IGoogleOAuthTokens | null>(null)
  const [googleTimezone, setGoogleTimezone] = useState('')
  const [microsoftTokens, setMicrosoftTokens] = useState<ITokens | null>(null)
  const [microsoftTimezone, setMicrosoftTimezone] = useState('')
  const [msTenantId, setMsTenantId] = useState('')

  const [qrCodeData, setQRCodeData] = useState<string|null>(null)
  const [userEnabledMFA, setUserEnabledMFA] = useState<boolean>(false)
  const [mfaActionInProgress, setMfaActionInProgress] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState(sessionStorage.getItem('activeAccountPageTab') || AccountPageActiveTab.settings)

  const [ getOutOfOfficeSettingsQuery ] = useManualQuery<IGetUserOutOfOfficeSettingsResponse, { userId: string }>(getOutOfOfficeSettings)
  const [ getLeaveTypesListQuery ] = useManualQuery<IGetLeaveTypesList>(getLeaveTypesList)
  const [ getConnectedCalendarsQuery ] = useManualQuery<IGetConnectedCalendarsResponse, { userId: string }>(getConnectedCalendars)

  useEffect(() => {
    if (shouldReloadConnectedCalendars) {
      fetchConnectedCalendars()
    }
  }, [shouldReloadConnectedCalendars])

  useEffect(() => {
    const checkMFA = async () => {
      setUserEnabledMFA(await hasUserEnabledMFA())
    }
    checkMFA()
    fetchConnectedCalendars()
  }, [])

  useEffect(() => {
    fetchOutOfOfficeData()
  }, [actionNotifications])

  useEffect(() => {
    if (googleCalendarList?.length as number > 0 || outlookCalendarList?.length as number > 0) {
      setShowCalendarListModal(true)
    }
    if (outlookCalendarList?.length as number > 0 || outlookCalendarList?.length as number > 0) {
      setShowCalendarListModal(true)
    }
  }, [googleCalendarList, outlookCalendarList])

  useEffect(() => {
    sessionStorage.setItem('activeAccountPageTab', activeTab)
  }, [activeTab])

  useEffect(() => {
    if (newOOOEmail) {
      setShowChangeOOOModal(true)
    }
  }, [newOOOEmail])

  const handleAccountPageActiveTabChange = (key: string) => {
    setActiveTab(key)
  }


  const fetchOutOfOfficeData = async () => {
    setIsOutOfOfficeSettingsLoading(true)
    try {
      const userOutOfOfficeSettingsResponse = await getOutOfOfficeSettingsQuery({ variables: { userId: authUser.id }})
      if (userOutOfOfficeSettingsResponse.error?.fetchError) {
        // This is a fetch error, it's often something like the following:
        // "fetchData should not be called after hook unmounted"
        // We can ignore this error, but just return
        return
      }
      if (!userOutOfOfficeSettingsResponse.data || userOutOfOfficeSettingsResponse.error) throw userOutOfOfficeSettingsResponse.error
      const leaveTypesResponse = await getLeaveTypesListQuery()
      if (leaveTypesResponse.error?.fetchError) {
        // This is a fetch error, it's often something like the following:
        // "fetchData should not be called after hook unmounted"
        // We can ignore this error, but just return
        return
      }
      if (!leaveTypesResponse.data || leaveTypesResponse.error) throw leaveTypesResponse.error
      const userLocationLeaveTypesOnly = leaveTypesResponse.data.getLeaveTypeList
        .filter((leaveType) => {
          if (!leaveType.isActive) {
            return false
          }
          return leaveType.leavePolicies.filter((leavePolicy) => leavePolicy?.location?.id === authUser.location.id).length > 0
        })
      setUserOutofOfficeSettings(userOutOfOfficeSettingsResponse.data.getOutOfOfficeSettings)
      setIsOutOfOfficeActive(userOutOfOfficeSettingsResponse.data.getOutOfOfficeSettings
        ? userOutOfOfficeSettingsResponse.data.getOutOfOfficeSettings.active
        : true
      )
      setLeaveTypes(userLocationLeaveTypesOnly)
      setActiveOutOfOfficeLeaveTypes(userOutOfOfficeSettingsResponse.data.getOutOfOfficeSettings?.snoozedLeaveTypes.length > 0
        ? userLocationLeaveTypesOnly
          .map((leaveType) => {
            const snoozed = find(userOutOfOfficeSettingsResponse.data?.getOutOfOfficeSettings.snoozedLeaveTypes, (snoozedLeaveTypeId) => {
              return snoozedLeaveTypeId === leaveType.id
            })
            if (!snoozed) {
              return {
                label: leaveType.name as string,
                value: leaveType.id as string,
              }
            }
          })
          .filter(Boolean) as SnoozedLeaveTypeOption[]
        : userLocationLeaveTypesOnly.map((leaveType) => {
          return {
            label: leaveType.name,
            value: leaveType.id,
          } as SnoozedLeaveTypeOption
        }))
      setIsOutOfOfficeSettingsLoading(false)
      setIsUpdatingUserOutOfOfficeSettings(false)
    } catch (err) {
      console.log('err', err)
      setIsOutOfOfficeSettingsLoading(false)
      setIsUpdatingUserOutOfOfficeSettings(false)
      notification.error({
        message: formatMessage({ id: 'error.somethingWentWrong' }),
        key: 'error-fetching-connected-calendars',
        description: formatMessage({ id: 'error.fetchingUserOutOfOfficeSettings' }),
        btn: (<Button onClick={() => {
          fetchOutOfOfficeData()
          notification.destroy('error-fetching-connected-calendars')
        }}>{ formatMessage({ id: 'app.tryAgain' }) }</Button>),
        duration: 0,
      })
    }
  }

  const fetchConnectedCalendars = async () => {
    try {
      setShouldReloadConnectedCalendars(false)
      setIsLoading(true)
      const response = await getConnectedCalendarsQuery({ variables: { userId: authUser.id }})

      if (response.data?.getConnectedGooogleCalendar) {
        setConnectedGoogleCalendar(response.data.getConnectedGooogleCalendar)
      }
      if (response.data?.getConnectedOutlookCalendar) {
        setConnectedOutlookCalendar(response.data.getConnectedOutlookCalendar)
      }
      setIsLoading(false)
    } catch (err) {
      notification.error({
        message: formatMessage({ id: 'error.somethingWentWrong' }),
        key: 'error-fetching-connected-calendars',
        description: formatMessage({ id: 'error.fetchingConnectedCalendarInfo' }),
        btn: (<Button onClick={() => {
          fetchConnectedCalendars()
          notification.destroy('error-fetching-connected-calendars')
        }}>{ formatMessage({ id: 'app.tryAgain' }) }</Button>),
        duration: 0,
      })
    }
  }

  const onPasswordChange = async () => {
    setChangePasswordSubmitLoader(true)
    try {
      const { oldPassword, password } = await changePasswordForm.validateFields()

      if (oldPassword === password) {
        const err = new Error('Old and new passwords are the same')
        err.name = 'PasswordNotChangedException'
        throw err
      }

      await updatePassword(oldPassword, password)

      logout({
        history: history,
        redirectRoute: `${FrontendUrls.signin}?platform=email`,
        reduxDispatch: dispatch,
        userId: authUser.id,
        globalSignout: true,
      })
      notification.success({
        message: formatMessage({ id: 'notifications.passwordChangedTitle' }),
        description: formatMessage({ id: 'notifications.passwordChangedDescription' }),
        duration: 10,
      })
      changePasswordForm.resetFields()
      history.push(`${FrontendUrls.signin}?platform=email`)

      setChangePasswordSubmitLoader(false)
    } catch (error) {
      setChangePasswordSubmitLoader(false)
      switch (error.name) {
        case 'NotAuthorizedException':
          notification.error({
            message: formatMessage({ id: 'error.incorrectPassword' }),
            key: 'INCORRECT_PASSWORD',
            description: formatMessage({ id: 'error.incorrectPasswordMessage' }),
            btn: (<Button onClick={() => {
              showForgotPasswordModal(true)
              notification.destroy('INCORRECT_PASSWORD')
            }}>{ formatMessage({ id: 'app.resetPassword' }) }</Button>),
            duration: 0,
          })
          break

        case 'PasswordNotChangedException':
          notification.error({
            message: formatMessage({ id: 'error.samePasswordException' }),
            key: 'SAME_PASSWORDS',
            description: formatMessage({ id: 'error.samePasswordExceptionMessage' }),
            duration: 0,
          })
          break

        case 'LimitExceededException':
        case 'TooManyFailedAttemptsException':
        case 'TooManyRequestsException':
          notification.error({
            message: formatMessage({ id: 'error.passwordResetLimitExceededException' }),
            key: 'LIMIT_EXCEEDED',
            description: formatMessage({ id: 'error.passwordResetLimitExceededExceptionMessage' }),
            duration: 0,
          })
          break

        default:
          notification.error({
            message: formatMessage({ id: 'error.generic' }),
            description: (<>
              <Paragraph>{ formatMessage({ id: 'error.generic.description' }) }</Paragraph>
              <Paragraph><Text code copyable>PASSWORD_CHANGE_FAILED</Text></Paragraph>
            </>),
            duration: 0,
          })
          Sentry.captureException(error)
          break
      }
    }
  }

  const getGoogleCalendarTokenAndCalendarList = async () => {
    const response = await googleAuth.grantCalendarPermission()
    setGoogleCalendarTokens(response)
    if (response) {
      const googleCalendarsResponse = await googleAuth.getGoogleCalendars(response.accessToken as string)
      if (googleCalendarsResponse?.items?.length as number > 0) {
        let foundDefaultCalendar
        if (connectedGoogleCalendar) {
          foundDefaultCalendar = find(googleCalendarsResponse?.items,
            (cal: IGoogleCalendar) => cal.id === (connectedGoogleCalendar as IConnectedGoogleCalendar)?.calendarId) as IGoogleCalendar
        } else {
          foundDefaultCalendar = find(googleCalendarsResponse?.items, (cal: IGoogleCalendar) => cal.accessRole === 'owner') as IGoogleCalendar
        }
        setGoogleCalendarList(googleCalendarsResponse?.items?.filter((cal: IGoogleCalendar) => cal.accessRole === 'writer' || cal.accessRole === 'owner') as IGoogleCalendar[])
        setOutlookCalendarList(null)
        setSelectedGoogleCalendar(foundDefaultCalendar)
      }
    }
  }

  const connectGoogleCalendar = async () => {
    try {
      await Api.post('/google/calendar/user/connect', {
        accessToken: googleCalendarTokens?.accessToken,
        refreshToken: googleCalendarTokens?.refreshToken,
        calendarId: selectedGoogleCalendar?.id,
        timezone: selectedGoogleCalendar?.timeZone,
      })
      notification.success({
        key: 'google-calendar-connected',
        message: formatMessage({ id: 'connectCalendar.success' }, { calendar: selectedGoogleCalendar?.summary}),
        duration: 10,
      })
      setShowCalendarListModal(false)
      setConnectedGoogleCalendar(selectedGoogleCalendar?.id as string)
      setShouldReloadConnectedCalendars(true)
    } catch (error) {
      notification.error({
        message: formatMessage({ id: 'connectCalendar.error' }),
        key: 'google-calendar-connection-error',
        description: formatMessage({ id: 'connectCalendar.errorDescription' }),
        btn: (<Button onClick={() => {
          getGoogleCalendarTokenAndCalendarList()
          notification.destroy('google-calendar-connection-error')
        }}>{ formatMessage({ id: 'app.ok' }) }</Button>),
        duration: 0,
      })
    }
  }

  const reconnectGoogleCalendar = async () => {
    try {
      await Api.post('/google/calendar/user/reconnect', {
        accessToken: googleCalendarTokens?.accessToken,
        refreshToken: googleCalendarTokens?.refreshToken,
        calendarId: selectedGoogleCalendar?.id,
        timezone: selectedGoogleCalendar?.timeZone,
      })
      notification.success({
        key: 'google-calendar-connected',
        message: formatMessage({ id: 'connectCalendar.reconnectSuccess' }, { calendar: selectedGoogleCalendar?.summary}),
        duration: 10,
      })
      setShowCalendarListModal(false)
      setConnectedGoogleCalendar(selectedGoogleCalendar?.id as string)
      setShouldReloadConnectedCalendars(true)
    } catch (error) {
      notification.error({
        message: formatMessage({ id: 'connectCalendar.reconnectError' }),
        key: 'google-calendar-connection-error',
        description: formatMessage({ id: 'connectCalendar.errorReconnectDescription' }),
        btn: (<Button onClick={() => {
          getGoogleCalendarTokenAndCalendarList()
          notification.destroy('google-calendar-connection-error')
        }}>{ formatMessage({ id: 'app.ok' }) }</Button>),
        duration: 0,
      })
    }
  }

  const disconnectGoogleCalendar = async () => {
    try {
      await Api.post('/google/calendar/user/disconnect', '')
      setConnectedGoogleCalendar(null)
      setShouldReloadConnectedCalendars(true)
      notification.success({
        key: 'google-calendar-disconnected',
        message: formatMessage({ id: 'disconnectCalendar.success' }),
        duration: 10,
      })
    } catch (error) {
      notification.error({
        message: formatMessage({ id: 'connectCalendar.disconnectError' }),
        key: 'google-calendar-disconnection-error',
        description: formatMessage({ id: 'connectCalendar.disconnectErrorDescription' }),
        btn: (<Button onClick={() => {
          disconnectGoogleCalendar()
          notification.destroy('google-calendar-disconnection-error')
        }}>{ formatMessage({ id: 'app.ok' }) }</Button>),
        duration: 0,
      })
    }
  }

  const getOutlookCalendarTokenAndCalendarList = async () => {
    const tokenResponse = await msAuth.getWebAppTokensForOutlook('offline_access Calendars.ReadWrite MailboxSettings.Read')
    const timezone = await msAuth.getUserOutlookTimezone(tokenResponse.accessToken)
    setOutlookCalendarTokens(tokenResponse)
    setPreferredOutlookTimezone(timezone)
    if (tokenResponse) {
      const outlookCalendarsResponse = await msAuth.getCalendars(tokenResponse.accessToken)
      if (outlookCalendarsResponse?.length as number > 0) {
        const foundDefaultCalendar = find(outlookCalendarsResponse, cal => cal.isDefaultCalendar) as IOutlookCalendar
        setOutlookCalendarList(outlookCalendarsResponse.filter(cal => cal.isDefaultCalendar))
        setGoogleCalendarList(null)
        setSelectedOutlookCalendar(foundDefaultCalendar)
      }
    }
  }

  const connectMSOutlookCalendar = async () => {
    try {
      await Api.post('/microsoft/calendar/user/connect', {
        accessToken: outlookCalendarTokens?.accessToken,
        refreshToken: outlookCalendarTokens?.refreshToken,
        calendarId: selectedOutlookCalendar?.id,
        timezone: preferredOutlookTimezone,
      })
      notification.success({
        key: 'outlook-calendar-connected',
        message: formatMessage({ id: 'connectCalendar.success' }, { calendar: selectedOutlookCalendar?.name}),
        duration: 10,
      })
      setShowCalendarListModal(false)
      setConnectedOutlookCalendar(selectedOutlookCalendar?.id as string)
      setShouldReloadConnectedCalendars(true)
    } catch (error) {
      notification.error({
        message: formatMessage({ id: 'connectCalendar.error' }),
        key: 'outlook-calendar-connection-error',
        description: formatMessage({ id: 'connectCalendar.errorDescription' }),
        btn: (<Button onClick={() => {
          getOutlookCalendarTokenAndCalendarList()
          notification.destroy('outlook-calendar-connection-error')
        }}>{ formatMessage({ id: 'app.ok' }) }</Button>),
        duration: 0,
      })
    }
  }

  const reconnectMSOutlookCalendar = async () => {
    try {
      await Api.post('/microsoft/calendar/user/reconnect', {
        accessToken: outlookCalendarTokens?.accessToken,
        refreshToken: outlookCalendarTokens?.refreshToken,
        calendarId: selectedOutlookCalendar?.id,
        timezone: preferredOutlookTimezone,
      })
      notification.success({
        key: 'outlook-calendar-connected',
        message: formatMessage({ id: 'connectCalendar.reconnectSuccess' }, { calendar: selectedOutlookCalendar?.name}),
        duration: 10,
      })
      setShowCalendarListModal(false)
      setConnectedOutlookCalendar(selectedOutlookCalendar?.id as string)
      setShouldReloadConnectedCalendars(true)
    } catch (error) {
      logger.warning(error)
      notification.error({
        message: formatMessage({ id: 'connectCalendar.reconnectError' }),
        key: 'outlook-calendar-connection-error',
        description: formatMessage({ id: 'connectCalendar.errorReconnectDescription' }),
        btn: (<Button onClick={() => {
          getOutlookCalendarTokenAndCalendarList()
          notification.destroy('outlook-calendar-connection-error')
        }}>{ formatMessage({ id: 'app.ok' }) }</Button>),
        duration: 0,
      })
    }
  }

  const disconnectMSOutlookCalendar = async () => {
    try {
      await Api.post('/microsoft/calendar/user/disconnect', '')
      setConnectedOutlookCalendar(null)
      setShouldReloadConnectedCalendars(true)
      notification.success({
        key: 'outlook-calendar-disconnected',
        message: formatMessage({ id: 'disconnectCalendar.success' }),
        duration: 10,
      })
    } catch (error) {
      notification.error({
        message: formatMessage({ id: 'connectCalendar.disconnectError' }),
        key: 'outlook-calendar-disconnection-error',
        description: formatMessage({ id: 'connectCalendar.disconnectErrorDescription' }),
        btn: (<Button onClick={() => {
          disconnectMSOutlookCalendar()
          notification.destroy('outlook-calendar-disconnection-error')
        }}>{ formatMessage({ id: 'app.ok' }) }</Button>),
        duration: 0,
      })
    }
  }

  const handleActivateOutOfOffice = () => {
    setIsOutOfOfficeActive(!isOutOfOfficeActive)
  }

  const handleSaveOutOfOfficeSettings = async () => {
    const snoozedLeaveTypes = difference(leaveTypes.map(lt => lt.id), activeOutOfOfficeLeaveTypes.map(lt => lt.value))
    setIsUpdatingUserOutOfOfficeSettings(true)
    const body = {
      eventType: EventTypeEnum.USER_OUT_OF_OFFICE_SETTINGS_UPDATED,
      eventGroup: 'USER',
      companyId: authCompany?.id,
      platform: authUser.platform,
      userId: authUser.id,
      oooPlatform: userOutOfOfficeSettings?.oooPlatform || '',
      active: isOutOfOfficeActive,
      snoozedLeaveTypes,
      refreshToken: userOutOfOfficeSettings?.refreshToken || '',
      msTenantId: userOutOfOfficeSettings?.msTenantId || '',
      outlookEmail: userOutOfOfficeSettings?.outlookEmail as string || '',
      googleUserId: userOutOfOfficeSettings?.googleUserId || '',
      timezone: userOutOfOfficeSettings?.timezone || 'UTC',
    }
    try {
      const response = await Api.post('/core/event', body)
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      setIsUpdatingUserOutOfOfficeSettings(false)
      notification.error({
        message: formatMessage({ id: 'error.generic' } ),
        description: formatMessage({ id: 'dashboard.outOfOfficeSetError' }),
        key: 'error-notification-handler-1',
        btn: <Button onClick={handleSaveOutOfOfficeSettings}>
          <IntlMessages id="dashboard.outOfOfficeSet" />
        </Button>,
        duration: 0,
      })
    }
  }

  const showDisconnectModal = (onOk, connectedPlatform) => {
    modal.confirm({
      title: formatMessage(
        { id: 'connectCalendar.disconnectTitle' },
        { calendarPlatform: connectedPlatform }
      ),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage(
        { id: 'connectCalendar.disconnectDescription' }
      ),
      width: 560,
      okType: 'primary',
      okText: formatMessage(
        { id: 'app.ok' }
      ),
      cancelText: formatMessage(
        { id: 'app.cancel' }
      ),
      onOk() {
        onOk()
      },
    })
  }

  const handleConnectCalendar = () => {
    if (hasConnectedCalendarErrorGoogle) {
      reconnectGoogleCalendar()
      return
    }

    if (hasConnectedCalendarErrorOutlook) {
      reconnectMSOutlookCalendar()
      return
    }

    if (googleCalendarList?.length as number > 0) {
      connectGoogleCalendar()
    }

    if (outlookCalendarList?.length as number > 0) {
      connectMSOutlookCalendar()
    }
  }

  const handleSelectCalendar = (v) => {
    if (googleCalendarList?.length as number > 0) {
      setSelectedGoogleCalendar(v)
      setSelectedOutlookCalendar(null)
    }

    if (outlookCalendarList?.length as number > 0) {
      setSelectedOutlookCalendar(v)
      setSelectedGoogleCalendar(null)
    }
  }

  const handleCalendarListModalCancel = () => {
    setSelectedGoogleCalendar(null)
    setSelectedOutlookCalendar(null)
    setShowCalendarListModal(false)
  }

  const handleSnoozedLeaveTypesChange = (leaveTypes:SnoozedLeaveTypeOption[]): void => {
    setActiveOutOfOfficeLeaveTypes(leaveTypes)
  }

  const changeMFASettings = async (event) => {
    if (event.target.value === true) {
      setMfaActionInProgress(true)
      const secretCode = await setupMfa()
      setQRCodeData(secretCode)
    }
    else if (event.target.value === false && userEnabledMFA) {
      try {
        modal.confirm({
          title: formatMessage({ id: 'app.mfaDisableWarningTitle' }),
          icon: <ExclamationCircleOutlined />,
          content: formatMessage({ id: 'app.mfaDisableWarning' }),
          width: 560,
          okType: 'primary',
          okText: formatMessage({ id: 'app.mfaDisableAnswerYes' }),
          cancelText: formatMessage({ id: 'app.mfaDisableAnswerNo' }),
          onOk() {
            turnOffMFA()
          },
        })
      } catch (error) {
        setMfaActionInProgress(false)
        notification.error({ message: formatMessage({ id: 'app.mfaDisableError' }), description: error.message, duration: 10 })
      }
    }
  }

  const turnOffMFA = async () => {
    setMfaActionInProgress(true)
    await updateMfaPreference('NOMFA')
    setMfaActionInProgress(false)
    setUserEnabledMFA(false)
    setQRCodeData(null)
    notification.success({ message: formatMessage({ id: 'app.mfaDisableSuccess' }), duration: 10 })
    setMfaActionInProgress(false)
  }

  const hasUserEnabledMFA = async (): Promise<boolean> => {
    const data = await getPreferredMFA()
    return data === 'TOTP'
  }

  const enableMFAForUser = async (code: string): Promise<void> => {
    setQRCodeData(null)
    try {
      await verifyTotpToken(code)
      await updateMfaPreference('TOTP')
      setUserEnabledMFA(true)
      setMfaActionInProgress(false)
      notification.success({ message: formatMessage({ id: 'app.mfaEnableSuccess' }), duration: 10 })
    } catch (error) {
      setMfaActionInProgress(false)
      setUserEnabledMFA(false)
      notification.error({
        message: formatMessage({ id: 'app.mfaEnableError' }),
        description: formatMessage({ id: getCognitoErrors(error.code as string) }),
        duration: 10,
      })
    }
  }

  const onCloseMFAModal = () => {
    setQRCodeData(null)
    setMfaActionInProgress(false)
  }

  const handleUpdateUser = async (locale: LocaleEnum, hourFormat: HourFormatEnum) => {
    try {
      let body = {}
      if (authUser.role === 'Admin') {
        body = {
          eventType: 'USER_UPDATED',
          eventGroup: 'USER',
          userId: authUser.id,
          name: authUser.name,
          locale,
          email: authUser.email,
          platform: authUser.platform,
          startDate: authUser.startDate,
          isAdmin: authUser.role === 'Admin',
          isNameLocked: authUser?.isNameLocked,
          status: authUser.status,
          hourFormat,
        }
      } else {
        body = {
          eventType: 'USER_UPDATED',
          eventGroup: 'USER',
          userId: authUser.id,
          name: authUser.name,
          locale,
          hourFormat,
        }
      }
      await Api.post('/core/event', body)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const tailLayout = {
    wrapperCol: { xs: { offset: 0, span: 12 }, md: { offset: 4, span: 12 }, lg: { offset: 6, span: 12 }},
  }

  const handleLangChange = (value: LocaleEnum) => {
    dispatch(setLocale(availableLanguages[value]))
    const hourFormat = authUser.hourFormat || getHourFormatBasedOnTimezone(authUser.location.timezone) || HourFormatEnum.twentyFour
    track('USER_CHANGED_LANGUAGE', {
      language: availableLanguages[value].name,
    })
    handleUpdateUser(value, hourFormat)
  }

  const handleHourFormatChange = (e: RadioChangeEvent) => {
    dispatch(setAuthUser({ ...authUser, hourFormat: e.target.value as HourFormatEnum }))
    track('USER_CHANGED_HOUR_FORMAT', {
      hourFormat: e.target.value,
      locationTimezone: authUser.location.timezone,
    })
    const locale = authUser.locale || sessionStorage.getItem('vtLocale') as LocaleEnum
    handleUpdateUser(locale, e.target.value)
  }

  const layout = {
    labelCol: {xs:{ span: 24} ,md:{ span: 4 }},
    wrapperCol: {xs:{ span: 24} ,md:{ span: 10 }},
  }

  const accountPreferencesFormItems = [
    {
      label: <IntlMessages id="dashboard.accountSettingsLanguageLabel" />,
      element: <Select
        defaultValue={locale.locale}
        value={locale.locale}
        style={{ width: 120 }}
        onChange={handleLangChange}
        options={
          Object.values(availableLanguages)
            .map(lang => {
              return {
                value: lang.locale,
                label: lang.name,
              }
            })
            .filter(lang => {
              // Hide languages that require feature flag and FF is disabled or "false"
              switch (lang.value) {
                case LocaleEnum.es:
                  return enableSpanishLanguage
                case LocaleEnum.pt:
                  return enablePortugueseLanguage
                case LocaleEnum.de:
                  return enableGermanLanguage
                case LocaleEnum.it:
                  return enableItalianLanguage
                case LocaleEnum.pl:
                  return enablePolishLanguage
                case LocaleEnum.tr:
                  return enableTurkishLanguage
                case LocaleEnum.sv:
                  return enableSwedishLanguage
                default:
                  return true
              }
            })
        }
      />,
      id: 'accountSettingsLanguageLabel',
    },
    {
      label: <IntlMessages id="dashboard.accountSettingsTimeDisplayLabel" />,
      element: <Radio.Group value={authUser?.hourFormat || HourFormatEnum.twentyFour} onChange={handleHourFormatChange}>
        <Radio.Button value={HourFormatEnum.twelve}>
          <IntlMessages id="app.12h" />
        </Radio.Button>
        <Radio.Button value={HourFormatEnum.twentyFour}>
          <IntlMessages id="app.24h" />
        </Radio.Button>
      </Radio.Group>,
      id: 'accountSettingsTimeDisplayLabel',
    },
    {
      label: <IntlMessages id="dashboard.accountSettingsUIModeLabel" />,
      element: <Switch
        checkedChildren={theme === DARK_THEME ? '🔆' : null}
        unCheckedChildren={theme === LIGHT_THEME ? '🌙' : null}
        checked={theme === DARK_THEME}
        onClick={() => setThemeHandler(theme === DARK_THEME ? LIGHT_THEME : DARK_THEME)}
      />,
      id: 'dashboard.accountSettingsUIModeLabel',
    },
  ]

  const getCalendarLabel = (calendar: IConnectedGoogleCalendar | IConnectedOutlookCalendar | string | null) => {
    if (!calendar) {
      return 'connectCalendar.connect'
    }
    if (typeof calendar === 'string') {
      return 'connectCalendar.disconnect'
    }
    if (typeof calendar !== 'string' && calendar?.error) {
      return 'connectCalendar.reconnect'
    }
    if (typeof calendar !== 'string' && !calendar?.error) {
      return 'connectCalendar.disconnect'
    }
  }

  const getGoogleOOOData = async () => {
    const tokens = await googleAuth.grantGmailPermission()
    const timezone = await googleAuth.getGoogleCalendarTimezone(tokens.accessToken as string) as string
    let user
    if (authUser.platform !== 'google') {
      const userRes = await googleAuth.userInfo(tokens.accessToken as string)
      user = userRes.email
    } else {
      user = authUser.email
    }
    setnewOOOEmail(user)
    setGoogleUser(user)
    setGoogleTokens(tokens)
    setGoogleTimezone(timezone)
  }

  const getMicrosoftOOOData = async () => {
    const tokens = await msAuth.getWebAppTokensForOutlook('offline_access MailboxSettings.ReadWrite user.readbasic.all')
    const timezone = await msAuth.getUserOutlookTimezone(tokens.accessToken)

    let tenantId
    let outlookEmail
    try {
      const decoded = jwtDecode<{tid: string; upn: string; unique_name: string}>(tokens?.accessToken as string)
      tenantId = decoded.tid
      outlookEmail = decoded?.upn || decoded?.unique_name
      setnewOOOEmail(outlookEmail)
    } catch (error) {
      Sentry.captureException(JSON.stringify({
        ...error,
        message: 'Error decoding microsoft token - fallback to "common" tenant',
      }))
      tenantId = 'common'
    }
    setMicrosoftTokens(tokens)
    setMicrosoftTimezone(timezone)
    setMsTenantId(tenantId)
  }

  const handleOnGmailOOOChangeCLick = async () => {
    try {
      setShowChangeOOOModal(false)
      setIsUpdatingUserOutOfOfficeSettings(true)
      const body = {
        eventType: EventTypeEnum.USER_OUT_OF_OFFICE_SETTINGS_UPDATED,
        eventGroup: 'USER',
        companyId: authCompany?.id,
        platform: authUser.platform,
        userId: authUser.id,
        oooPlatform: 'gmail',
        active: isOutOfOfficeActive,
        snoozedLeaveTypes: !userOutOfOfficeSettings
          ? []
          : userOutOfOfficeSettings.snoozedLeaveTypes,
        refreshToken: googleTokens?.refreshToken,
        msTenantId: userOutOfOfficeSettings?.msTenantId || '',
        outlookEmail: userOutOfOfficeSettings?.outlookEmail as string || '',
        googleUserId: googleUser,
        timezone: googleTimezone,
      }
      const response = await Api.post('/core/event', body)
      handleResetOOOChangeData()
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setIsUpdatingUserOutOfOfficeSettings(false)
    } catch (error) {
      setIsUpdatingUserOutOfOfficeSettings(false)
      Sentry.captureException(JSON.stringify(error))
      errorNotificationHandler(error, 'google', handleOnGmailOOOChangeCLick)
    }
  }

  const handleOnOutlookOOOChangeClick = async () => {
    try {
      setShowChangeOOOModal(false)
      setIsUpdatingUserOutOfOfficeSettings(true)
      const body = {
        eventType: EventTypeEnum.USER_OUT_OF_OFFICE_SETTINGS_UPDATED,
        eventGroup: 'USER',
        companyId: authCompany?.id,
        platform: authUser.platform,
        userId: authUser.id,
        oooPlatform: 'outlook',
        active: isOutOfOfficeActive,
        snoozedLeaveTypes: !userOutOfOfficeSettings
          ? []
          : userOutOfOfficeSettings.snoozedLeaveTypes,
        refreshToken: microsoftTokens?.refreshToken,
        msTenantId,
        googleUserId: userOutOfOfficeSettings?.googleUserId || '',
        timezone: microsoftTimezone,
        outlookEmail: newOOOEmail,
      }
      const response = await Api.post('/core/event', body)
      handleResetOOOChangeData()
      setActionNotifications([ ...actionNotifications, response.correlationId ])
      setIsUpdatingUserOutOfOfficeSettings(false)
    } catch (error) {
      Sentry.captureException(JSON.stringify(error))
      errorNotificationHandler(error, 'microsoft', handleOnOutlookOOOChangeClick)
    }
  }

  const errorNotificationHandler = (error, platform: Platform, onClickHandler: Function) => {
    const isTokenError = error.message === 'no_tokens'
    let isInsufficientPermission = false
    if (platform === 'google') {
      const parsedError = JSON.parse(error.message)
      isInsufficientPermission = parsedError?.error?.message?.includes('insufficient authentication scopes')
    }
    const errorMessageDescription = isTokenError
      ? formatMessage({ id: 'error.token.description' }, {platform} )
      : isInsufficientPermission
        ? formatMessage({ id: 'error.token.permissionDenied' } )
        : error.message

    notification.error({
      message: formatMessage({ id: 'error.generic' } ),
      description: errorMessageDescription,
      key: 'error-notification-handler',
      btn: isTokenError || isInsufficientPermission
        ? <Button onClick={() => {
          onClickHandler()
          notification.destroy('error-notification-handler')
        }}>
          {formatMessage({ id: 'error.token.button' }, {platform} )}
        </Button>
        : null,
      duration: 0,
    })
  }

  const handleResetOOOChangeData = () => {
    setnewOOOEmail('')
    setMicrosoftTokens(null)
    setMicrosoftTimezone('')
    setMsTenantId('')
    setGoogleUser('')
    setGoogleTimezone('')
    setGoogleTokens(null)
  }

  const handleOOOChangeGetData = () => {
    if (userOutOfOfficeSettings?.oooPlatform === 'gmail') {
      getGoogleOOOData()
    } else {
      getMicrosoftOOOData()
    }
  }

  const handleOOOChange = () => {
    if (userOutOfOfficeSettings?.oooPlatform === 'gmail') {
      handleOnGmailOOOChangeCLick()
    } else {
      handleOnOutlookOOOChangeClick()
    }
  }

  const hasConnectedCalendarErrorGoogle = typeof connectedGoogleCalendar !== 'string' && connectedGoogleCalendar?.error
  const hasConnectedCalendarErrorOutlook = typeof connectedOutlookCalendar !== 'string' && connectedOutlookCalendar?.error

  const accountSettingsTabs: TabsProps['items'] = [
    {
      key: AccountPageActiveTab.settings,
      label: formatMessage({ id: 'app.accountSettings' }),
      children: <>
        <div className="main-content-body">
          <div className="genereal-body">
            {isLoading ? <CircularProgress /> :
              <>
                { authUser.platform === 'email' &&
                  <div>
                    <Title level={4}>
                      <IntlMessages id="app.changePassword" />
                    </Title>
                    <Form
                      form={changePasswordForm}
                      layout="horizontal"
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onFinish={onPasswordChange}
                      {...layout}
                    >
                      <SetPassword changePassword={true} />
                      <Form.Item {...tailLayout}>
                        <Button type="primary" loading={changePasswordSubmitLoader} htmlType="submit"><IntlMessages id="app.changePassword" /></Button>
                      </Form.Item>
                    </Form>
                  </div>
                }
                { authUser.platform === 'email' &&
                  <div style={{ margin: '15px 0' }}>
                    <Title level={4}>
                      <IntlMessages id="app.mfa" />
                    </Title>
                    <Form.Item
                      label={<IntlMessages id='app.mfaLabel' />}
                      {...layout}
                    >
                      <Radio.Group
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onChange={changeMFASettings}
                        value={userEnabledMFA}
                      >
                        {mfaActionInProgress ? (
                          <Spin size="small" />
                        ) : (
                          <>
                            <Radio.Button value={true}><IntlMessages id='app.yes' /></Radio.Button>
                            <Radio.Button value={false}><IntlMessages id='app.no' /></Radio.Button>
                          </>
                        )}
                      </Radio.Group>
                    </Form.Item>
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    {qrCodeData && <EnableMFAModal onSave={enableMFAForUser} secretCode={qrCodeData} onClose={onCloseMFAModal} />}
                  </div>
                }
                <div>
                  <Title level={4}>
                    <IntlMessages
                      id="accountSettings.connectYourPersonalCalendar"
                    />
                  </Title>
                  <Paragraph>
                    <IntlMessages
                      id="accountSettings.connectYourPersonalCalendar.description"
                      values={{
                        helpdesk: (...chunks) => (
                          <a href="https://vacationtracker.crisp.help/en/article/how-do-i-connect-my-personal-calendar-to-vacation-tracker-1i0zqvw"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </Paragraph>
                  <Paragraph>
                    <Form.Item
                      label={<IntlMessages
                        id={getCalendarLabel(connectedGoogleCalendar)}
                        values={{r: (chunks) => <span style={{ color: 'red'}}>{...chunks}</span>}} />}
                      {...layout}
                    >
                      <Button
                        danger={Boolean(connectedGoogleCalendar)}
                        icon={<Icon component={googleCalendarIconSvg} />}
                        onClick={connectedGoogleCalendar && !hasConnectedCalendarErrorGoogle
                          ? () => showDisconnectModal(disconnectGoogleCalendar, 'Google')
                          : getGoogleCalendarTokenAndCalendarList}>
                          Google Calendar
                      </Button>
                    </Form.Item>
                    {hasConnectedCalendarErrorGoogle && <Alert
                      message={ formatMessage({ id: 'connectCalendar.reconnectInfo' } )}
                      type='warning'
                      showIcon
                    />
                    }
                  </Paragraph>
                  <Paragraph>
                    <Form.Item
                      label={<IntlMessages
                        id={getCalendarLabel(connectedOutlookCalendar)}
                        values={{r: (chunks) => <span style={{ color: 'red'}}>{...chunks}</span>}} />}
                      {...layout}
                    >
                      <Button
                        danger={Boolean(connectedOutlookCalendar)}
                        icon={<Icon component={office365CalendarIconSvg} />}
                        onClick={connectedOutlookCalendar && !hasConnectedCalendarErrorOutlook
                          ? () => showDisconnectModal(disconnectMSOutlookCalendar, 'Outlook')
                          : getOutlookCalendarTokenAndCalendarList}>
                            Microsoft Outlook Calendar
                      </Button>
                    </Form.Item>
                    {hasConnectedCalendarErrorOutlook && <Alert
                      message={ formatMessage({ id: 'connectCalendar.reconnectInfo' }) }
                      type='warning'
                      showIcon
                    />
                    }
                  </Paragraph>
                </div>
                {isOutOfOfficeSettingsLoading || isUpdatingUserOutOfOfficeSettings ? <CircularProgress /> :
                  <div style={{ marginTop: '30px' }}>
                    <Title level={4}>
                      <IntlMessages id="dashboard.accountSettingsOutOfOfficeTitle" />
                    </Title>
                    <Paragraph>
                      <IntlMessages id="dashboard.accountSettingsPageOOODescription" />
                    </Paragraph>
                    {userOutOfOfficeSettings &&
                      (userOutOfOfficeSettings.googleUserId || userOutOfOfficeSettings.outlookEmail || userOutOfOfficeSettings.msTenantId) && <Paragraph>
                      <Form.Item
                        {...layout}
                        label={<IntlMessages id='dashboard.accountSettingsOutOfOfficeConnectedWith' />}>
                        <Icon component={userOutOfOfficeSettings?.oooPlatform === 'gmail' ? googleCalendarIconSvg : office365CalendarIconSvg} />
                        {userOutOfOfficeSettings?.outlookEmail || userOutOfOfficeSettings?.googleUserId &&
                        <>&nbsp;<Text>{userOutOfOfficeSettings?.outlookEmail || userOutOfOfficeSettings?.googleUserId}</Text></>}
                        <Button
                          type='link'
                          onClick={handleOOOChangeGetData}>
                          <IntlMessages id="app.change" />
                        </Button>
                      </Form.Item>
                    </Paragraph>}
                    <Paragraph>
                      <Form.Item
                        label={<IntlMessages id='app.enabled' />}
                        {...layout}
                      >
                        <Switch
                          checked={isOutOfOfficeActive}
                          loading={isLoading || isUpdatingUserOutOfOfficeSettings}
                          onClick={handleActivateOutOfOffice}
                        />
                      </Form.Item>
                    </Paragraph>
                    {isOutOfOfficeActive &&
                      <Paragraph>
                        <Form.Item
                          {...layout}
                          label={<IntlMessages id='app.leaveTypes' />}
                        >
                          <Select
                            placeholder={<IntlMessages id="dashboard.selectSnoozeLeaveTypesPlaceholder" />}
                            mode="multiple"
                            maxTagCount="responsive"
                            options={leaveTypes.map((leaveType) => {
                              return {
                                label: leaveType.name,
                                value: leaveType.id,
                              }
                            }) as SnoozedLeaveTypeOption[]}
                            value={activeOutOfOfficeLeaveTypes?.map((leaveType) => leaveType.value)}
                            onChange={(_, option) => handleSnoozedLeaveTypesChange(option as SnoozedLeaveTypeOption[])}
                          />
                        </Form.Item>
                      </Paragraph>
                    }
                    <Form.Item {...tailLayout}>
                      <Button
                        type="primary"
                        loading={isUpdatingUserOutOfOfficeSettings}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleSaveOutOfOfficeSettings}
                      >
                        <IntlMessages id="app.save" />
                      </Button>
                    </Form.Item>
                  </div>
                }
              </>
            }
          </div>
        </div>
        {forgotPasswordModalVisible &&
          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              loading={isUpdatingUserOutOfOfficeSettings}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSaveOutOfOfficeSettings}
            >
              <IntlMessages id="app.save" />
            </Button>
          </Form.Item>
        }
        <Text style={{display: 'block', marginTop: '50px'}}>
          <IntlMessages id="dashboard.b2bSoftware" values={{
            mail: (...chunks) => <a href="mailto:hello@vacationtracker.io">{chunks}</a>,
          }} />
        </Text>
      </>,
    },
    {
      key: AccountPageActiveTab.preferences,
      label: formatMessage({ id: 'app.accountPreferences' }),
      children: <>
        <div className="main-content-body">
          <Form
            layout="horizontal"
            id='account-prefernces-form'
            {...layout}
          >
            {accountPreferencesFormItems.map((item) => {
              return <Form.Item
                className='wrap-label'
                key={item.id}
                label={item.label}
                wrapperCol={{ span: 24 }}
                labelCol={{ md: { span: 10 }, sm: { span: 6 }, lg: { span: 8 } }}
              >{item.element}
              </Form.Item>
            })}
          </Form>
        </div>
      </>,
    },
  ]

  return (
    <div className='main-content'>
      <Tabs
        onChange={handleAccountPageActiveTabChange}
        defaultActiveKey={activeTab}
        items={accountSettingsTabs}
      />
      {forgotPasswordModalVisible &&
        <ForgotPasswordModal
          forgotPasswordModalVisible={forgotPasswordModalVisible}
          showForgotPasswordModal={showForgotPasswordModal}
        />
      }
      {showCalendarListModal &&
        <Modal
          title={<IntlMessages id={hasConnectedCalendarErrorGoogle || hasConnectedCalendarErrorOutlook
            ? 'calendarListModal.reconnectTitle'
            : 'calendarListModal.title'
          } />}
          centered
          open={showCalendarListModal}
          onCancel={handleCalendarListModalCancel}
          onOk={handleConnectCalendar}
        >
          <div>
            {googleCalendarList?.length as number > 0 ?
              <IntlMessages id={hasConnectedCalendarErrorGoogle || hasConnectedCalendarErrorOutlook
                ? 'calendarListModal.reconnectDescription'
                : 'calendarListModal.descriptionGoogleFirstLine'
              } /> :
              <IntlMessages id="calendarListModal.descriptionOutlookFirstLine" />
            }
            {hasConnectedCalendarErrorGoogle || hasConnectedCalendarErrorOutlook
              ? null
              : <IntlMessages id="calendarListModal.descriptionSecondLine" />
            }
          </div>
          <Select
            style={{ width: '400px', marginTop: '20px', marginBottom: '10px' }}
            placeholder={<IntlMessages id="calendarListModal.selectPlaceHolder" />}
            onSelect={(v) => handleSelectCalendar(JSON.parse(v))}
            disabled={outlookCalendarList?.length as number > 0 || Boolean((connectedGoogleCalendar as IConnectedGoogleCalendar)?.error)}
            value={googleCalendarList?.length as number > 0
              ? JSON.stringify(selectedGoogleCalendar)
              : outlookCalendarList?.length as number > 0
                ? JSON.stringify(selectedOutlookCalendar)
                : null}
          >
            {googleCalendarList?.length as number > 0
              ? googleCalendarList?.map(cal => <Option value={JSON.stringify(cal)} key={cal.id}>
                {cal.summary}
              </Option>)
              : null}
            {outlookCalendarList?.length as number > 0
              ? outlookCalendarList?.map(cal => <Option value={JSON.stringify(cal)} key={cal.id}>
                {cal.name}
              </Option>)
              : null}
          </Select>
          <div>
            <IntlMessages
              id="calendarListModal.hiddenLeaveTypesDescription"
              values={{
                helpdesk: (...chunks) => (
                  <a href="https://vacationtracker.crisp.help/en/article/how-do-i-connect-my-personal-calendar-to-vacation-tracker-1i0zqvw"
                    target="_blank"
                    rel="noopener noreferrer">
                    {chunks}
                  </a>
                ),
              }}
            />
          </div>
        </Modal>
      }
      {showChangeOOOModal &&
        <Modal
          title={<IntlMessages id="dashboard.accountSettingsOutOfOfficeChangeModalTitle" />}
          centered
          open={showChangeOOOModal}
          onCancel={() => setShowChangeOOOModal(false)}
          onOk={handleOOOChange}
        >
          <div>
            <IntlMessages
              id="dashboard.accountSettingsOutOfOfficeChangeModalInfo"
              values={{ email: newOOOEmail }}
            />
          </div>
        </Modal>
      }
    </div>
  )
}

export default AccountSettings
