import antdSv from 'antd/locale/sv_SE'
import svMessages from '@vacationtracker/shared/i18n/sv-se.json'

const SvLang = {
  messages: {
    ...svMessages,
  },
  antd: antdSv,
  locale: 'sv-SE',
}
export default SvLang
