import React from 'react'
import * as _ from 'lodash'
import {ReactSpreadsheetImport} from 'react-spreadsheet-import'
import {importInitialBFFields} from './fields'
import {validateImportBroughtForwardRow} from './hooks/rowHook'
import {IGetImportPageValidationData, IUserSlim} from '../../types/users'
import {findDuplicateLeaveTypes} from './hooks/tableHook'
import {ThemeContext} from '../../context/themeContext'
import {customTheme} from './customTheme'
import { getTranslations } from './translations'
import { useIntl } from 'react-intl'
import { translateDescriptions, trimData } from './helpers'

type Props = {
  isOpen: boolean
  onHideModal: () => void
  onUpload: (data: any) => Promise<void>
  companyUsers: IUserSlim[]
  validationData: IGetImportPageValidationData
  hourlyLeaveAccounting: boolean
}

const SpreadsheetImportBroughtForward = ({
  isOpen,
  onHideModal,
  companyUsers,
  validationData,
  hourlyLeaveAccounting,
  onUpload,
}: Props): React.ReactElement => {
  const { formatMessage } = useIntl()
  const {theme} = React.useContext(ThemeContext)
  // Called after user completes the flow. Provides data array, where data keys matches your field keys.
  function onSubmit(data: any) {
    onUpload(data)
  }

  return (
    <ReactSpreadsheetImport
      allowInvalidSubmit={false}
      isOpen={isOpen}
      onClose={onHideModal}
      onSubmit={onSubmit}
      fields={translateDescriptions(importInitialBFFields(hourlyLeaveAccounting), formatMessage)}
      rowHook={validateImportBroughtForwardRow(companyUsers, validationData, formatMessage)}
      tableHook={findDuplicateLeaveTypes}
      customTheme={customTheme(theme)}
      translations={getTranslations('components.imports.titleImportQuotas', formatMessage)}
      uploadStepHook={async (data) => {
        await Promise.resolve(data)
        return trimData(data)
      }}
      selectHeaderStepHook={async (headerValues, data) => {
        await Promise.resolve(data)
        return { headerValues, data }
      }}
    />
  )
}

export default SpreadsheetImportBroughtForward
