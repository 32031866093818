export const getQuotasPart = () => `
  quotas {
    rolloverFromPreviousYear {${getQuotaFieldsPart()}}
    earnedWithoutRollover {${getQuotaFieldsPart()}}
    baseQuota {${getQuotaFieldsPart()}}
    toil {${getQuotaFieldsPart()}}
    toilLapsed {${getQuotaFieldsPart()}}
    seniorityEntitlementForYear {${getQuotaFieldsPart()}}
    entitlementByRoleForYear {${getQuotaFieldsPart()}}
    expired {${getQuotaFieldsPart()}}
    used {${getQuotaFieldsPart()}}
    scheduled {${getQuotaFieldsPart()}}
    remaining {${getQuotaFieldsPart()}}
  }
`
export const getQuotaFieldsPart = () => `
  days
  hours
  minutes
  isNegative
  raw {
    days
    hours
  }
`