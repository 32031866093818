import React from 'react'
import sortBy from 'lodash/sortBy'
import ReportTable from '../../components/reports/table'
import ReportFilter from '../../components/reports/filters'
import ReportDisplay from '../../components/reports/display'
import { ILeaveBalanceMonthlyReportProps } from './types'
import { getColumnsWithMonths, getMonthKey } from '@vacationtracker/shared/functions/export'
import { leaveBalanceMonthylReportColumns } from '@vacationtracker/shared/types/export'
import { UserStatus } from '@vacationtracker/shared/types/user'
import { capitalize } from 'lodash'

const LeaveBalanceMonthlyReport: React.FC<ILeaveBalanceMonthlyReportProps> = ({
  companyData,
  data,
  total,
  page,
  loading,
  display,
  onFiltersChange,
  onDisplayChange,
  loadNext,
  loadPrevious,
  download,
}) => {

  const filters = [
    { type: 'locationIds', options: companyData.getLocationList, label: 'Locations' },
    { type: 'departmentIds', options: companyData.getTeamList, label: 'Departments'},
    { type: 'labelIds', options: companyData.getLabels, label: 'Labels' },
    { type: 'userIds', options: companyData.getUsersFiltered.users, label: 'Users' },
    { type: 'leaveTypeIds', options: sortBy(companyData.getLeaveTypeList, 'position'), label: 'Leave Types' },
    { 
      type: 'userStatuses',
      options: (['ACTIVE', 'INACTIVE', 'DELETED'] as UserStatus[]).map(status => ({ id: status, name: capitalize(status) })),
      label: 'User Status',
    },
  ]

  const groupBalancesByUserId = (data: any) => {
    const groupedData = {}

    data.forEach(item => {
      if (!groupedData[item.userId]) {
        groupedData[item.userId] = {
          user: item.user,
          id: item.userId,
          children: [],
        }
      }
      const { ...data } = item
      data.id = item.id
      groupedData[item.userId].children.push(data)
    })

    return formatMonthlyColumnValues(Object.values(groupedData))
  }

  const formatMonthlyColumnValues = (data) => {
    const transformedArray = data.map(userObj => {
      return {
        ...userObj,
        children: userObj.children.map(child => {
          const result = { ...child }
          child.amount.forEach(item => {
            const key = getMonthKey(item.month as number, item.year as number)
            result[key] = { days: 0, hours: 0, raw: { days: item.raw.days, hours: item.raw.hours } }
          })
          return result
        }),
      }
    })
    return transformedArray
  }

  return (
    <div>
      <ReportDisplay defaultDisplay={display} onDisplayChange={onDisplayChange}/>
      <ReportFilter filters={filters} onFilterChange={onFiltersChange} defaultSelectedValues={{userStatuses: ['ACTIVE']}}/>
      <ReportTable 
        expandableKey={'user'}
        columnNames={getColumnsWithMonths(leaveBalanceMonthylReportColumns)}
        data={groupBalancesByUserId(data)}
        total={groupBalancesByUserId(data).length === 0 ? 0 : total}
        loadNext={loadNext}
        loadPrevious={loadPrevious}
        download={download}
        loading={loading}
        dateFormat={'YYYY-MM-DD'}
        display={display}
        page={page}
        reportType='leave-balance-report'
        editableColumns={false}
      />
    </div>
  )
}

export default LeaveBalanceMonthlyReport
