import React, { useState } from 'react'
import { Typography, Button } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import Api from '@vacationtracker/shared/services/api'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { FeedbackModal } from '@vacationtracker/shared/components/feedback-modal'
import { FrontendUrls } from '../../types/urls'
import { useAppSelector } from '../../store/hooks'
import { selectUserIdSlice } from '../../store/user-id-slice'
import Card from '../../components/card'
import { ImportType } from '../ImportPage/types'

const { Paragraph } = Typography

const ImportsPage = () => {

  const toggleFeedbackModal = () => setShowFeedbackModal(!showFeedbackModal)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const { userId } = useAppSelector(selectUserIdSlice)

  const sendFeedback = async (data) => {
    const body = {
      eventGroup: 'FEEDBACK',
      eventType: 'USER_FEEDBACK',
      userId,
      ...data,
    }
    await Api.post('/core/event', body )
    setShowFeedbackModal(false)
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id="sidebar.imports" />
        </div>
      </div>
      <div className="main-content-body" id="vt-cards">
        <Paragraph className='vt-cards-intro'><IntlMessages id="components.imports.intro" /></Paragraph>
        <div className='vt-cards-container'>
          <Card 
            title={<IntlMessages id="components.imports.titleImportLeaves" />}
            path={`${FrontendUrls.import}/${ImportType.leaves}`}
            content={<IntlMessages id="components.imports.descriptionImportLeaves" />}
            actionIcon={<EyeOutlined />}
            actionText={<IntlMessages id="app.view" />}
          />
          <Card 
            title={<IntlMessages id="components.imports.titleImportDetails" />}
            path={`${FrontendUrls.import}/${ImportType.details}`}
            content={<IntlMessages id="components.imports.descriptionImportDetails" />}
            actionIcon={<EyeOutlined />}
            actionText={<IntlMessages id="app.view" />}
          />
          <Card 
            title={<IntlMessages id="components.imports.titleImportQuotas" />}
            path={`${FrontendUrls.import}/${ImportType.quotas}`}
            content={<IntlMessages id="components.imports.descriptionImportQuotas" />}
            actionIcon={<EyeOutlined />}
            actionText={<IntlMessages id="app.view" />}
          />
          <Card 
            title={<IntlMessages id="components.imports.titleImportToils" />}
            path={`${FrontendUrls.import}/${ImportType.toils}`}
            content={<IntlMessages id="components.imports.descriptionImportToils" />}
            actionIcon={<EyeOutlined />}
            actionText={<IntlMessages id="app.view" />}
          />
        </div>
        <div className='cards-feedback'>
          <Paragraph>
            <IntlMessages id="components.imports.feedback" />&nbsp;
            <Button type='primary' onClick={toggleFeedbackModal}>
              <IntlMessages id='components.feedbackModal.button' />
            </Button>
          </Paragraph>
          <FeedbackModal visible={showFeedbackModal} onCancel={toggleFeedbackModal} onSave={sendFeedback}/>
        </div>
      </div>
    </div>
  )
}

export default ImportsPage

