import React, { useState } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { useIntl } from 'react-intl'
import { Table, Checkbox, Button, Space, Popover } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { format, setMonth } from 'date-fns'
import dayjs from 'dayjs'
import arrayMove from 'array-move'
import IntlMessages from '../../util/IntlMessages'
import { IColumnProps, IReportTableProps, reportResultsLimit } from './types'
import { DateFormat, Display } from '@vacationtracker/shared/types/export'

const SortableItem = SortableElement(({ column, onCheckboxChange, ...props }) => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }} {...props}>
    <SortableColumnHandle />
    <Checkbox
      checked={column.visible}
      onChange={(e) => onCheckboxChange(column.key, e.target.checked)}
    >
      {column.title}
    </Checkbox>
  </div>
))

const SortableBody = SortableContainer(({ items, onCheckboxChange }) => (
  <div>
    {items.map((column, index) => (
      <SortableItem
        key={column.key}
        index={index}
        column={column}
        onCheckboxChange={onCheckboxChange}
      />
    ))}
  </div>
))

const SortableColumnHandle = SortableHandle(() => (
  <span style={{ cursor: 'grab', marginRight: 8 }}>☰</span>
))

const ReportTable = ({ 
  columnNames, data, total, loading, page, dateFormat, display, reportType, expandableKey, editableColumns, loadNext, loadPrevious, download, 
}: IReportTableProps) => {

  const { formatMessage } = useIntl()
  const initialColumns: IColumnProps[] = columnNames.map(col => ({
    title: col.startsWith('month-')
      ? formatMessage({ id: 'components.tables.month' }, { month: format(setMonth(new Date(), Number(col.split('-')[1]) - 1), 'MMM'), year: col.split('-')[2] })
      : formatMessage({ id: `components.tables.${col}` }),
    dataIndex: col,
    key: col,
    visible: true,
    render: (val: string | boolean) => {
      if (val === null || val === '') {
        return 'N/A'
      }
      if (typeof val === 'boolean') {
        return val ? <IntlMessages id="app.yes" /> : <IntlMessages id="app.no" />
      }
      return val
    },
    ...(col === 'user' && {
      render: (text: string, record: any) => (
        <a href={`/app/users/${record.userId}`} target="_blank" rel="noopener noreferrer">{text}</a>
      ),
    }),
  }))

  const formatData = (data: any[], format: DateFormat, display: Display) => {
    return data.map(item => {
      const formattedItem = {
        ...item,
        startDate: dayjs(item.startDate as string).format(format),
        endDate: dayjs(item.endDate as string).format(format),
      }
      for (const key in item) {
        if (item[key] && typeof item[key] === 'object' && 'days' in item[key] && 'hours' in item[key]) {
          formattedItem[key] = display === 'days' ? item[key].raw.days : item[key].raw.hours
          if (item[key].raw?.days === Number.MAX_VALUE) {
            formattedItem[key] = '∞'
          }
        }
      }
      return formattedItem
    })
  }

  const loadColumnSettings = (): { columns: { key: string; visible: boolean }[]; columnOrder: string[] } | null => {
    const settings = localStorage.getItem(`${reportType}TableSettings`)
    if (settings) {
      try {
        return JSON.parse(settings)
      } catch (error) {
        console.error('Failed to parse column settings from localStorage:', error)
      }
    }
    return null
  }

  const saveColumnSettings = (columns: IColumnProps[], columnOrder: string[]) => {
    const settings = {
      columns: columns.map(({ key, visible }) => ({ key, visible })),
      columnOrder,
    }
    localStorage.setItem((`${reportType}TableSettings`), JSON.stringify(settings))
  }

  const savedSettings = loadColumnSettings()
  const initialColumnState = savedSettings
    ? initialColumns.map(col => ({
      ...col,
      visible: savedSettings.columns.find(c => c.key === col.key)?.visible ?? true,
    }))
    : initialColumns.map(col => ({ ...col, visible: true }))
  
  const initialColumnOrder = savedSettings ? savedSettings.columnOrder : initialColumns.map(col => col.key)

  const [columns, setColumns] = useState(initialColumnState)
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder)
  const [popoverColumns, setPopoverColumns] = useState(initialColumnState)

  const handleCheckboxChange = (key: string, checked: boolean) => {
    const updatedColumns = columns.map(col =>
      col.key === key ? { ...col, visible: checked } : col
    )
    setColumns(updatedColumns)
    setPopoverColumns(updatedColumns)
    saveColumnSettings(updatedColumns, columnOrder)
  }

  const handleSelectAll = () => {
    const updatedColumns = columns.map(col => ({ ...col, visible: true }))
    setColumns(updatedColumns)
    setPopoverColumns(updatedColumns)
    saveColumnSettings(updatedColumns, columnOrder)
  }

  const handleUnselectAll = () => {
    const updatedColumns = columns.map((col, index) => ({
      ...col,
      visible: index === 0, // Only the first column remains visible
    }))
    setColumns(updatedColumns)
    setPopoverColumns(updatedColumns)
    saveColumnSettings(updatedColumns, columnOrder)
  }

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const newOrder = arrayMove(columnOrder, oldIndex, newIndex)
    setColumnOrder(newOrder)

    const reorderedColumns = newOrder.map(key => columns.find(col => col.key === key))
    setColumns(reorderedColumns as IColumnProps[])
    setPopoverColumns(reorderedColumns as IColumnProps[])
    saveColumnSettings(reorderedColumns as IColumnProps[], newOrder)
  }

  const sortedColumns = columns
    .filter(col => col?.visible)
    .sort((a, b) => columnOrder.indexOf(a.key) - columnOrder.indexOf(b.key))
  
  const expandedRowData = (record) => {
    return (
      <Table
        columns={sortedColumns as ColumnType<any>[]}
        dataSource={formatData(record.children, dateFormat, display)}
        pagination={false}
        rowKey={'id'}
      />
    )
  }

  return (
    <div id="report-table-container">
      <Space style={{ marginBottom: 16 }}></Space>

      <div id="report-table-btns-container">
        <Button disabled={total === 0} onClick={download} style={{ margin: '5px' }}><IntlMessages id="app.download" /></Button>
        {editableColumns && <Popover
          title={<IntlMessages id="components.tables.editColumnsDesc" />}
          trigger="click"
          placement="bottom"
          arrowPointAtCenter
          content={
            <div>
              <Button onClick={handleSelectAll} style={{ marginBottom: '8px', width: '100%' }}>
                <IntlMessages id="createCompany.steps.selectUsers.selectAll" />
              </Button>
              <Button onClick={handleUnselectAll} style={{ marginBottom: '8px', width: '100%' }}>
                <IntlMessages id="createCompany.steps.selectUsers.deselectAll" />
              </Button>
              <SortableBody
                items={popoverColumns}
                onSortEnd={onSortEnd}
                useDragHandle
                onCheckboxChange={handleCheckboxChange}
              />
            </div>
          }
        >
          <Button style={{ margin: '5px' }}><IntlMessages id="components.tables.editColumns" /></Button>
        </Popover>}
      </div>

      <Table
        columns={expandableKey ? [{
          title: `${formatMessage({ id: `app.${expandableKey}` })}`,
          dataIndex: expandableKey,
          key: 'id',
          render: (text: string, record: any) => (
            <a href={`/app/users/${record.id}`} target="_blank" rel="noopener noreferrer">{text}</a>
          ),
        }] : sortedColumns as ColumnType<any>[]}
        dataSource={formatData(data, dateFormat, display)}
        pagination={false}
        scroll={{ x: 'max-content' }}
        rowKey="id"
        loading={loading}
        showHeader={true}
        expandable={expandableKey ? {
          expandedRowRender: expandedRowData,
        } : undefined}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '6px' }}>
        <div>{total} <IntlMessages id="app.results" /></div>
        <div>
          <Button className='prev-next-btns' disabled={loading || page === 1} onClick={loadPrevious} style={{ width: '100px' }}>
            <IntlMessages id="app.previous" />
          </Button>
          <Button 
            className='prev-next-btns'
            disabled={loading || data.length < reportResultsLimit || data.length === total}
            onClick={loadNext}
            style={{ width: '100px', marginLeft: '10px' }}
          >
            <IntlMessages id="app.next" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ReportTable
