import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FrontendUrls } from '../../../types/urls'
import IntlMessages from '../../../util/IntlMessages'
import ImportEmailUsersForm from './form'
import { Breadcrumb, App, Progress } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { getDepartmentsAndLocations, getAllUserListShortPaginated } from '../../../graphql/custom-queries'
import { useManualQuery } from 'graphql-hooks'
import { IGetUserListShort } from '../../../types/teams'
import { IGetAllUserListShortPaginatedData } from '../../../types/users'
import { IUpcomingInvoiceInformation } from '@vacationtracker/shared/types/stripe'
import Api from '@vacationtracker/shared/services/api'
import { DepartmentShort, LocationShort } from './types'
import logger from '@vacationtracker/shared/functions/logger'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { notificationStore } from '../../../context/notificationsContext/store'
import { useIntl } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

interface IGetDepartmentsAndLocations {
  getTeamList: DepartmentShort[]
  getLocationList: LocationShort[]
}

interface ImportEmailUserData {
  name: string
  email: string
  department: string
  location: string
}

const ImportEmailUsersPage = (): React.ReactElement => {
  const { notification } = App.useApp()
  const [departments, setDepartments] = useState<DepartmentShort[]>([])
  const [locations, setLocations] = useState<LocationShort[]>([])
  const [activeUsers, setActiveUsers] = useState<IGetUserListShort[]>([])
  const [upcomingInvoiceInfo, setUpcomingInvoiceInfo] = useState<IUpcomingInvoiceInformation | null>(null)
  const [isLoadingUpcomingInvoiceInfo, setIsLoadingUpcomingInvoiceInfo] = useState(false)

  const abortSignalRef = useRef(new AbortController())
  const [correlationId, setCorrelationId] = useState('')
  const [correlationExist, setCorrelationExist] = useState(false)
  const { eventsNotifications, setEventsNotifications } = useContext(notificationStore)
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const [getDepartmentsAndLocationsQuery] = useManualQuery<IGetDepartmentsAndLocations>(getDepartmentsAndLocations)
  const [getAllUserListShortPaginatedQuery] = useManualQuery<IGetAllUserListShortPaginatedData, {
    limit: number
    nextToken?: string
  }>(getAllUserListShortPaginated)

  useEffect(() => {
    abortSignalRef.current = new AbortController()
    return () => {
      abortSignalRef.current.abort()
    }
  }, [])

  useEffect(() => {
    fetchInitialData()
  }, [])

  useEffect(() => {
    if (eventsNotifications[correlationId]) {
      setCorrelationExist(true)
    }
  }, [eventsNotifications])

  const fetchInitialData = async () => {
    await Promise.all([
      fetchDepartmentsAndLocations(),
      fetchActiveUsers(),
    ])
  }

  const fetchDepartmentsAndLocations = async () => {
    const response = await getDepartmentsAndLocationsQuery({
      fetchOptionsOverrides: {
        signal: abortSignalRef.current.signal,
      },
    })
    if (!response.data || response.error) throw response.error
    setDepartments(response.data.getTeamList)
    setLocations(response.data.getLocationList)
  }

  const fetchActiveUsers = async () => {
    const usersInSystem = await fetchAllUsersInSystem()
    setActiveUsers(usersInSystem)
  }

  const fetchAllUsersInSystem = async (nextToken?: string, limit = 200, shortUsers: IGetUserListShort[] = []): Promise<IGetUserListShort[]> => {
    const usersInSystem = await getAllUserListShortPaginatedQuery({ variables: { nextToken, limit }, fetchOptionsOverrides: { signal: abortSignalRef.current.signal } })
    if (!usersInSystem.data || usersInSystem.error) throw usersInSystem.error

    const users = shortUsers.concat(usersInSystem.data.getAllUserListPaginated.users as IGetUserListShort[])

    if (usersInSystem.data.getAllUserListPaginated.nextToken) {
      return await fetchAllUsersInSystem(usersInSystem.data.getAllUserListPaginated.nextToken, limit, users)
    }

    return users.filter(user => user.status !== 'DELETED')
  }

  // In ImportEmailUsersPage (parent component)
  const getUpcomingInvoiceInfo = useCallback(async (noOfUsersToImport: number) => {
    if (noOfUsersToImport === 0) {
      return
    }
    setIsLoadingUpcomingInvoiceInfo(true)
    try {
      const info = await Api.post('/stripe/upcoming-invoice-information', {
        quantity: noOfUsersToImport,
        date: Math.ceil(new Date().getTime() / 1000),
      })
      if (info) {
        setUpcomingInvoiceInfo(info)
        setIsLoadingUpcomingInvoiceInfo(false)
      }
    } catch (error) {
      setIsLoadingUpcomingInvoiceInfo(false)
    }
  }, []) // Empty dependency array since it doesn't use any props or state

  const showNotification = (users: {
    userId: string
    email: string
    name: string
    locationId: string
    teamId: string
  }[], currentCorrelationId: string) => {

    notification.open({
      key: currentCorrelationId,
      message: users.length === 1 ?
        formatMessage({ id: 'users.importOne.InProgress' }) :
        formatMessage({ id: 'users.importMany.InProgress' }),
      description: <Progress percent={0} size="small" />,
      duration: 0,
    })
    setCorrelationId(currentCorrelationId)
    setEventsNotifications({
      ...eventsNotifications,
      [currentCorrelationId]: {
        eventType: 'USER_STATUS_CHANGED',
        payload: {
          users: users.map(user => {
            return user.userId
          }),
          totalUsers: users.length,
        },
      },
    })
  }

  const onFinish = useCallback(async ({ users, shouldInviteUsers }: { users: ImportEmailUserData[]; shouldInviteUsers: boolean }): Promise<void> => {
    const body = {
      eventGroup: 'BULK_ACTIONS',
      eventType: 'IMPORT_USERS',
      users: users.map(user => {
        return {
          userId: `email-${uuidv4()}`,
          email: user.email.toLowerCase(),
          name: user.name,
          teamId: user.department,
          locationId: user.location,
        }
      }),
      shouldInviteUsers,
      locale: authUser?.locale,
      organizationId: authCompany?.organizationId,
      totalUsers: users.length,
      version: 2,
    }
    try {

      const response = await Api.post('/core/event', body)
      // Send user to the list of all users
      showNotification(body.users, response.correlationId as string)

      history.push('/app/users')
    } catch (error) {
      logger.error('error', error)
    }
  }, [])

  return (
    <div className='main-content'>
      <div className='main-content-header'>
        <div className='main-content-header-title'>
          <IntlMessages id='users.createUsers' />
        </div>
        <div className='main-content-header-breadcrumb'>
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <Link to="/app/users"><IntlMessages id="sidebar.users" /></Link>,
              },
              {
                title: <IntlMessages id='users.createUsers' />,
              },
            ]}
          />
        </div>
      </div>
      <div className='main-content-body'>
        <ImportEmailUsersForm
          departments={departments}
          locations={locations}
          activeUsers={activeUsers}
          upcomingInvoiceInfo={upcomingInvoiceInfo}
          isLoadingUpcomingInvoiceInfo={isLoadingUpcomingInvoiceInfo}
          onGetUpcomingInvoiceInfo={getUpcomingInvoiceInfo}
          authCompany={authCompany}
          authUser={authUser}
          onFinish={onFinish}
        />
      </div>
    </div>
  )
}

export default ImportEmailUsersPage
