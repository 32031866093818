import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Card as AntCard, Descriptions } from 'antd'

interface CardProps {
  title: ReactElement
  path: string
  actionIcon: ReactElement
  actionText: ReactElement
  content: ReactElement
}

const Card = ({ title, path, actionIcon, actionText, content }: CardProps): ReactElement => {
  
  return (
    <Link to={path} style={{ textDecoration: 'none' }}>
      <AntCard
        className='vt-card'
        hoverable
        key={path}
        actions={[<div key={path}>{actionIcon}&nbsp;{actionText}</div>]}
        title={title}
      >
        <Descriptions layout="horizontal" size="small" column={1}>
          <Descriptions.Item key="desc">
            {content}
          </Descriptions.Item>
        </Descriptions>
      </AntCard>
    </Link>
  )
}

export default Card