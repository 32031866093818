import React, { useEffect, useState } from 'react'
import { Button, Modal, notification, Spin } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import axios from 'axios'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { useIntl } from 'react-intl'
import { track } from '../../services/analytics/analytics'
import { Display, ReportType } from '@vacationtracker/shared/types/export'
import { IReportQuery } from './types'
import { getAuthTokens } from '@vacationtracker/shared/services/auth'

interface ExportModalProps {
  total: number
  reportQuery: IReportQuery
  reportType: ReportType
  title: string
  display: Display
  authUser: any
  open: boolean
  onClose: () => void
}

const EXPORT_TIME_WAIT_MINUTES = 3
const EXPORT_TIME_POLL_INTERVAL_SECONDS = 10
const EXPORT_LS_KEY = 'waiting_for_export'

const ExportModal: React.FC<ExportModalProps> = ({ total, title, reportQuery, reportType, display, authUser, open, onClose }) => {
  const { formatMessage } = useIntl()
  const [exportInProgress, setExportInProgress] = useState(false)
  const [exportLink, setExportLink] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (open) {
      pollUrl()
    }
  }, [open])

  const downloadFile = (url: string) => {
    setExportLink(undefined)
    localStorage.setItem(EXPORT_LS_KEY, `${(Date.now() + EXPORT_TIME_WAIT_MINUTES * 60 * 1000).toString()}|${url}`)
    setTimeout(() => { pollUrl() }, 2000)
  }

  const requestExport = async (format: 'excel' | 'csv') => {
    try {
      setExportInProgress(true)
      const exportQuery = { ...reportQuery, reportType, display, exportFormat: format }
      const filteredColumns = JSON.parse(localStorage.getItem((`${reportType}TableSettings`)) as string)?.columns
        .filter(column => column.visible)
        .map(column => column.key)
      if (filteredColumns) {
        exportQuery.columns = filteredColumns
      }
      delete exportQuery.lastId
      delete exportQuery.limit
      delete exportQuery.page
      const tokens = await getAuthTokens()
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/core/export-new`, exportQuery, {
        headers: {
          'Content-Type': 'application/json',
          Accept: format === 'excel' ? 'application/vnd.ms-excel' : 'text/csv',
          Authorization: `Bearer ${tokens.idToken}`,
        },
      })

      downloadFile(result.data)

      track('REPORT_DOWNLOADED', {
        platform: authUser.platform,
        userId: authUser.id,
        reportType,
      })
    } catch (error) {
      setExportInProgress(false)
      console.log('ERROR HANDLE SUBMIT', error)
      const description = error.response?.data?.error || error.message || JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'error.generic' }),
        description,
        duration: 0,
      })
    }
  }

  const pollUrl = async (): Promise<void> => {
    setExportInProgress(true)
    const time = localStorage.getItem(EXPORT_LS_KEY)?.substring(0, 13)
    const url = localStorage.getItem(EXPORT_LS_KEY)?.substring(14)

    if (!time || time < Date.now().toString()) {
      localStorage.removeItem(EXPORT_LS_KEY)
      setExportInProgress(false)
      return
    }
    const startTime = Date.now()

    const checkUrlValidity = async (): Promise<boolean> => {
      try {
        const response = await fetch(`https://${url}`)
        return response.ok
      } catch (error) {
        return false
      }
    }

    while (Date.now() - startTime < EXPORT_TIME_WAIT_MINUTES * 60 * 1000) {
      const isValid = await checkUrlValidity()
      if (isValid) {
        setExportLink(`https://${url}`)
        setExportInProgress(false)
        localStorage.removeItem(EXPORT_LS_KEY)
        return
      }
      await new Promise(resolve => setTimeout(resolve, EXPORT_TIME_POLL_INTERVAL_SECONDS * 1000))
    }

    setExportInProgress(false)
    localStorage.removeItem(EXPORT_LS_KEY)
    notification.error({ message: formatMessage({ id: 'error.generic' }), duration: 0 })
  }

  return (
    <Modal
      title={<IntlMessages id={title} />}
      open={open}
      onCancel={onClose}
      closeIcon={<CloseCircleOutlined />}
      footer={null}
    >
      <div><IntlMessages id="export.downloadAllRows" values={{ total }} /></div>
      {!exportInProgress && !exportLink && (
        <div id="download-modal-btns">
          <div><Button onClick={() => { requestExport('excel') }}>Excel</Button></div>
          <div><Button onClick={() => { requestExport('csv') }}>CSV</Button></div>
        </div>
      )}
      {exportInProgress && <div><IntlMessages id="app.downloadProgressReport" /><Spin size="large" /></div>}
      {exportLink && (
        <div>
          <a href={exportLink} target="_blank" rel="noopener noreferrer">
            <Button onClick={() => { setExportLink(undefined); onClose() }}><IntlMessages id="app.downloadReady" /></Button>
          </a>
        </div>
      )}
    </Modal>
  )
}

export default ExportModal
