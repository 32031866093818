import React from 'react'
import { capitalize } from 'lodash'
import sortBy from 'lodash/sortBy'
import ReportTable from '../../components/reports/table'
import ReportFilter from '../../components/reports/filters'
import ReportPeriodFilter from '../../components/reports/filter-period'
import ReportDisplay from '../../components/reports/display'
import { ILeaveHistoryReportProps } from './types'
import { LeaveRequestStatus } from '@vacationtracker/shared/types/leave-request'
import { RequestType } from '@vacationtracker/shared/types/request'
import { leaveRequestReportColumns } from '@vacationtracker/shared/types/export'
import { UserStatus } from '@vacationtracker/shared/types/user'

const LeaveHistoryReport: React.FC<ILeaveHistoryReportProps> = ({
  companyData,
  defaultDates,
  data,
  total,
  page,
  loading,
  dateFormat,
  display,
  onFiltersChange,
  onPeriodChange,
  onDisplayChange,
  onDateFormatChange,
  loadNext,
  loadPrevious,
  download,
}) => {
  const filters = [
    { type: 'locationIds', options: companyData.getLocationList, label: 'Locations' },
    { type: 'departmentIds', options: companyData.getTeamList, label: 'Departments'},
    { type: 'labelIds', options: companyData.getLabels, label: 'Labels' },
    { type: 'userIds', options: companyData.getUsersFiltered.users, label: 'Users' },
    { type: 'leaveTypeIds', options: sortBy(companyData.getLeaveTypeList, 'position'), label: 'Leave Types' },
    { 
      type: 'requestTypes',
      options: (['LEAVE', 'TOIL'] as RequestType[]).map(status => ({ id: status, name: status === 'TOIL' ? status : capitalize(status) })),
      label: 'Request Type',
    },
    { 
      type: 'statuses',
      options: (['APPROVED', 'CANCELLED', 'DENIED', 'EXPIRED', 'OPEN'] as LeaveRequestStatus[]).map(status => ({ id: status, name: capitalize(status) })),
      label: 'Status',
    },
    { 
      type: 'userStatuses',
      options: (['ACTIVE', 'INACTIVE', 'DELETED'] as UserStatus[]).map(status => ({ id: status, name: capitalize(status) })),
      label: 'User Status',
    },
  ]

  return (
    <div>
      <ReportPeriodFilter dateFormat={dateFormat} defaultDates={defaultDates} onFilterChange={onPeriodChange}/>
      <ReportDisplay defaultDisplay={display} defaultDateFormat={dateFormat} onDisplayChange={onDisplayChange} onDateFormatChange={onDateFormatChange}/>
      <ReportFilter filters={filters} onFilterChange={onFiltersChange} defaultSelectedValues={{userStatuses: ['ACTIVE']}} />
      <ReportTable 
        columnNames={leaveRequestReportColumns}
        data={data}
        total={total}
        loadNext={loadNext}
        loadPrevious={loadPrevious}
        download={download}
        loading={loading}
        dateFormat={dateFormat}
        display={display}
        page={page}
        reportType='leave-history-report'
        editableColumns={true}
      />
    </div>
  )
}

export default LeaveHistoryReport
