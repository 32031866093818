import { IGetCompanyBillingTeamList } from '../../../frontend/src/types/teams'
import { ITeam, ITeamShort } from '../../types/team'
import { TeamTree } from './types'

export function buildDepartmentTree(
  departments: ITeam[] | ITeamShort[] | IGetCompanyBillingTeamList[],
  team?: ITeam
): TeamTree[] {
  const departmentMap = {}
  const extendedDepartments = team ? [...departments, team] : departments

  // create a map of departments and add users and approvers to them
  extendedDepartments.forEach((department) => {
    departmentMap[department.id] = {...department, children: []}
  })

  // Root departments list
  const rootDepartments: any[] = []

  extendedDepartments.forEach((department) => {
    if (department.parentId && departmentMap[department.parentId]) {
      departmentMap[department.parentId].children.push(
        departmentMap[department.id]
      )
    } else {
      // If no parentId, it's a root department
      rootDepartments.push(departmentMap[department.id])
    }
  })

  return rootDepartments
}

export function getNodeDepth(
  tree: TeamTree[],
  id: string,
  currentDepth = 1
): number {
  for (const department of tree) {
    if (department.id === id) {
      return currentDepth
    }
    if (department.children.length > 0) {
      const depth = getNodeDepth(department.children, id, currentDepth + 1)
      if (depth !== -1) {
        return depth
      }
    }
  }
  return -1 // ID not found in the tree
}

// get tree height by traversing the children
export function getMaxDepth(tree: TeamTree[], currentDepth = 1): number {
  let maxDepth = currentDepth

  for (const department of tree) {
    if (department.children.length > 0) {
      const depth = getMaxDepth(department.children, currentDepth + 1)
      if (depth > maxDepth) {
        maxDepth = depth
      }
    }
  }

  return maxDepth
}

export function getNumberOfSubdeparments(tree: TeamTree[]): number {
  let totalCount = 0
  for (const team of tree) {
    if (team.parentId) {
      totalCount++
    }
    totalCount += getNumberOfSubdeparments(team.children)
  }
  return totalCount
}