import React, { useState, useEffect } from 'react'
import { Popover, Select, Button } from 'antd'
import { camelCase } from 'lodash'
import IntlMessages from '../../util/IntlMessages'
import { IExportFilterProps, IFilterPopoverProps } from './types'

const { Option } = Select

const FilterPopover: React.FC<IFilterPopoverProps> = ({
  filterType,
  options,
  label,
  selectedValues,
  onApply,
  onReset,
  triggerIcon,
}) => {
  const [tempValues, setTempValues] = useState<string[]>(selectedValues || [])
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTempValues(selectedValues || [])
  }, [selectedValues])

  const handleChange = (value: string[]) => {
    setTempValues(value)
  }

  const handleApply = () => {
    onApply(filterType, tempValues)
    setVisible(false)
  }

  const handleReset = () => {
    setTempValues([])
    onReset(filterType)
    setVisible(false)
  }

  const handleVisibleChange = (newVisible: boolean) => {
    if (!newVisible) {
      setTempValues(selectedValues || [])
    }
    setVisible(newVisible)
  }

  return (
    <Popover
      content={
        <div>
          <Select
            mode="multiple"
            style={{ width: 200 }}
            placeholder={<IntlMessages id="app.select" />}
            value={tempValues}
            onChange={handleChange}
            filterOption={(input, option) =>
              (option as any).children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {options.map((option: IExportFilterProps) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
          <div style={{ marginTop: '10px' }}>
            <Button type="primary" onClick={handleApply} style={{ marginRight: '8px' }}>
              <IntlMessages id="app.apply" />
            </Button>
            <Button onClick={handleReset} style={{ marginRight: '8px' }}>
              <IntlMessages id="app.reset" />
            </Button>
          </div>
        </div>
      }
      title={<IntlMessages id={`app.${camelCase(label)}`} />}
      trigger="click"
      placement="bottom"
      open={visible}
      onOpenChange={handleVisibleChange}
    >
      {triggerIcon}
    </Popover>
  )
}

export default FilterPopover
