import React, { useContext, useState,useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { App, Button, Switch, Table } from 'antd'
import { CloseOutlined, CheckOutlined, ExclamationCircleOutlined, FormOutlined, LoadingOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { convertQuota, getQuotaInOriginalUnits } from '@vacationtracker/shared/functions/get-days-or-hours'
import { getShortestLeaveInterval } from '@vacationtracker/shared/functions/get-shortest-leave-interval'
import { notificationStore } from '../../context/notificationsContext/store'
import IntlMessages from '../../util/IntlMessages'
import LeavePolicyModal from '../leave-policy-modal'
import { useShouldEnableFeatures } from '../../store/use-should-enable-features'
import * as logger from '../../services/logger'

import Api from '@vacationtracker/shared/services/api'
import { ILocationLeavePolicies, ILeavePolicyBody } from './types'
import { ILocationGeneralLeavePolicies } from '../../types/locations'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { AccrualTypeEnum, QuotaUnits, ShortestLeaveIntervalEnum } from '@vacationtracker/shared/types/leave-policy'
import isNull from 'lodash/isNull'
import dayjs from 'dayjs'
import { roundTo2Decimals } from '@vacationtracker/shared/functions/calculation-shared'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'

const LocationLeavePolicies = ({ leavePoliciesList, location, leaveTypes, allLeavePolicies, hourlyLeaveAccounting }: ILocationLeavePolicies): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const history = useHistory()
  const { modal, notification } = App.useApp()
  const [loading, setLoading] = useState<boolean>(false)
  const [visibleLeavePolicyForm, setVisibleLeavePolicyForm] = useState(false)
  const [leavePolicies, setLeavePolicies] = useState<ILocationGeneralLeavePolicies[]>(leavePoliciesList)
  const [leaveTypeList, setLeaveTypeList] = useState(leaveTypes)

  const shouldEnableHourIntervals = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.shortestLeaveInterval)
  const shouldEnableAccruals = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.accruals)

  useEffect(() => {
    if (leavePoliciesList) {
      setLeavePolicies(leavePoliciesList)
    }
    if (leaveTypes) {
      setLeaveTypeList(leaveTypes)
    }
  }, [leavePoliciesList, leaveTypes])

  const createNewLeavePolicy = () => {
    setVisibleLeavePolicyForm(true)
  }

  const handleEditLeavePolicyClick = (row) => {
    history.push(`/app/settings/location/${row.locationId}/leave-policies/${row.leaveType.id}`)
  }

  const enableLeavePolicy = async (leavePolicy, reactivation = false) => {
    let shortestLeaveInterval = getShortestLeaveInterval(leavePolicy?.shortestLeaveInterval, leavePolicy?.allowHalfDays)
    if (shortestLeaveInterval === ShortestLeaveIntervalEnum.oneHour && !shouldEnableHourIntervals) {
      shortestLeaveInterval = ShortestLeaveIntervalEnum.halfDay
    }
    let accrualType = leavePolicy.accrualType
    if (leavePolicy.accrualType !== AccrualTypeEnum.none && !shouldEnableAccruals) {
      accrualType = AccrualTypeEnum.none
    }

    const locationHours = getUserWorkingHoursPerDay(location?.workHours)

    let {days: daysPerYear, hours: hoursPerYear} = convertQuota(leavePolicy.quota as number, leavePolicy.quotaSetIn as QuotaUnits, locationHours)
    let {days: maxRolloverDays, hours: maxRolloverHours} = convertQuota(leavePolicy.maxRollover as number, leavePolicy.quotaSetIn as QuotaUnits, locationHours)

    if (reactivation) {
      daysPerYear = leavePolicy.daysPerYear
      hoursPerYear = leavePolicy.hoursPerYear
      maxRolloverDays = leavePolicy.maxRolloverDays
      maxRolloverHours = leavePolicy.maxRolloverHours
    }

    const body: ILeavePolicyBody = {
      toil: Boolean(leavePolicy.toil),
      eventType: 'LEAVE_POLICY_ENABLED',
      eventGroup: 'LEAVE_POLICY',
      leaveTypeId: leavePolicy?.leaveType?.id || leavePolicy?.leaveTypeId,
      negativeBallanceAllowed: leavePolicy.negativeBallanceAllowed,
      locationId: location.id,
      daysPerYear,
      hoursPerYear,
      quotaSetIn: leavePolicy.quotaSetIn,
      maxRolloverDays,
      maxRolloverHours,
      hasUnlimitedDays: leavePolicy.hasUnlimitedDays,
      shortestLeaveInterval,
      hideLeaveType: leavePolicy.hideLeaveType,
      accrualType: accrualType,
      isApprovalRequired: leavePolicy.isApprovalRequired,
      isReasonRequired: leavePolicy.isReasonRequired,
      cancelUserSettings: Boolean(leavePolicy.cancelUserSettings),
      allowLeaveInPast: Boolean(leavePolicy.allowLeaveInPast),
      broughtForwardExpirationSettings: {
        enabled: Boolean(leavePolicy.enableRolloverExpiry),
      },
      allowAdvanceAccrualUsage: Boolean(leavePolicy.allowAdvanceAccrualUsage),
      pauseAccrualsWhenUserIsDeactivated: isNull(leavePolicy?.pauseAccrualsWhenUserIsDeactivated) || leavePolicy?.pauseAccrualsWhenUserIsDeactivated,
      negativeBroughtForwardAllowed: Boolean(leavePolicy.negativeBroughtForwardAllowed),
      version: 3,
    }
    if (leavePolicy.firstEarningDate) {
      body.firstEarningDate = leavePolicy.firstEarningDate
    }
    if (leavePolicy.accrualType !== AccrualTypeEnum.none) {
      body.accrualPeriodStart = leavePolicy.accrualPeriodStart
    }
    if (leavePolicy.accrualCapRate) {
      body.accrualCapRate = leavePolicy.accrualCapRate
    }

    if (leavePolicy.enableRolloverExpiry) {
      if (typeof leavePolicy.rolloverExpiryDay === 'number' && typeof leavePolicy.rolloverExpiryMonth === 'number') {
        body.broughtForwardExpirationSettings.day = leavePolicy.rolloverExpiryDay
        body.broughtForwardExpirationSettings.month = leavePolicy.rolloverExpiryMonth
      } else if (typeof leavePolicy.rolloverExpiryAfterDays === 'number') {
        body.broughtForwardExpirationSettings.afterDays = leavePolicy.rolloverExpiryAfterDays
      } else {
        body.broughtForwardExpirationSettings.enabled = false
      }
    }
    if (leavePolicy.toil) {
      body.toilShortestInterval = leavePolicy.toilShortestInterval
      body.toilRequestsAllowedForUsers = leavePolicy.toilRequestsAllowedForUsers
      body.toilExpiration = leavePolicy.toilExpiration
      body.toilExpirationMonths = 0
      body.toilExpirationDate = undefined
      if (leavePolicy.toilExpiration) {
        leavePolicy.toilExpirationMonths === 'calendar-year'
          ? body.toilExpirationDate = dayjs().endOf('year').format('YYYY-MM-DD')
          : body.toilExpirationMonths = leavePolicy.toilExpirationMonths
      }
    }

    return await Api.post('/core/event', body)
  }

  const handleErrorNotification = (error, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'error.notificationGeneral' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.notificationGeneral' }),
      description,
      duration: 0,
    })
  }

  const onSaveLeavePolicy = async (leavePolicy) => {
    try {
      setLoading(true)
      const leavePolicyId = leavePolicy.id || null
      delete leavePolicy.id

      const response = await enableLeavePolicy(leavePolicy)

      if (leavePolicyId) {
        notification.open({
          key: response.correlationId,
          message: formatMessage({ id: 'components.locationLeavePolicy.updateInProgress' }),
          icon: (<LoadingOutlined />),
          duration: 0,
        })
      } else {
        notification.open({
          key: response.correlationId,
          message: formatMessage({ id: 'components.locationLeavePolicy.createInProgress' }, { name: leavePolicy.name }),
          icon: (<LoadingOutlined />),
          duration: 0,
        })
      }
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      logger.warning('error', error)
      handleErrorNotification(error)
    }

    setVisibleLeavePolicyForm(false)
    setLoading(false)
  }

  const handleChangeStatusLeavePolicy = async (leavePolicy) => {
    setLoading(true)
    if (leavePolicy.isActive) {
      await Api.post('/core/event', {
        eventType: 'LEAVE_POLICY_DISABLED',
        eventGroup: 'LEAVE_POLICY',
        leaveTypeId: leavePolicy.leaveType.id,
        locationId: location.id,
      })
    } else {
      enableLeavePolicy(leavePolicy, true)
    }
    setLeavePolicies(leavePolicies.filter(lp => {
      if (lp.id === leavePolicy.id) {
        lp.isActive = !lp.isActive
      }
      return lp
    }))
    setLoading(false)
  }

  const confirmChangeStatus = (leavePolicy) => {
    modal.confirm({
      title: leavePolicy.isActive ?
        formatMessage({ id: 'leaveTypes.deactivateLeaveType' }) :
        formatMessage({ id: 'leaveTypes.activatingLeaveType' }),
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ whiteSpace: 'pre-line' }}>
        {leavePolicy.isActive ?
          formatMessage({ id: 'components.locationLeavePolicies.deactivateLeaveTypeInfo' }) :
          formatMessage({ id: 'components.locationLeavePolicies.activatingLeaveTypeInfo' })
        }
      </p>,
      okText: formatMessage({ id: 'app.yes' }),
      cancelText: formatMessage({ id: 'app.no' }),
      onOk() {
        handleChangeStatusLeavePolicy(leavePolicy)
      },
    })
  }

  const leavePoliciesColumns = [
    {
      title: <IntlMessages id="app.status" />,
      dataIndex: 'isActive',
      key: 'isActive',
      // eslint-disable-next-line react/display-name
      render: (isActive, row) => {
        return (<>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={isActive}
            onChange={() => confirmChangeStatus(row)}
          />
        </>)
      },
    }, {
      title: <IntlMessages id="components.locationLeavePolicies.leaveTypeName" /> ,
      dataIndex: 'id',
      key: 'leaveType',
      render: (id, row) => row.leaveType.name,
    }, {
      title: <IntlMessages id="locations.leavePolicies.yearlyQuota" /> ,
      dataIndex: 'daysPerYear',
      className: 'daysPerYear',
      key: 'daysPerYear',
      render: (daysPerYear, row) => {
        if (row.hasUnlimitedDays) {
          return <IntlMessages id="app.unlimited" />
        } else {
          let quota: number
          let textId: string
          //see confluence diagram LOWD:
          if (row.quotaSetIn) {
            quota = getQuotaInOriginalUnits(daysPerYear as number, row.hoursPerYear as number, row.quotaSetIn as QuotaUnits)
            textId = row.quotaSetIn === 'hours' ? 'locations.leavePolicies.quotaHours' : 'locations.leavePolicies.quotaDays'
          } else {
            //old companies have all quotas in days
            quota = hourlyLeaveAccounting ? daysPerYear * getUserWorkingHoursPerDay(location?.workHours) : daysPerYear
            textId = hourlyLeaveAccounting ? 'locations.leavePolicies.quotaHours' : 'locations.leavePolicies.quotaDays'
          }
          return <IntlMessages id={textId} values={{value: roundTo2Decimals(quota) }} />
        }
      },
    }, {
      title: <IntlMessages id="app.approvalRequired" /> ,
      dataIndex: 'isApprovalRequired',
      key: 'isApprovalRequired',
      render: (isApprovalRequired) => {
        if (isApprovalRequired) {
          return <IntlMessages id="app.yes" />
        } else {
          return <IntlMessages id="app.no" />
        }
      },
    }, {
      title: <IntlMessages id="app.shortestLeaveDuration" />,
      dataIndex: 'shortestLeaveInterval',
      key: 'shortestLeaveInterval',
      render: (shortestLeaveInterval, row) => {
        const shortLeaveInterval = getShortestLeaveInterval(shortestLeaveInterval, row.allowHalfDays)
        if (shortLeaveInterval === ShortestLeaveIntervalEnum.oneHour && !shouldEnableHourIntervals) {
          return <IntlMessages id={`app.${ShortestLeaveIntervalEnum.halfDay.toLowerCase()}`} />
        }
        return <IntlMessages id={`app.${shortLeaveInterval.toLowerCase()}`} />
      },
    }, {
      title: '',
      className: 'action',
      width: 54,
      dataIndex: 'id',
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (id, row) => (
        <Button onClick={() => {
          handleEditLeavePolicyClick(row)
        }} type="link"><FormOutlined /></Button>
      ),
    },
  ]

  return (
    <>
      <p><IntlMessages id="components.locationLeavePolicies.createNewLeavePolicyInfo" /></p>
      <Button onClick={createNewLeavePolicy} className="joyride-assign-leave-policies">
        <IntlMessages id="app.assignLeavePolicy" />
      </Button>
      <div>
        <Table
          dataSource={leavePolicies}
          columns={leavePoliciesColumns}
          rowKey="index"
          loading={loading}
          pagination={false}
        />
        {visibleLeavePolicyForm &&
          <LeavePolicyModal
            visibleModal={visibleLeavePolicyForm}
            handleCancel={() => { setVisibleLeavePolicyForm(false) }}
            loading={loading}
            leaveTypes={leaveTypeList}
            allLeavePolicies={allLeavePolicies}
            location={location}
            onSave={async (data) => await onSaveLeavePolicy(data)}
            hourlyLeaveAccounting={hourlyLeaveAccounting}
          />
        }
      </div>
    </>
  )
}

export default LocationLeavePolicies
