import React, { ReactElement } from 'react'
import { ISubscriptionEvent, ISubscriptionEventMessage } from '@vacationtracker/shared/types/notification'
import { Button, Progress } from 'antd'
import { EventTypeEnum } from '@vacationtracker/shared/types/core-event'
import { showErrors } from '@vacationtracker/shared/components/toil-form/errors'
import { ToilRequestErrorTypeEnum } from '@vacationtracker/shared/types/toil-request'
import { NUMBER_OF_LOCATIONS_LIMIT, NUMBER_OF_TEAMS_LIMIT } from '@vacationtracker/shared/data/app-parameters'

// Third kind of "Notification", event success/failure on the dashboard
interface INotificationInfoResponse {
  action: string
  notifications: string[] | object
  options: {
    key: string
    message: string
    title?: string
    titleValues?: { [key: string]: any }
    description?: string | object
    descriptionValues?: { [key: string]: any }
    btn?: ReactElement
    duration: number
    updateNotification: boolean
    forceErrorMessage?: boolean
  }
}

// DUPLICATE IN SHARED

export function generateNotificationInfo(event: ISubscriptionEvent, notifications: string[] | object, formatMessage): INotificationInfoResponse {
  let action = event.code || 'open'
  let newAction

  let subscriptionEvent: ISubscriptionEventMessage | null = null
  const message: string = event.message
  try {
    subscriptionEvent = JSON.parse(event.originalEvent)
  } catch (err) {
    // Generic message
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = null
  if(event?.result) {
    try {
      result = JSON.parse(event.result)
    } catch (error) {
      // Generic message
    }
  }

  let title
  let titleValues = {}
  let description
  let updateNotification = true
  let forceErrorMessage = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const descriptionValues: any = {}
  let button: ReactElement | undefined
  let key = subscriptionEvent?.correlationId || ''

  if (action === 'error') {
    const errorCode = event.errorCode
    switch (subscriptionEvent?.eventType) {

      case 'BILLING_UPDATED':
        if (message.includes('Subscription is not active') || message.includes('Invalid operation as underlying resource is not active')) {
          title = 'billing.subscriptionExpired'
          description = 'billing.subscriptionExpiredDescription'
        }
        if (message.includes('Patch cannot be completed')) {
          title = 'billing.subscriptionUpdateInProgress'
          description = 'billing.subscriptionUpdateInProgressDescription'
        }
        if (errorCode === 'PaymentFailed') {
          title = 'error.paymentFailed'
          description = 'stripe.dynamicMessage'
          descriptionValues.message = JSON.parse(event.data).message
        }
        else if (errorCode === 'PromoCodeIsntValid') {
          title = 'error.PromoCodeIsntValid'
          titleValues = JSON.parse(event.data)
          forceErrorMessage = true
        }
        else if (errorCode === 'PromoCodeDoesntExist') {
          // We don't want to show that the promo code doesn't exist, and give the possibility to hack the promo code.
          title = 'error.PromoCodeIsntValid'
          titleValues = JSON.parse(event.data)
          forceErrorMessage = true
        }
        else if (errorCode === 'TeamLimitReached') {
          title = 'error.TeamLimitReached'
          titleValues = {
            limitNumber: NUMBER_OF_TEAMS_LIMIT,
          }
        }
        else if (errorCode === 'LocationLimitReached') {
          title = 'error.LocationLimitReached'
          titleValues = {
            limitNumber: NUMBER_OF_LOCATIONS_LIMIT,
          }
        }
        else if (errorCode === 'DowngradeImpossibleCustomWorkDay') {
          title = 'error.CustomWorkday'
        }
        else if (errorCode) {
          title = `error.${errorCode}`
          if (JSON.parse(event.data)) {
            const data = JSON.parse(event.data)
            if (data.promoCode) {
              titleValues = data
            }
          }
        }
        break
      case 'LOCATION_YEAR_HOLIDAYS_CHANGED':
        if (errorCode === 'InvalidHolidayForYear') {
          const date = event.message.match(/\d{4}-\d{2}-\d{2}/) as string[]
          title = 'error.holidayDateInvalid'
          titleValues = {
            date: date[0],
            year: event.message.slice(-4),
          }
          forceErrorMessage = true
        }
        break
      case 'LEAVE_TYPE_CREATED':
        if (notifications[`${subscriptionEvent.correlationId}#LEAVE_POLICY_ENABLED`]) {
          key = `${subscriptionEvent.correlationId}#LEAVE_POLICY_ENABLED`
          newAction = 'close'
          title = 'error.generic'
          delete notifications[`${subscriptionEvent.correlationId}#LEAVE_POLICY_ENABLED`]
        }
        break
      case 'LEAVE_TYPE_UPDATED':
        if (notifications[subscriptionEvent.correlationId]) {
          key = subscriptionEvent.correlationId
          newAction = 'close'
          title = 'error.generic'
          delete notifications[subscriptionEvent.correlationId]
        }
        break
      case 'TOIL_REQUEST_CREATED':
        title = showErrors(event.message as ToilRequestErrorTypeEnum)
        break
      case 'TOIL_REQUEST_DENIED':
        title = showErrors(event.message as ToilRequestErrorTypeEnum)
        break
      case 'TOIL_REQUEST_APPROVED':
        title = showErrors(event.message as ToilRequestErrorTypeEnum)
        break
      case 'TOIL_REQUEST_UPDATED':
        title = showErrors(event.message as ToilRequestErrorTypeEnum)
        break
      case 'TOIL_REQUEST_DELETED':
        title = showErrors(event.message as ToilRequestErrorTypeEnum)
        break
      case 'BLACKOUT_PERIOD_DELETED':
        title = 'error.blackOutPeriod.isntDeleted'
        description = `error.${errorCode}`
        break
      case 'BLACKOUT_PERIOD_CREATED':
        title = 'error.blackOutPeriod.isntCreated'
        description = `error.${errorCode}`
        break
      case 'BLACKOUT_PERIOD_UPDATED':
        title = 'error.blackOutPeriod.isntUpdated'
        description = `error.${errorCode}`
        break
      case 'SENIORITY_ENTITLEMENT_DELETED':
        title = 'error.seniorityEntitlement.isntDeleted'
        description = `error.${errorCode}`
        break
      case 'SENIORITY_ENTITLEMENT_CREATED':
        title = 'error.seniorityEntitlement.isntCreated'
        description = `error.${errorCode}`
        break
      case 'SENIORITY_ENTITLEMENT_UPDATED':
        title = 'error.seniorityEntitlement.isntUpdated'
        description = `error.${errorCode}`
        break
      case 'ENTITLEMENT_BY_ROLE_DELETED':
        title = 'error.entitlementByRole.isntDeleted'
        description = `error.${errorCode}`
        break
      case 'ENTITLEMENT_BY_ROLE_CREATED':
        title = 'error.entitlementByRole.isntCreated'
        description = `error.${errorCode}`
        break
      case 'ENTITLEMENT_BY_ROLE_UPDATED':
        title = 'error.entitlementByRole.isntUpdated'
        description = `error.${errorCode}`
        break
      case 'OPEN_API_ADDON_CREATED':
        title = 'error.openApi.isntCreated'
        description = `error.${errorCode}`
        break
      case 'OPEN_API_ADDON_DELETED':
        title = 'error.openApi.isntDeleted'
        description = `error.${errorCode}`
        break
      case 'TEAM_DELETED':
        title = 'error.team.delete'
        description = `error.${errorCode}`
        break
      case 'PROBATION_PERIOD_DELETED':
        title = 'error.probationPeriod.isntDeleted'
        description = `error.${errorCode}`
        break
      case 'PROBATION_PERIOD_CREATED':
        title = 'error.probationPeriod.isntCreated'
        description = `error.${errorCode}`
        break
      case 'PROBATION_PERIOD_UPDATED':
        title = 'error.probationPeriod.isntUpdated'
        description = `error.${errorCode}`
        break
      case 'USER_UPDATED':
        title = `error.${errorCode}`
        break
    }
  }

  if (action !== 'error') {
    switch (subscriptionEvent?.eventType) {
      case 'LOCATION_CREATED':
        button = (<Button type="primary" size="small" href={`/app/settings/locations/${subscriptionEvent?.locationId}/general`}>
          {formatMessage({ id: 'actionNotifications.location.view' })}
        </Button>)
        title = 'actionNotifications.location.createdTitle'
        titleValues = { locationName: subscriptionEvent.name }
        break

      case 'LOCATION_UPDATED':
        button = (<Button type="primary" size="small" href={`/app/settings/locations/${subscriptionEvent?.locationId}/general`}>
          {formatMessage({ id: 'actionNotifications.location.view' })}
        </Button>)
        title = 'actionNotifications.location.updatedTitle'
        titleValues = { locationName: subscriptionEvent.name }
        break

      case 'LOCATION_DELETED':
        title = 'actionNotifications.location.deletedTitle'
        break

      case 'TEAM_CREATED':
        button = (<Button type="primary" size="small" href={`/app/settings/departments/${subscriptionEvent?.teamId}`}>
          {formatMessage({ id: 'departments.viewDepartment' })}
        </Button>)
        title = 'actionNotifications.team.createdTitle'
        titleValues = { teamName: subscriptionEvent.name }
        break

      case 'TEAM_UPDATED':
        button = (<Button type="primary" size="small" href={`/app/settings/departments/${subscriptionEvent?.teamId}`}>
          {formatMessage({ id: 'departments.viewDepartment' })}
        </Button>)
        title = 'actionNotifications.team.updatedTitle'
        titleValues = { teamName: subscriptionEvent.name }
        break

      case 'TEAM_DELETED':
        title = 'actionNotifications.team.deletedTitle'
        break

      case 'LEAVE_REQUEST_APPROVED':
        title = 'actionNotifications.leave.requestApproved'
        break
      
      case 'LEAVE_REQUEST_STATUS_CHANGED':
        if (subscriptionEvent.leaveStatus === 'DENIED') {
          title = 'actionNotifications.leave.requestDenied'
        } else {
          title = 'actionNotifications.leave.requestApproved'
        }
        break

      case 'LEAVE_REQUEST_DENIED':
        if (result && result?.isEdited && result?.originalVersion.status === 'APPROVED') {
          title = 'actionNotifications.leave.editedRequestDenied'
          description = 'actionNotifications.leave.editedRequestDeniedDescription'
        } else {
          title = 'actionNotifications.leave.requestDenied'
        }
        break

      case 'NOTIFICATION_CREATED':
        title = 'actionNotifications.notification.created'
        break

      case 'NOTIFICATION_UPDATED':
        title = 'actionNotifications.notification.updated'
        break

      case 'LEAVE_TYPE_UPDATED':
        title = 'actionNotifications.leaveType.updated'
        titleValues = { leaveTypeName: subscriptionEvent.name }
        break

      case 'LEAVE_TYPE_CREATED':
        title = 'actionNotifications.leaveType.created'
        titleValues = { leaveTypeName: subscriptionEvent.name }
        break

      case 'LEAVE_TYPE_DELETED':
        title = 'actionNotifications.leaveType.deleted'
        break

      case 'LEAVE_POLICY_ENABLED':
        if (Array.isArray(notifications)) {
          title = 'components.locationLeavePolicy.successTitle'
        } else {
          let correlationId = subscriptionEvent.correlationId
          let currentEvent = notifications[subscriptionEvent.correlationId]
          if(!currentEvent) {
            currentEvent = notifications[`${subscriptionEvent.correlationId}#${subscriptionEvent.eventType}`]
            correlationId = `${subscriptionEvent.correlationId}#${subscriptionEvent.eventType}`
            key = correlationId
          }
          currentEvent.payload.locationIds = currentEvent.payload.locationIds.filter(event => event !== subscriptionEvent?.locationId)

          const totalLocationIds = currentEvent.payload.totalLocationIds
          const procent = (100/totalLocationIds) * (totalLocationIds - currentEvent.payload.locationIds.length)

          description = <Progress percent={Math.round(procent)} size="small" />

          if (currentEvent.payload.locationIds.length === 0) {
            delete notifications[correlationId]
            title = totalLocationIds > 1 ?
              'components.createLeaveType.assignToLocationManyInProgressCompleted' :
              'components.createLeaveType.assignToLocationOneInProgressCompleted'
          } else {
            notifications[correlationId] = currentEvent
            title = totalLocationIds > 1 ?
              'components.createLeaveType.assignToLocationManyInProgress' :
              'components.createLeaveType.assignToLocationOneInProgress'
          }
        }
        break

      case 'IMPORT_USERS':
        title = 'users.importedSuccessfully'
        break

      case 'LEAVE_REQUEST_CANCELLED':
        title = 'user.cancelLeaveRequestTitle'
        break

      case 'LEAVE_REQUEST_ADDED':
        title = 'addLeave.title'
        break

      case 'LEAVE_REQUEST_UPDATED':
        title = 'editLeave.title'
        break

      case 'LEAVE_REQUEST_CREATED':
        title = 'requestLeave.title'
        break
      
      case 'TOIL_REQUEST_CREATED':
        title = 'components.toil.requested'
        break
      
      case 'TOIL_REQUEST_UPDATED':
        title = 'components.toil.edited'
        break

      case 'LOCATION_YEAR_HOLIDAYS_CHANGED':
        title = 'location.holidays.successTitle'
        break

      case 'LOCATION_HOLIDAYS_AUTOIMPORT_CHANGED':
        title = 'location.holidays.autoimportTitle'
        break

      case 'USER_UPDATED':
        title = 'user.updateSuccessTitle'
        break

      case 'USERS_INVITED':
        title = 'user.invitationSuccessTitle'
        break

      case 'USER_INVITATION_DELETED':
        title = 'user.invitationDeletedTitle'
        break

      case 'USER_STATUS_CHANGED':
        if (Array.isArray(notifications)) {
          title = 'user.status.updateSuccessTitle'
        } else {
          const currentEvent = notifications[subscriptionEvent.correlationId]
          if (subscriptionEvent.platform === 'slack') {
            // We don't have slack id in subscriptionEvent.
            currentEvent.payload.users.pop()
          } else {
            currentEvent.payload.users = currentEvent.payload.users.filter(event => event !== subscriptionEvent?.userId)
          }

          const totalUsers = currentEvent.payload.totalUsers
          const procent = (100/totalUsers) * (totalUsers - currentEvent.payload.users.length)
          action = procent === 100 ? 'success' : 'open'
          description = <Progress percent={Math.round(procent)} size="small" />
          if (currentEvent.payload.users.length === 0) {
            delete notifications[subscriptionEvent.correlationId]
            if (currentEvent.payload.type === 'assignLicenses') {
              title = 'manageMicrosoftLicenses.assignLicencesSuccessTitle'
            } else {
              title = totalUsers > 1 ? 'users.importMany.successTitle' : 'users.importOne.successTitle'
            }
          } else {
            notifications[subscriptionEvent.correlationId] = currentEvent
            if (currentEvent.payload.type === 'assignLicenses') {
              title = 'manageMicrosoftLicenses.weAreAssignLicenses'
            } else {
              title = totalUsers > 1 ? 'users.importMany.InProgress' : 'users.importOne.InProgress'
            }
          }
        }
        break

      case 'USER_LEAVE_TYPES_UPDATED':
        title = 'users.updateQuota.successTitle'
        break

      case 'LOCATION_USER_MOVED':
        title = 'user.location.moveSuccessTitle'
        titleValues = { name: subscriptionEvent.name }
        break

      case 'TEAM_USER_MOVED':
        title = 'user.team.moveSuccessTitle'
        titleValues = { name: subscriptionEvent.name }
        break

      case 'LEAVE_REQUEST_DELETED':
        title = 'user.deleteLeaveSuccessTitle'
        break

      case 'USER_WORK_WEEK_UPDATED':
        title = 'user.workWeek.updateSuccessTitle'
        break

      case 'SYNC_EXISTING_USERS':
        title = 'users.syncExistingUsers.successTitle'
        break

      case 'BULK_USER_STATUS':
        title = 'user.bulkChangeStatusSuccessTitle'
        break

      case 'NOTIFICATION_DELETED':
        title = 'actionNotifications.notification.deleted'
        break

      case 'COMPANY_SETTINGS_UPDATED':
        title = 'general.updateSuccessTitle'
        break
      
      case 'COMPANY_PLATFORM_CHANGED':
        title = 'general.updateSuccessTitle'
        break

      case 'BILLING_UPDATED':
        title = 'billing.updatedSuccessfully'
        if (result && result?.subscriptionStatus === 'canceled') {
          title = 'billing.subscriptionExpired'
          description = 'billing.subscriptionExpiredDescription'
          action = 'error'
        }
        //TODO: Check is this if work, on line 36 we have if with (action !== error)
        if (subscriptionEvent.stripeCouponId && action === 'error') {
          description = 'billing.promoCodeErrorDescription'
          descriptionValues.code = subscriptionEvent.stripeCouponId
        }
        break

      case 'SUBSCRIPTION_CANCELED':
        title = 'billing.subscriptionCanceled'
        break

      case 'PAYMENT_PROCESSOR_UPDATED':
        // this event is triggred together with BILLING_UPDATED and we want to avoid showing a success message before the other event is finished
        // BILLING_UPDATED fails if payment is not processed
        title = 'billing.updatedSuccessfully'
        updateNotification = false
        break

      case 'SCHEDULED_REPORT_CREATED':
        button = (<Button type="primary" size="small" href={`/app/reports/scheduled-reports/${subscriptionEvent?.scheduledReportId}/edit`}>
          {formatMessage({ id: 'actionNotifications.scheduledReport.viewScheduledReport' })}
        </Button>)
        title = 'actionNotifications.scheduledReport.createdTitle'
        titleValues = { scheduledReportName: subscriptionEvent.name }
        break

      case 'SCHEDULED_REPORT_UPDATED':
        button = (<Button type="primary" size="small" href={`/app/reports/scheduled-reports/${subscriptionEvent?.scheduledReportId}/edit`}>
          {formatMessage({ id: 'actionNotifications.scheduledReport.viewScheduledReport' })}
        </Button>)
        title = 'actionNotifications.scheduledReport.updatedTitle'
        titleValues = { scheduledReportName: subscriptionEvent.name }
        break

      case 'SCHEDULED_REPORT_DELETED':
        title = 'actionNotifications.scheduledReport.deletedTitle'
        break

      case 'ANNOUNCE_VT_COMPANY':
        title = 'actionNotifications.companyAnnouncement.success'
        break

      case EventTypeEnum.USER_OUT_OF_OFFICE_SETTINGS_UPDATED:
        title = 'actionNotifications.outOfOfficeSetting.success'
        break
      
      case 'BLACKOUT_PERIOD_CREATED':
        title = 'automations.blackoutPeriod.createInProgressCompleted'
        break
      
      case 'OPEN_API_ADDON_CREATED':
        title = 'addons.openApi.createInProgressCompleted'
        break
      
      case 'OPEN_API_ADDON_DELETED':
        title = 'addons.openApi.openApiDeleted'
        break

      case 'BLACKOUT_PERIOD_UPDATED':
        title = 'automations.blackoutPeriod.updateInProgressCompleted'
        break

      case 'PROBATION_PERIOD_CREATED':
        title = 'automations.probationPeriod.createInProgressCompleted'
        break

      case 'PROBATION_PERIOD_UPDATED':
        title = 'automations.probationPeriod.updateInProgressCompleted'
        break

      case 'BLACKOUT_PERIOD_DELETED':
        title = 'automations.automationBlackoutPeriodDeleted'
        break

      case 'SENIORITY_ENTITLEMENT_DELETED':
        title = 'automations.automationSeniorityEntitlementDeleted'
        break

      case 'ENTITLEMENT_BY_ROLE_DELETED':
        title = 'automations.automationEntitlementByRoleDeleted'
        break

      case 'PROBATION_PERIOD_DELETED':
        title = 'automations.automationProbationPeriodDeleted'
        break

      case 'SENIORITY_ENTITLEMENT_CREATED':
        title = 'automations.seniorityEntitlement.createInProgressCompleted'
        break

      case 'SENIORITY_ENTITLEMENT_UPDATED':
        title = 'automations.seniorityEntitlement.updateInProgressCompleted'
        break
      
      case 'ENTITLEMENT_BY_ROLE_CREATED':
        title = 'automations.entitlementByRole.createInProgressCompleted'
        break

      case 'ENTITLEMENT_BY_ROLE_UPDATED':
        title = 'automations.entitlementByRole.updateInProgressCompleted'
        break      
      
      case 'VISIBILITY_ADDON_UPDATED':
        title = 'automations.visibility.updateInProgressCompleted'
        break
      case 'VISIBILITY_ADDON_CREATED':
        title = 'automations.visibility.createInProgressCompleted'
        break
      
      case 'ADDONS_UPDATED':
        if (subscriptionEvent.isActive) {
          title = 'automations.addpnSuccessfullyAdded'
          description = 'automations.addpnSuccessfullyAddedDescription'
        } else {
          title = 'automations.addpnSuccessfullyDeactivated'
          description = 'automations.addpnSuccessfullyDeactivatedDescription'
        }
        break
    }
  }

  const response: INotificationInfoResponse = {
    action: newAction ? newAction : action,
    notifications,
    options: {
      key,
      message,
      duration: action === 'success' ? 10 : 0,
      updateNotification,
      forceErrorMessage,
    },
  }

  if (key) {
    response.options.key = key
  }

  if (title) {
    response.options.title = title
    response.options.titleValues = titleValues
  }

  if (description) {
    response.options.description = description
    response.options.descriptionValues = descriptionValues
  }

  if (button) {
    response.options.btn = button
  }

  if (Array.isArray(notifications)) {
    notifications = notifications.filter(value => value !== key)
  }

  response.notifications = notifications

  return response
}

const numberToDay = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
}

export const getNumberToDay = (day, formatMessage) => {
  const mappedDay = numberToDay[day]
  return {
    full: formatMessage({ id: `app.${mappedDay}` }),
    short: formatMessage({ id: `app.${mappedDay}.short` }),
  }
}
