export function getColumnsWithMonths(columns: string[]) {
  const currentMonth = new Date().getMonth() + 1
  const currentYear = new Date().getFullYear()

  const months = Array.from({ length: 12 }, (_, i) => {
    const month = (currentMonth + i - 1) % 12 + 1
    const year = currentMonth + i > 12 ? currentYear + 1 : currentYear
    return getMonthKey(month, year)
  })
  return columns.concat(months)
}

export function getMonthKey(month: number, year: number) {
  return `month-${month}-${year}`
}