import { 
  DateFormat, Display, IExportLeaveBalanceData, IExportLeaveBalanceMonthlyData, IExportLeaveHistoryData, ReportPeriod, ReportType 
} from '@vacationtracker/shared/types/export'
import { Dayjs } from 'dayjs'

export interface IExportFiltersResponse {
  getLocationList: IExportFilterProps[]
  getTeamList: IExportFilterProps[]
  getLeaveTypeList: IExportLeaveTypeProps[]
  getLabels: IExportFilterProps[]
  getUsersFiltered: {
    users: IExportFilterProps[]
  }
}

export interface IReportTableProps {
  columnNames: string[]
  data: any[]
  total: number
  page: number
  loading: boolean
  dateFormat: DateFormat
  display: Display
  reportType: ReportType
  expandableKey?: string
  editableColumns?: boolean
  loadPrevious: () => void
  loadNext: () => void
  download: () => void
}

export interface IColumnProps {
  title: string
  dataIndex: string
  key: string
  visible: boolean
  render?: (text: string, record: any) => JSX.Element|string
}

export interface IExportFilterProps {
  id: string
  name: string
}

export interface IExportLeaveTypeProps extends IExportFilterProps {
  position: number
}

export interface ILeaveReportProps {
  companyData: IExportFiltersResponse
  total: number
  loading: boolean
  page: number
  display: Display
  onFiltersChange: (filters: { [key: string]: string[] }) => void
  onDisplayChange: (display: Display) => void
  loadNext: () => void
  loadPrevious: () => void
  download: () => void
}

export interface ILeaveHistoryReportProps extends ILeaveReportProps {
  data: IExportLeaveHistoryData[]
  dateFormat: DateFormat
  defaultDates: [Dayjs, Dayjs]
  onDateFormatChange: (dateFormat: DateFormat) => void
  onPeriodChange: (dates: [Dayjs, Dayjs], period?: ReportPeriod) => void
}

export interface ILeaveBalanceReportProps extends ILeaveReportProps {
  data: IExportLeaveBalanceData[]
  defaultDates: [Dayjs, Dayjs]
  onPeriodChange: (dates: [Dayjs, Dayjs], period?: ReportPeriod) => void
}

export interface ILeaveBalanceMonthlyReportProps extends ILeaveReportProps {
  data: IExportLeaveBalanceMonthlyData[]
}

export const reportResultsLimit = 10
export const EXPORT_TIME_WAIT_MINUTES = 3
export const EXPORT_TIME_POLL_INTERVAL_SECONDS = 10
export const EXPORT_LS_KEY = 'waiting_for_export'

export interface IReportQuery {
  lastId?: string
  page?: number
  limit?: number
  // Dynamic filter properties
  [key: string]: string[] | number | string | undefined
  columns?: string[]
}

export interface ILeaveReportQuery extends IReportQuery {
  startDate: string
  endDate: string
}

export interface IBalanceReportQuery extends IReportQuery {
  period: ReportPeriod
  startDate?: string
  endDate?: string
}

interface IFilter {
  type: string
  options: IExportFilterProps[]
  label: string
}

export interface IReportFilterProps {
  filters: IFilter[]
  onFilterChange: (selectedFilters: { [key: string]: string[] }) => void
  defaultSelectedValues?: { [key: string]: string[] }
}

export interface IFilterPopoverProps {
  filterType: string
  options: IExportFilterProps[]
  label: string
  selectedValues: string[]
  onApply: (filterType: string, values: string[]) => void
  onReset: (filterType: string) => void
  triggerIcon: React.ReactNode
}

export interface IPeriodFilterProps {
  defaultDates: [Dayjs, Dayjs]
  dateFormat: DateFormat
  onFilterChange: (dates: [Dayjs, Dayjs]) => void
}

export interface IReportDisplayProps {
  defaultDisplay: Display
  defaultDateFormat?: DateFormat
  onDisplayChange: (display: Display) => void
  onDateFormatChange?: (dateFormat: DateFormat) => void
}

export interface IBalancePeriodProps {
  onChange: (balancePeriod: ReportPeriod) => void
}
