import React, { useState } from 'react'
import { Result, Row, Col, Button, Typography, Skeleton, Alert } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

import { displayLeaveInDaysAndHours } from '../display-leave-in-days-and-hours'
import { formatDateToLocale } from '../formatted-date'

import { FeedbackModal } from '../feedback-modal'
import IntlMessages from '../utils/IntlMessages'
import UserLeaveQuotasCompact from '../user-leave-quotas/compact'

import { IUserQuota, IUserLocation, IGetUserForLeaveReueqstUserLeaveDaysLeaveTypes, IUserLocationLeavePolicies } from '../../../frontend/src/types/custom-queries'
import { ILeaveTypeResult } from '../../types/calculations'
import { LocaleEnum } from '../../types/i18n'
import {
  ILeaveRequestOverlappingInfoParams,
  ILeaveRequestQuotaParams,
  ILeaveRequestSummaryParams
} from './types'
import { displayQuota } from '../../functions/get-days-or-hours'

const { Text } = Typography

export const getLeavePolicyFromQuota = (leaveTypeId: string, quota): ILeaveTypeResult | IGetUserForLeaveReueqstUserLeaveDaysLeaveTypes | null => {
  const leavePolicy = quota?.leaveTypes.find(q => q.leaveTypeId === leaveTypeId || `${q.leaveTypeId}|part-day` === leaveTypeId)
  if (leavePolicy) {
    return leavePolicy
  }
  return null
}

export const LeaveRequestOverlappingInfo: React.FC<ILeaveRequestOverlappingInfoParams>= ({
  isLoadingOverlappingUsersInfo,
  overlappingUsersInfo,
}) => {
  return <div>
    {isLoadingOverlappingUsersInfo ? <div>
      <Skeleton.Input size="large" style={{ width: '90vw' }} active />
    </div> : overlappingUsersInfo && <>
      <Alert
        message={overlappingUsersInfo.message}
        type={overlappingUsersInfo.employees?.length > 0 ? 'warning' : 'success'}
      />
    </>}
  </div>
}

export const LeaveRequestSummary: React.FC<ILeaveRequestSummaryParams> = ({
  userId,
  isLeaveAddition,
  leaveRequestInfo,
  approvers,
  resetLeaveRequestForm,
  onSave,
  futureAccruals,
  locale,
  numberOfWorkingHoursPerDay,
}) => {
  const { formatMessage } = useIntl()
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [isFeedbackSent, setIsFeedbackSent] = useState(false)

  const displayRemainingQuotaForCurrentYear = () => {
    if (futureAccruals?.daysOnLeaveEndDates as number > 0) {
      return `${displayLeaveInDaysAndHours({
        value: futureAccruals?.daysOnLeaveEndDates as number,
        formatMessage,
        numberOfWorkingHoursPerDay,
      })}${futureAccruals?.endDate && futureAccruals?.allowAdvanceAccrualUsage ?
        ` (${formatMessage({ id: 'bot.message.on' })} ${formatDateToLocale(futureAccruals.endDate as unknown as string, 'dddd, MMM D, YYYY', locale)})` : ''
      }`
    }
    return displayQuota(leaveRequestInfo.remainingAmountCurrentYear, formatMessage)
  }

  const toggleFeedbackModal = () => setShowFeedbackModal(!showFeedbackModal)

  const handleSendFeedback = async (data) => {
    onSave && await onSave({
      eventGroup: 'FEEDBACK',
      eventType: 'USER_FEEDBACK',
      userId,
      ...data,
    })
    setShowFeedbackModal(false)
    setIsFeedbackSent(true)
  }

  return <div>
    <Result
      icon={<CheckCircleOutlined />}
      title={<IntlMessages id={isLeaveAddition ? 'addLeave.title' : 'requestLeave.title'} />}
      subTitle={isLeaveAddition
        ? null
        : leaveRequestInfo?.isApprovalRequired
          ? <IntlMessages id='leaveRequest.simpleQuota.summaryInfo' />
          : <IntlMessages id='components.leaveRequestAction.autoApproved' values={{ requestor: '' }} />}
      extra={isFeedbackSent && <div style={{ color: '#7f00ff' }}><IntlMessages id="bot.message.feedback.thanks" /></div>}
    />
    <div className='vt-leave-request-additional-info' style={{width: '50%', margin: 'auto'}}>
      <div>
        <Row>
          <Col span={12}>{formatMessage({ id: 'components.leaveRequestAction.leaveTypeLabel' })}</Col>
          <Col span={12}>{formatMessage({ id: 'components.leaveRequestAction.periodLabel' })}</Col>
        </Row>
        <Row>
          <Col span={12}><Text strong>{leaveRequestInfo.leaveTypeName}</Text></Col>
          <Col span={12}><Text strong>{leaveRequestInfo.leavePeriod}</Text></Col>
        </Row>
      </div>
      <br />
      {leaveRequestInfo.requestedAmountCurrentYear.raw.days > 0 &&
        <div>
          <Row>
            <Col span={12}>{formatMessage({ id: isLeaveAddition ? 'leaveRequests.addedCurrentYear' : 'leaveRequests.requestedCurrentYear'})}</Col>
            <Col span={12}>{formatMessage({ id: 'leaveRequests.remainingCurrentYear' })}</Col>
          </Row>
          <Row>
            <Col span={12}>
              <Text strong>{displayQuota(leaveRequestInfo.requestedAmountCurrentYear, formatMessage)}</Text>
            </Col>
            <Col span={12}>
              <Text strong>{leaveRequestInfo.hasUnlimitedDays ? formatMessage({ id: 'app.unlimited' }) : displayRemainingQuotaForCurrentYear()}</Text>
            </Col>
          </Row>
        </div>
      }
      <br />
      {leaveRequestInfo.requestedAmountNextYear.raw.days > 0 &&
        <div>
          <Row>
            <Col span={12}>{formatMessage({ id: isLeaveAddition ? 'leaveRequests.addeddNextYear' : 'leaveRequests.requestedNextYear'})}</Col>
            <Col span={12}>{formatMessage({ id: 'leaveRequests.remainingNextYear'})}</Col>
          </Row>
          <Row>
            <Col span={12}>
              <Text strong>{displayQuota(leaveRequestInfo.requestedAmountNextYear, formatMessage)}</Text>
            </Col>
            <Col span={12}>
              <Text strong>{leaveRequestInfo.hasUnlimitedDaysNextYear
                ? formatMessage({ id: 'app.unlimited' })
                : displayQuota(leaveRequestInfo.remainingAmountNextYear, formatMessage)}</Text>
            </Col>
          </Row>
        </div>
      }
      <br />
      {approvers?.length > 0 && <div>
        <Row>
          <Col span={12}>{formatMessage({ id: 'app.role.approvers'})}:</Col>
        </Row>
        <Row>
          <Text className="vt-approvers-name">{approvers.map(approver => approver.name).join(', ')}</Text>
        </Row>
      </div>}
      <br />
      <Row justify='center'>
        <Col style={{ marginRight: 10 }}>
          {!isFeedbackSent &&
            <Button type='primary' onClick={toggleFeedbackModal}>
              <IntlMessages id='components.feedbackModal.button' />
            </Button>
          }
        </Col>
        <Col>
          <Button onClick={resetLeaveRequestForm}>
            <IntlMessages id={isLeaveAddition ? 'leaveRequest.simpleQuota.addAnotherLeave' : 'leaveRequest.simpleQuota.requestAnotherLeave'} />
          </Button>
        </Col>
      </Row>
      <FeedbackModal
        visible={showFeedbackModal}
        onCancel={toggleFeedbackModal}
        onSave={handleSendFeedback}
      />
    </div>
  </div>
}

export const LeaveRequestQuota: React.FC<ILeaveRequestQuotaParams> = ({
  currentQuota,
  nextYearQuota,
  userQuotasForCurrentYear,
  userQuotasForNextYear,
  leavePolicy,
  user,
  overlappingUsersInfo,
  partDayLeaveTotalHours,
  isAddingLeave,
  authUserLocale,
}) => {
  const { formatMessage } = useIntl()

  const showCurrentYear = Boolean(overlappingUsersInfo && overlappingUsersInfo?.year1Days)
  const showNextYear = Boolean(overlappingUsersInfo && overlappingUsersInfo?.year2Days)

  return <div>
    {userQuotasForCurrentYear && currentQuota && showCurrentYear &&
      <UserLeaveQuotasCompact
        currentQuota={currentQuota as IUserQuota}
        selectedLeaveType={userQuotasForCurrentYear as ILeaveTypeResult}
        selectedLeavePolicy={leavePolicy as IUserLocationLeavePolicies}
        quotas={user?.leaveDays as IUserQuota[]}
        userId={user?.id as string}
        IntlMessages={IntlMessages}
        location={user?.location as IUserLocation}
        userName={user?.name as string}
        hourlyLeaveAccounting={false}
        workingHours={overlappingUsersInfo?.userWorkingHours}
        locale={authUserLocale || user?.locale as LocaleEnum}
        requested={overlappingUsersInfo?.year1Days as number}
        partDayLeaveTotalHours={partDayLeaveTotalHours as number}
        title={formatMessage({ id: isAddingLeave ? 'components.userLeaveQuotasCompact.titleForApprover' : 'components.userLeaveQuotasCompact.title' })}
      />
    }

    {userQuotasForNextYear && nextYearQuota && showNextYear &&
      <UserLeaveQuotasCompact
        currentQuota={(showCurrentYear ? nextYearQuota : currentQuota) as IUserQuota}
        selectedLeaveType={(showCurrentYear ? userQuotasForNextYear : userQuotasForCurrentYear) as ILeaveTypeResult}
        selectedLeavePolicy={leavePolicy as IUserLocationLeavePolicies}
        quotas={user?.leaveDays as IUserQuota[]}
        userId={user?.id as string}
        IntlMessages={IntlMessages}
        location={user?.location as IUserLocation}
        userName={user?.name as string}
        hourlyLeaveAccounting={false}
        locale={user?.locale as LocaleEnum}
        requested={overlappingUsersInfo?.year2Days as number}
        partDayLeaveTotalHours={partDayLeaveTotalHours as number}
        title={''}
        workingHours={overlappingUsersInfo?.userWorkingHours}
      />
    }
  </div>
}
