import React, { useState } from 'react'
import { Popover, Select, Button, Typography } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../../util/IntlMessages'
import { DateFormat, Display } from '@vacationtracker/shared/types/export'
import { IReportDisplayProps } from './types'

const { Option } = Select
const { Text } = Typography

const ReportDisplay: React.FC<IReportDisplayProps> = ({
  defaultDisplay,
  defaultDateFormat,
  onDisplayChange,
  onDateFormatChange,
}) => {
  const [display, setDisplay] = useState<Display>(defaultDisplay)
  const [dateFormat, setDateFormat] = useState<DateFormat|undefined>(defaultDateFormat)
  const [tempDisplay, setTempDisplay] = useState<Display>(defaultDisplay)
  const [tempDateFormat, setTempDateFormat] = useState<DateFormat|undefined>(defaultDateFormat)
  const [displayPopoverVisible, setDisplayPopoverVisible] = useState(false)
  const [dateFormatPopoverVisible, setDateFormatPopoverVisible] = useState(false)

  const handleDisplayChange = (value: Display) => {
    setTempDisplay(value)
  }

  const handleDateFormatChange = (value: DateFormat) => {
    setTempDateFormat(value)
  }

  const handleApplyDisplay = () => {
    setDisplay(tempDisplay)
    onDisplayChange(tempDisplay)
    setDisplayPopoverVisible(false)
  }

  const handleApplyDateFormat = () => {
    setDateFormat(tempDateFormat)
    onDateFormatChange && onDateFormatChange(tempDateFormat as DateFormat)
    setDateFormatPopoverVisible(false)
  }

  const handleDisplayOpenChange = (open: boolean) => {
    setDisplayPopoverVisible(open)
    if (!open) {
      setTempDisplay(display)
    }
  }

  const handleDateFormatOpenChange = (open: boolean) => {
    setDateFormatPopoverVisible(open)
    if (!open) {
      setTempDateFormat(dateFormat)
    }
  }

  return (
    <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
      <Popover
        content={
          <div>
            <div style={{ marginBottom: '10px' }}>
              <Text><IntlMessages id="app.display" /></Text>
              <Select
                value={tempDisplay}
                onChange={handleDisplayChange}
                style={{ width: 120, marginLeft: '8px' }}
              >
                <Option value="days"><IntlMessages id="app.days" /></Option>
                <Option value="hours"><IntlMessages id="app.hours" /></Option>
              </Select>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Button type="primary" onClick={handleApplyDisplay} style={{ marginRight: '8px' }}>
                <IntlMessages id="app.apply" />
              </Button>
            </div>
          </div>
        }
        title={<IntlMessages id="app.display" />}
        trigger="click"
        placement="bottom"
        open={displayPopoverVisible}
        onOpenChange={handleDisplayOpenChange}
      >
        <div className="popover-trigger-container">
          <MinusCircleOutlined />
          <label>
            <IntlMessages id="app.display" />: {display}
          </label>
        </div>
      </Popover>

      {defaultDateFormat && <><Popover
        content={
          <div>
            <div style={{ marginBottom: '10px' }}>
              <Text><IntlMessages id="app.dateFormat" /></Text>
              <Select
                value={tempDateFormat}
                onChange={handleDateFormatChange}
                style={{ width: 180, marginLeft: '8px' }}
              >
                <Option value="YYYY-MM-DD">YYYY-MM-DD</Option>
                <Option value="MM/DD/YYYY">MM/DD/YYYY</Option>
              </Select>
            </div>
            <div style={{ marginTop: '10px' }}>
              <Button type="primary" onClick={handleApplyDateFormat} style={{ marginRight: '8px' }}>
                <IntlMessages id="app.apply" />
              </Button>
            </div>
          </div>
        }
        title={<IntlMessages id="app.dateFormat" />}
        trigger="click"
        placement="bottom"
        open={dateFormatPopoverVisible}
        onOpenChange={handleDateFormatOpenChange}
      >
        <div className="popover-trigger-container">
          <MinusCircleOutlined />
          <label>
            <IntlMessages id="app.dateFormat" />: {dateFormat}
          </label>
        </div>
      </Popover></>}
    </div>
  )
}

export default ReportDisplay
