/* eslint-disable max-len */
import React from 'react'
import { Button, Card, Drawer, Typography } from 'antd'

import IntlMessages from '../../util/IntlMessages'
import { getOnboardingLink } from '../../util/get-onboarding-link'

import { Platform } from '@vacationtracker/shared/types/core-event'
import { openChat } from '../../util/set-crisp-session-info'

interface Props {
  isHelpDrawerVisible: boolean
  onHelpDrawerClose: () => void
  startQuickTour: () => void
  userRole: string
  platform: Platform
}

const { Paragraph } = Typography

export const HelpDrawer: React.FC<Props> = ({
  isHelpDrawerVisible,
  onHelpDrawerClose,
  startQuickTour,
  userRole,
  platform,
}: Props) => {

  const onSupportChatOpen = () => {
    onHelpDrawerClose()
    openChat()
  }

  return (
    <Drawer
      title={<IntlMessages id="components.helpDrawer.needhelp" />}
      placement="right"
      onClose={onHelpDrawerClose}
      open={isHelpDrawerVisible}
      width={Math.min(450, window.innerWidth)}
    >
      <div className="help-drawer-inner">
        <Card
          title={<IntlMessages id="app.helpdesk" />}
          extra={
            <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noreferrer">
              <IntlMessages id="app.visitHelpdesk" />
            </a>
          }
          className="help-drawer-card"
        >
          <Paragraph style={{ whiteSpace: 'break-spaces' }}>
            <IntlMessages id="components.helpDrawer.helpDeskInfo" />
          </Paragraph>
        </Card>
        <Card
          title={<IntlMessages id="components.helpDrawer.supportTitle" />}
          extra={(window && window.$crisp) ?
            <Button type="link" onClick={onSupportChatOpen}>
              <IntlMessages id="app.contactSupport" />
            </Button> :
            <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noreferrer">
              <IntlMessages id="app.contactSupport" />
            </a>
          }
          className="help-drawer-card"
        >
          <Paragraph style={{ whiteSpace: 'break-spaces' }}>
            <IntlMessages
              id="components.helpDrawer.supportInfo"
              values={{
                link: (...chunks) => <a href="mailto:hello@vacationtracker.io">{chunks}</a>,
              }}
            />
          </Paragraph>
        </Card>
        <Card
          title={<IntlMessages id="components.helpDrawer.quickTourTitle" />}
          extra={<Button type="link" className="joyride-start" onClick={startQuickTour} style={{ paddingLeft: 0 }}>
            <IntlMessages id="app.takeQuickTour" />
          </Button>}
          className="help-drawer-card"
        >
          <Paragraph style={{ whiteSpace: 'break-spaces' }}>
            <IntlMessages id="components.helpDrawer.quickTourInfo" />
          </Paragraph>
        </Card>
        <Card
          title={
            userRole === 'User' ? <IntlMessages id="components.helpDrawer.userProductTourTitle" /> :
              userRole === 'Approver' ? <IntlMessages id="components.helpDrawer.approverProductTourTitle" /> :
                <IntlMessages id="components.helpDrawer.adminProductTourTitle" />
          }
          extra={
            <a href={getOnboardingLink(platform, userRole)} target="_blank" rel="noreferrer">
              <IntlMessages id="app.learnMore" />
            </a>
          }
          className="help-drawer-card"
        >
          {platform !== 'google' &&
            <Paragraph style={{ whiteSpace: 'break-spaces' }}>
              { userRole === 'User' && <IntlMessages id="components.helpDrawer.userProductTourInfo" /> }
              { userRole === 'Approver' && <IntlMessages id="components.helpDrawer.approverProductTourInfo" /> }
              { userRole === 'Admin' && <IntlMessages id="components.helpDrawer.adminProductTourInfo" /> }
              <a href="mailto:hello@vacationtracker.io">hello@vacationtracker.io</a>.
            </Paragraph>
          }
          {platform === 'google' &&
            <Paragraph style={{ whiteSpace: 'break-spaces' }}>
              { userRole === 'User' && <IntlMessages id="components.helpDrawer.google.userProductTourInfo" /> }
              { userRole === 'Approver' && <IntlMessages id="components.helpDrawer.google.approverProductTourInfo" /> }
              { userRole === 'Admin' && <IntlMessages id="components.helpDrawer.google.adminProductTourInfo" /> }
              <a href="mailto:hello@vacationtracker.io">hello@vacationtracker.io</a>.
            </Paragraph>
          }
        </Card>
      </div>
    </Drawer>
  )
}
