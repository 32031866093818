import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Col, Divider, Form, Input, Row, Select, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'
import { filterOptions } from '../../util/filter-options'
import YesNoSwitch from '../yes-no-switch'

import { IEditTeamById, IGetUserListShort } from '../../types/teams'
import { ApproversLevelEnum, ITeamShort } from '@vacationtracker/shared/types/team'
import { SubscriptionPlan, SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { Platform, PlatformEnum } from '@vacationtracker/shared/data/platforms'

interface ITeamForm {
  team: IEditTeamById
  teams: ITeamShort[]
  users: IGetUserListShort[]
  totalTeams: number
  formType: 'create' | 'update'
  plan: SubscriptionPlan
  platform: Platform
  allowMultiLevelApproval: boolean // Delete after allow for all companies
}

const { Paragraph, Title, Text } = Typography

const TeamForm = ({ team, users, teams, totalTeams, formType, plan, platform, allowMultiLevelApproval }: ITeamForm): React.ReactElement => {
  const { formatMessage } = useIntl()

  const [approvalLevel, setApprovalLevel] = useState<string>(team.approvalLevel)

  const isRemovalFromTeamDisabled = (userId: string) => {
    if (!team.isDefault) {
      return false
    }
    return team.users.find(id => id === userId) !== undefined
  }


  return (
    <>
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}>
            <IntlMessages id="form.department.generalTitle" />
          </Title>
          <Paragraph type="secondary">
            {
              formType === 'create' ? (
                <IntlMessages id="form.department.generalDescriptionCreate" />
              ) : (
                <IntlMessages id="form.department.generalDescriptionEdit" />
              )
            }
          </Paragraph>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            name="name"
            label={<IntlMessages id="app.name" />}
            rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
          >
            <Input maxLength={100} placeholder={`${formatMessage({ id: 'components.teamForm.pleaseEnterTeamName' })}`} data-qa='department-name' />
          </Form.Item>
          <Form.Item
            name="users"
            label={<>
              <span>{formatMessage({ id: 'app.users' })}</span>
              {team.isDefault && <Tooltip className='info-tooltip' title={<IntlMessages id="teams.removeUserInfo" />} ><InfoCircleOutlined/></Tooltip>}
            </>}
          >
            <Select
              mode="multiple"
              disabled={(team.id && totalTeams === 1) ? true : false}
              filterOption={filterOptions}
            >
              {users.map(user => <Select.Option disabled={isRemovalFromTeamDisabled(user.id)} key={user.id} value={user.id}>{user.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <>
            <Title level={4}>
              <IntlMessages id="form.department.approverTitle" />
            </Title>
            <Paragraph type="secondary">
              <IntlMessages id="form.department.approverDescription" />
            </Paragraph>
          </>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            name="approvalLevel"
            label={formatMessage({ id: 'form.department.apprverFlowType' })}
            rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
          >
            <Select onChange={(value: string) => setApprovalLevel(value)}>
              <Select.Option value={ApproversLevelEnum.FIRST}><IntlMessages id="form.department.apprverFlowType.singleLevel" /></Select.Option>
              {(plan === SubscriptionPlanEnum.complete || allowMultiLevelApproval) && platform === PlatformEnum.Microsoft ?
                <Select.Option value={ApproversLevelEnum.SECOND}><IntlMessages id="form.department.apprverFlowType.multiLevel" /></Select.Option> :
                <Select.Option value={ApproversLevelEnum.SECOND} disabled={true}>
                  <IntlMessages id="form.department.apprverFlowType.multiLevel" />&nbsp;
                  {platform !== PlatformEnum.Microsoft && <><IntlMessages id="app.coming-soon" />&nbsp;</>}
                  {plan === SubscriptionPlanEnum.core &&
                    <Text code>
                      <IntlMessages id="calendar.sync.connectCalendarCompletePlanyOnly" />
                    </Text>
                  }
                </Select.Option>
              }
            </Select>
          </Form.Item>

          <Form.Item
            name="approverIdsFirstLevel"
            label={approvalLevel === ApproversLevelEnum.SECOND ? <IntlMessages id="form.department.approversFirstLevel" /> : <IntlMessages id="form.department.approvers" />}
            rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
          >
            <Select
              mode="multiple"
              filterOption={filterOptions}
              data-qa='teams-approvers'
            >
              {users.map(user => <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>)}
            </Select>
          </Form.Item>
          
          {approvalLevel === ApproversLevelEnum.SECOND && 
            <Form.Item
              name="approverIdsSecoundLevel"
              label={<IntlMessages id="form.department.approversSecondLevel" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              data-qa='teams-approvers-second-level'
            >
              <Select
                mode="multiple"
                filterOption={filterOptions}
              >
                {users.map(user => <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>)}
              </Select>
            </Form.Item>
          }
        </Col>
      </Row>

      <Divider />

      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}>
            <IntlMessages id="form.department.setDefaultTitle" />
          </Title>
          <Paragraph type="secondary">
            <IntlMessages id="components.teamForm.isDefaultExtra" />
          </Paragraph>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <YesNoSwitch
            name="isDefault"
            label={`${formatMessage({ id: 'components.teamForm.isDefault' })}`}
            disabled={Boolean(team.isDefault || (team.id && totalTeams === 1))}
          />
        </Col>
      </Row>

      <Divider />

      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}>
            <IntlMessages id="form.department.subdepartmentTitle" />
          </Title>
          <Paragraph type="secondary">
            <IntlMessages id="form.department.subdepartmentDescription" />
          </Paragraph>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            name="parentId"
            label={<IntlMessages id="teamForm.setAsSubdepartment" />}
          >
            <Select
              filterOption={filterOptions}
            >
              <Select.Option value={null}>{formatMessage({ id: 'app.none' })}</Select.Option>
              {teams.filter(t => t.id !== team.id).map(t => <Select.Option key={t.id} value={t.id}>{t.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      
    </>
  )
}

export default TeamForm
