import { DEFAULT_WORKING_HOURS_IN_DAY } from '../data/app-parameters'
import { IFormatMessage, IGetFormattedDaysAndHours, LeaveDisplayFormatEnum } from '../types/display-in-days-hours'
import { IQuotaAmount, QuotaUnits } from '../types/leave-policy'
import { WorkingHours } from '../types/work-week'
import { IPartDay } from '../types/leave-request'
import { HourFormatEnum } from '../types/user'
import { roundTo2Decimals, roundTo4Decimals } from './calculation-shared'
import { isInteger } from 'lodash'
import { getUserWorkingHoursPerDay } from './work-week'

export interface IComputeDaysAndHours {
  days: number
  hours: number
  minutes: number
  isNegative: boolean
}

// TODO workday change this once we implement user workweek schedule
// get quota in days or hours depending on the hourlyLeaveAccounting, days value is used as input value
export function getDaysOrHours(valueInDays: number, hourlyLeaveAccounting: boolean, numberOfWorkingHoursPerDay: number): number {
  if (hourlyLeaveAccounting) {
    return valueInDays * numberOfWorkingHoursPerDay
  } else {
    return valueInDays
  }
}

// get raw quota in units which are set in general settings (hourlyLeaveAccounting) - ignore units in which the quota was originally set
export function getRawQuotaInSettingsUnit(quota: IQuotaAmount, hourlyLeaveAccounting: boolean): number {
  return hourlyLeaveAccounting ? quota.raw.hours : quota.raw.days
}

// get quota in units which are set in general settings (hourlyLeaveAccounting) - ignore units in which the quota was originally set
// if saved units are diffrent from the units in settings, convert the value by multiplying or dividing by working hours
export function getQuotaInSettingsUnits(
  hourlyLeaveAccounting: boolean,
  workingHours: number,
  inDaysValue: number,
  inHoursValue?: number,
  quotaSetIn?: QuotaUnits
): number {
  if (hourlyLeaveAccounting) {
    return quotaSetIn === 'hours' ? Number(inHoursValue) : roundTo4Decimals(inDaysValue * workingHours)
  }
  return quotaSetIn === 'hours' ? roundTo4Decimals(Number(inHoursValue) / workingHours) : inDaysValue
}

// get quota in units in which it was originally set (either days or hours)
export function getQuotaInOriginalUnits(inDaysValue: number, inHoursValue: number, units: QuotaUnits): number {
  return units === 'hours' ? inHoursValue : inDaysValue
}

// get quota in units in which it is set (either days or hours) if it is set - new companies will have both days, hours and quotaSetIn params set
// old companies will have only inDays values set and we will use hourlyLeaveAccounting to determine the units and convert the hours to days with default working hours
export function getQuotaInUnitsIfSet(inDaysValue: number, inHoursValue: number,  hourlyLeaveAccounting: boolean, quotaSetIn?: QuotaUnits): number {
  if (!quotaSetIn) {
    inHoursValue = inDaysValue * DEFAULT_WORKING_HOURS_IN_DAY
    quotaSetIn = hourlyLeaveAccounting ? 'hours' : 'days'
  }
  return getQuotaInOriginalUnits(inDaysValue, inHoursValue, quotaSetIn)
}

// function to convert the quota in days or in hours depending on the units
export function convertQuota(quota: number, units: QuotaUnits, workingHours: number): {days: number; hours: number} {
  const days = units === 'days' ? quota : roundTo2Decimals(quota / workingHours)
  const hours = units === 'hours' ? quota : roundTo2Decimals(quota * workingHours)
  return { days, hours }
}

// get quota in days no matter in which units it is set
export function getQuotaInDays(inDaysValue: number, inHoursValue: number, workHours: number, quotaSetIn?: QuotaUnits): number {
  if (quotaSetIn === 'hours' && inHoursValue) {
    return inHoursValue / workHours
  }
  return inDaysValue
}

export function setDaysOrHours(value: number, hourlyLeaveAccounting: boolean, numberOfWorkingHoursPerDay: number): number {
  if (hourlyLeaveAccounting) {
    return roundTo4Decimals(value / numberOfWorkingHoursPerDay)
  } else {
    return value
  }
}

export function computeDaysAndHours(hours: number, numberOfWorkingHoursPerDay: number): IComputeDaysAndHours {
  const numberOfMinutesPerHour = 60
  const totalMinutes = hours * numberOfMinutesPerHour
  const workingDayMinutes = numberOfWorkingHoursPerDay * numberOfMinutesPerHour
  const isNegative = Math.sign(hours) === -1

  const absMinutes = Math.abs(totalMinutes)
  let days = Math.floor(absMinutes / workingDayMinutes)
  const remainingMinutes = absMinutes % workingDayMinutes
  let computedHours = Math.floor(remainingMinutes / numberOfMinutesPerHour)
  let minutes = Math.round(remainingMinutes % numberOfMinutesPerHour)

  if (minutes === 60) {
    computedHours += 1
    minutes = 0
  }

  if (computedHours === numberOfWorkingHoursPerDay) {
    days += 1
    computedHours = 0
  }

  const roundedMinutes = isInteger(numberOfWorkingHoursPerDay) ? 0 : Math.floor(minutes / 15) * 15

  return {
    days,
    hours: computedHours,
    minutes: roundedMinutes,
    isNegative,
  }
}

//same like getFormattedDaysAndHours() but it takes IQuotaAmount as input
export const displayQuota = (quota: IQuotaAmount, formatMessage: any, displayFormat: LeaveDisplayFormatEnum = LeaveDisplayFormatEnum.long): string => {
  return getFormattedDaysAndHours({
    leave: {
      days: quota.days,
      hours: quota.hours,
      minutes: quota.minutes,
      isNegative: quota.isNegative,
    },
    formatMessage,
    displayFormat,
  })
}

export const getFormattedDaysAndHours = ({
  leave,
  formatMessage,
  displayFormat,
}: IGetFormattedDaysAndHours): string => {
  if (leave.days === 0 && leave.hours === 0 && leave.minutes === 0) {
    return displayFormat === 'long'
      ? `0 ${formatMessage({ id: 'dashboard.hours' })}`
      : `0${formatMessage({ id: 'dashboard.hourSingle' })}`
  }

  const daysLong = leave.days !== 0
    ? `${leave.days} ${formatMessage({ id: leave.days === 1 ? 'dashboard.day' : 'dashboard.daysPlural' })}`
    : ''
  const hoursLong = leave.hours !== 0
    ? `${leave.hours} ${formatMessage({ id: leave.hours === 1 ? 'dashboard.hourOne' : 'dashboard.hours' })}`
    : ''
  const minutesLong = leave.minutes !== 0
    ? `${leave.minutes} ${formatMessage({ id: leave.minutes === 1 ? 'dashboard.minute' : 'dashboard.minutes' })}`
    : ''
  const daysShort = leave.days !== 0
    ? `${leave.days}${formatMessage({ id: 'dashboard.daysSmallCaseSingle' })}`
    : ''
  const hoursShort = leave.hours !== 0
    ? `${leave.hours}${formatMessage({ id: 'dashboard.hourSingle' })}`
    : ''
  const minutesShort = leave.minutes !== 0
    ? ` ${leave.minutes}${formatMessage({ id: 'dashboard.minuteAbbreviation' })}`
    : ''
  const justString = displayFormat === 'long'
    ? `${daysLong} ${hoursLong} ${minutesLong}`
    : `${daysShort} ${hoursShort} ${minutesShort}`

  // This replace removing whitespace from both sides of a string
  return `${leave.isNegative ? '-' : ''}${justString.trim()}`
}

// this function is used to get the formatted value of the location working hours
// e.g. 8h for 8, or 8h 30m for 8.5
export const getFormattedWorkhours = (formatMessage: (d: IFormatMessage) => string, workingHours?: WorkingHours) => {
  const hoursPerDay = getUserWorkingHoursPerDay(workingHours)
  const hours = Math.floor(hoursPerDay)
  const minutes = (hoursPerDay - hours) * 60
  return getFormattedDaysAndHours({
    leave: {days: 0, hours, minutes, isNegative: false},
    formatMessage,
    displayFormat: LeaveDisplayFormatEnum.short,
  })
}
/**
 * Calculates the number of hours for a part-day.
 *
 * @param partDay - An object representing the part-day with start and end times.
 * @returns The number of hours between the start and end times. Returns 0 if partDay is undefined.
 */
export const calculatePartDayHours = (partDay: IPartDay | undefined): number => {
  if (!partDay) {
    return 0
  }
  const startHoursDecimal = partDay.startHour +
    (partDay.startMinute || 0) / 60
  const endHoursDecimal = partDay.endHour +
    (partDay.endMinute || 0) / 60

  return endHoursDecimal - startHoursDecimal
}

interface ITimeFormatOptions {
  hour: number
  minute: number
  showZeroPadding?: boolean
  hourFormat?: HourFormatEnum
  amOrPm?: string
}

/**
 * Formats a time string based on the provided hour and minute with flexible formatting options.
 *
 * @param hour - The hour to be formatted
 * @param minute - The minute to be formatted
 * @param showZeroPadding - Whether to show :00 for whole hours (default: false) - for calendar params
 * @param hourFormat - '12h' or '24h' format (default: '24h') - Optional - for UI
 * @param amOrPm - The AM/PM indicator (only used with 12h format) - Optional - for UI
 * @returns Formatted time string examples:
 * - 24h UI: "17", "17:15"
 * - 24h Calendar: "17:00", "17:15"
 * - 12h UI: "5PM", "5:15PM"
 */
export const formatTimeString = ({
  hour,
  minute,
  showZeroPadding = false,
  hourFormat,
  amOrPm,
}: ITimeFormatOptions): string => {
  const minuteStr = minute > 0
    ? `:${minute.toString().padStart(2, '0')}`
    : showZeroPadding ? ':00' : ''

  const displayHour = hourFormat === HourFormatEnum.twelve && hour > 12
    ? hour - 12
    : hour

  const paddedHour = showZeroPadding && displayHour < 10
    ? displayHour.toString().padStart(2, '0')
    : displayHour
  const amPmStr = hourFormat === HourFormatEnum.twelve ? (amOrPm || '') : ''

  return `${paddedHour}${minuteStr}${amPmStr}`
}
