import { ICoreEvent } from './core-event'

export enum ApproversLevelEnum {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
}

export interface ITeam {
  id: string
  companyId: string
  name: string
  timestamp: string
  isDefault?: boolean
  parentId?: string
  approversLevel?: 'single' | 'multi'
}
export interface ITeamShort {
  id: string
  name: string
  parentId?: string
}

export type ITeamMap = { [key: string]: ITeam }

export interface ITeamCreatedEvent extends ICoreEvent {
  eventType: 'TEAM_CREATED' | 'TEAM_UPDATED'
  eventGroup: 'TEAM'
  teamId: string
  name: string
  isDefault?: boolean
  users: string[]
  approvers: string[]
  parentId?: string
}

export interface ITeamCreatedEventV2 extends ICoreEvent {
  eventType: 'TEAM_CREATED' | 'TEAM_UPDATED'
  eventGroup: 'TEAM'
  teamId: string
  name: string
  isDefault?: boolean
  users: string[]
  approvers: {
    userId: string
    approverLevels: ApproversLevelEnum[]
  }[]
  parentId?: string
}

export interface ITeamDeletedEvent extends ICoreEvent {
  eventType: 'TEAM_DELETED'
  eventGroup: 'TEAM'
  teamId: string
}


export interface ILocationTeamMoved extends ICoreEvent {
  teamId: string
  locationId: string
}

export interface ITeamOpenApi {
  id: string
  companyId: string
  name: string
  timestamp: string
  createdAt?: boolean
}