import { notification } from 'antd'
import * as Sentry from '@sentry/react'
import { IExportLeaveBalanceCustomData, IExportLeaveBalanceData, IExportLeaveBalanceMonthlyData, IExportLeaveHistoryData, ReportType } from '@vacationtracker/shared/types/export'
import { getLeaveBalanceMonthlyReport, getLeaveBalanceReport, getLeaveRequestReport } from '../../graphql/custom-queries'
import { IReportQuery } from './types'
import { useCallback, useRef, useState} from 'react'
import { useIntl } from 'react-intl'
import { useManualQuery } from 'graphql-hooks'
import { IGetLeaveBalanceMonthlyReportData, IGetLeaveBalanceReportData, IGetLeaveRequestReportData } from '../../types/custom-queries'

const useLeaveReportData = () => {
  const [exportData, setExportData] = useState<IExportLeaveHistoryData[] | IExportLeaveBalanceData[] | IExportLeaveBalanceCustomData[] | IExportLeaveBalanceMonthlyData[]>([])
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [getLeaveRequestReportQuery] = useManualQuery<IGetLeaveRequestReportData, IReportQuery>(getLeaveRequestReport)
  const [getLeaveBalanceReportQuery] = useManualQuery<IGetLeaveBalanceReportData, IReportQuery>(getLeaveBalanceReport)
  const [getLeaveBalanceMonthlyReportQuery] = useManualQuery<IGetLeaveBalanceMonthlyReportData, IReportQuery>(getLeaveBalanceMonthlyReport)
  const cachedData = useRef<{ [page: number]: IExportLeaveHistoryData[] | IExportLeaveBalanceData[] | IExportLeaveBalanceCustomData[] | IExportLeaveBalanceMonthlyData[] }>({})
  const { formatMessage } = useIntl()

  const getResults = useCallback(async (reportQuery: IReportQuery, reportType: ReportType) => {
    const { page } = reportQuery
    if (cachedData.current[page as number]) {
      setExportData(cachedData.current[page as number])
      setLoading(false)
      return
    }
    
    try {
      setLoading(true)
      let results: IGetLeaveRequestReportData | IGetLeaveBalanceReportData | IGetLeaveBalanceMonthlyReportData
      let total = 0
      let response
      let data: IExportLeaveHistoryData[] | IExportLeaveBalanceData[] | IExportLeaveBalanceCustomData[] | IExportLeaveBalanceMonthlyData[]
      switch (reportType) {
        case 'leave-history-report':
          response = (await getLeaveRequestReportQuery({ variables: { ...reportQuery } }))
          results = response.data as IGetLeaveRequestReportData
          data = results.getLeaveRequestReport.data
          total = results.getLeaveRequestReport.total
          break
        case 'leave-balance-report':
          response = (await getLeaveBalanceReportQuery({ variables: { ...reportQuery } }))
          results = response.data as IGetLeaveBalanceReportData
          data = results.getLeaveBalanceReport.data
          total = results.getLeaveBalanceReport.total
          break
        case 'monthly-leave-balance-report':
          response = (await getLeaveBalanceMonthlyReportQuery({ variables: { ...reportQuery } }))
          results = response.data as IGetLeaveBalanceMonthlyReportData
          data = results.getLeaveBalanceMonthlyReport.data
          total = results.getLeaveBalanceMonthlyReport.total
          break
        default:
          throw new Error('Unsupported report type')
      }
      cachedData.current[page as number] = data
      setExportData(data)
      setTotal(total)
    } catch (e) {
      Sentry.captureException(e)
      notification.error({
        message: formatMessage({ id: 'error.generic' }),
        description: formatMessage({ id: 'error.leaveRequestsDataError.title'}),
        duration: 0,
      })
    }
    setLoading(false)
  }, [])

  const clearCache = () => {
    cachedData.current = {}
  }

  return { exportData, total, loading, getResults, clearCache }
}

export default useLeaveReportData