export enum ImportType {
  leaves = 'leaves',
  details = 'details',
  quotas = 'quotas',
  toils = 'toils',
}

export enum ImportFormat {
  csv = 'csv',
  excel = 'Excel',
}