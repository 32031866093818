import dayjs from 'dayjs'
import { IGetImportPageValidationData, IUserSlim } from '../../../types/users'
import { IUserLeaves } from '@vacationtracker/shared/types/leave-request'
import { findSameDateLeaves } from '../helpers'
import { ShortestLeaveIntervalEnum } from '@vacationtracker/shared/types/leave-policy'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'
import { ILeavePolicy } from '../../../types/locations'
import { WorkingHours } from '@vacationtracker/shared/types/work-week'
import { DEFAULT_WORKING_HOURS_IN_DAY } from '@vacationtracker/shared/data/app-parameters'
import { IUserToilDays } from '@vacationtracker/shared/types/toil-request'

export const validateImportLeavesRow = (companyUsers:  IUserSlim[], validationData: IGetImportPageValidationData, existingLeaves: IUserLeaves[], formatMessage: Function) => {

  // Bellow is the hook that handles validation logic for each row of the provided  spreadsheet
  return (data, addError: Function) => {

    const validations = commonValidations(data, companyUsers, validationData, addError, formatMessage)
    
    const user = validations.validateUser()

    validations.validateApprover(user?.teamId as string)

    const leaveType = validations.validateLeaveType()

    const userLocation = validationData.getLocationList.find(location => location.id === user?.locationId)
    if (user) {
      const leavePolicy = validations.validateUserLeavePolicy(leaveType?.id as string, userLocation)
      if (leavePolicy && leavePolicy.isReasonRequired && !data.reason) {
        addError('reason', { message: formatMessage({ id: 'components.leaveForm.pleaseInputReason' }), level: 'error' })      
      }
      if (leavePolicy && leavePolicy.shortestLeaveInterval === ShortestLeaveIntervalEnum.fullDay && data.isHalfDay) {
        addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.leavePolicyError' }), level: 'error' })      
        addError('hourTo', { message: formatMessage({ id: 'app.importLeaves.errors.leavePolicyError' }), level: 'error' })      
      }
    }

    //TODO workday change this when we implement user hours
    const workingHours = getUserWorkingHoursPerDay(userLocation?.workHours)
    const sameDayLeaves = findSameDateLeaves(companyUsers, existingLeaves, data, workingHours)

    if (sameDayLeaves) {
      addError('dateFrom', sameDayLeaves)
    }

    const { dateFrom, dateTo} = validations.validateDates(workingHours)

    return {
      ...data,
      leaveType: leaveType?.name,
      hourFrom: (data.isHalfDay && data.hourFrom && !isNaN(Number(data.hourFrom))) ? Number(data.hourFrom) : undefined,
      hourTo: (data.isHalfDay && data.hourTo && !isNaN(Number(data.hourTo))) ? Number(data.hourTo) : undefined,
      dateFrom,
      dateTo,
    }
  }
}

export const validateImportUsersDataRow = (companyUsers:  IUserSlim[], validationData: IGetImportPageValidationData, formatMessage: Function) => {

  return (data, addError: Function) => {
    const validations = commonValidations(data, companyUsers, validationData, addError, formatMessage)
    validations.validateUser()

    const department = validationData.getTeamList.find(department => data.department && department.name.toLowerCase() === (data.department && data.department.toLowerCase()))
    if (data.department && !department) {
      addError('department', { message: formatMessage({ id: 'app.importLeaves.errors.departmentNotFound' }), level: 'error' })      
    }
    const location = validationData.getLocationList.find(location => data.location && location.name.toLowerCase() === (data.location && data.location.toLowerCase()))
    if (data.location && !location) {
      addError('location', { message: formatMessage({ id: 'app.importLeaves.errors.locationNotFound' }), level: 'error' })      
    }
    if (data.endDate) { 
      const startDate = dayjs(data.startDate as string).format('YYYY-MM-DD')
      const endDate = dayjs(data.endDate as string).format('YYYY-MM-DD')

      if (!validationData.getCompany.userEndDate) {
        addError('endDate', { message: formatMessage({ id: 'app.importLeaves.errors.endDateNotEnabled' }), level: 'error'})
      }
      if (!data.startDate) {
        addError('startDate', { message: formatMessage({ id: 'app.importLeaves.errors.startDateRequired' }), level: 'error'})
      }
      if (dayjs(startDate).isAfter(endDate)) {
        addError('endDate', { message: formatMessage({ id: 'app.importLeaves.errors.dateFromAfterDateTo' }), level: 'error'})
      }
    }
    const nothingNew = !data.startDate && !data.endDate && !data.employeeId && !data.department && !data.location

    if (nothingNew) {
      addError('email', { message: formatMessage({ id: 'app.importLeaves.errors.atLeastOne' }), level: 'error' } )
    }
    return data
  }
}

export const validateImportBroughtForwardRow = (companyUsers:  IUserSlim[], validationData: IGetImportPageValidationData, formatMessage: Function) => {

  return (data, addError: Function) => {
    const validations = commonValidations(data, companyUsers, validationData, addError, formatMessage)
    const user = validations.validateUser()
    const leaveType = validations.validateLeaveType()
    if (leaveType) {
      const userLocation = validationData.getLocationList.find(location => location.id === user?.locationId)
      validations.validateUserLeavePolicy(leaveType.id, userLocation)
    }
    
    return data
  }
}

export const validateImportToilsRow = (companyUsers:  IUserSlim[], validationData: IGetImportPageValidationData, existingToils: IUserToilDays[], formatMessage: Function) => {

  // Bellow is the hook that handles validation logic for each row of the provided  spreadsheet
  return (data, addError: Function) => {

    const validations = commonValidations(data, companyUsers, validationData, addError, formatMessage)
    
    const user = validations.validateUser()

    validations.validateApprover(user?.teamId as string)

    const leaveType = validations.validateLeaveType()

    const userLocation = validationData.getLocationList.find(location => location.id === user?.locationId)
    if (user) {
      const leavePolicy = validations.validateUserLeavePolicy(leaveType?.id as string, userLocation)
      if (leavePolicy && !leavePolicy.toil) {
        addError('leaveType', { message: formatMessage({ id: 'app.importLeaves.errors.leavePolicyToilError' }), level: 'error' })      
      }
      if (leavePolicy && leavePolicy.toilShortestInterval === ShortestLeaveIntervalEnum.fullDay && data.isHalfDay) {
        addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.leavePolicyError' }), level: 'error' })      
        addError('hourTo', { message: formatMessage({ id: 'app.importLeaves.errors.leavePolicyError' }), level: 'error' })      
      }
    }

    //TODO workday change this when we implement user hours
    const workingHours = getUserWorkingHoursPerDay(userLocation?.workHours)

    const { dateFrom, dateTo} = validations.validateDates(workingHours)

    if (dayjs(dateFrom).isAfter(dayjs())) {
      addError('dateFrom', { message: formatMessage({ id: 'app.importLeaves.errors.dateFromInFuture' }), level: 'error'})
    }
    if (dayjs(dateTo).isAfter(dayjs())) {
      addError('dateTo', { message: formatMessage({ id: 'app.importLeaves.errors.dateFromInFuture' }), level: 'error'})
    }
    if (workingHours !== DEFAULT_WORKING_HOURS_IN_DAY && !dayjs(dateFrom).isSame(dayjs(dateTo), 'day')) {
      addError('dateFrom', { message: formatMessage({ id: 'components.toil.submitErrorPolicyDuration' }), level: 'error'})
    }

    //check if already exists in the system in the same period
    const sameDayToils = leaveType && existingToils.filter(existingToil => 
      existingToil.userId === user?.id &&
      existingToil.leaveTypes?.find(toilLeaveType => toilLeaveType.id === leaveType.id)?.days.find((day: string) => day === dateFrom || day === dateTo)
    )
    if (sameDayToils && sameDayToils?.length > 0) {
      addError('dateFrom', { message: formatMessage({ id: 'app.importLeaves.errors.toilAlreadyExist' }), level: 'error'})
    }

    return {
      ...data,
      leaveType: leaveType?.name,
      hourFrom: (data.isHalfDay && data.hourFrom && !isNaN(Number(data.hourFrom))) ? Number(data.hourFrom) : undefined,
      hourTo: (data.isHalfDay && data.hourTo && !isNaN(Number(data.hourTo))) ? Number(data.hourTo) : undefined,
      dateFrom,
      dateTo,
    }
  }
}


const commonValidations = (data, companyUsers:  IUserSlim[], validationData: IGetImportPageValidationData, addError: Function, formatMessage: Function) => {
  
  const validateUser = (): IUserSlim | undefined => {
    const user = companyUsers.find(user => user.email.toLowerCase() === (data.email && data.email.toLowerCase()))
    if (!user) {
      addError('email', { message: formatMessage({ id: 'error.UserNotFound' }), level: 'error' })
    }
    return user
  }

  const validateApprover = (teamId: string): void => {
    const approver = companyUsers.find(user => user?.email?.toLowerCase() === data?.approverEmail?.toLowerCase())
    const approversForTeam = validationData.getTeamList?.find(team => team.id === teamId)?.approvers

    if (!approver || !approversForTeam?.find(approverForTeam => approverForTeam.id === approver.id)) {
      addError('approverEmail', { message: formatMessage({ id: 'error.UserNotFoundApprover' }), level: 'error' })
    }
  }

  const validateLeaveType = (): {
    id: string
    name: string
    isActive: boolean
  } | undefined => {
    const leaveType = validationData.getLeaveTypeList.find(lt => data.leaveType && lt.name.toLowerCase() === (data.leaveType && data.leaveType.toLowerCase()))
    if (!leaveType) {
      addError('leaveType', { message: formatMessage({ id: 'app.importLeaves.errors.leaveTypeNotFound' }), level: 'error' })      
    }
    return leaveType
  }

  const validateUserLeavePolicy = (leaveTypeId: string, userLocation?: {
    id: string
    name: string
    leavePolicies: ILeavePolicy[]
    workHours: WorkingHours
  }): ILeavePolicy|undefined  => {
    const leavePolicy = userLocation?.leavePolicies.find(policy => policy.id === `${userLocation?.id}#${leaveTypeId}`)

    if (!leavePolicy) {
      addError('leaveType', { message: formatMessage({ id: 'app.importLeaves.errors.leaveTypeNotInUserLocation' }), level: 'error' })      
    }
    return leavePolicy
  }

  const validateDates = (workingHours: number): {
    dateFrom: string | undefined
    dateTo: string | undefined
  } => {
    let dateFrom: string | undefined
    let dateTo: string | undefined
    if (!data.dateFrom || !data.dateTo) {
      if (!data.dateFrom) {
        addError('dateFrom', { message: formatMessage({ id: 'app.importLeaves.errors.dateFromRequired' }), level: 'error' })
      } else {
        dateFrom = dayjs(data.dateFrom).format('YYYY-MM-DD')
      }
      if (!data.dateTo) {
        addError('dateTo', { message: formatMessage({ id: 'app.importLeaves.errors.dateToRequired' }), level: 'error' })
      } else {
        dateTo = dayjs(data.dateTo).format('YYYY-MM-DD')
      }
    } else {
      dateFrom = dayjs(data.dateFrom).format('YYYY-MM-DD')
      dateTo = dayjs(data.dateTo).format('YYYY-MM-DD')

      if (!dayjs(dateFrom).isValid()) {
        addError('dateFrom', { message: formatMessage({ id: 'app.importLeaves.errors.dateFromNotValid' }), level: 'error'})
      }

      if (!dayjs(dateTo).isValid()) {
        addError('dateTo', { message: formatMessage({ id: 'app.importLeaves.errors.dateToNotValid' }), level: 'error'})
      }

      // validate date range
      if (dayjs(dateFrom).isAfter(dateTo)) {
        addError('dateFrom', { message: formatMessage({ id: 'app.importLeaves.errors.dateFromAfterDateTo' }), level: 'error'})
      }
    }

    if (data.isHalfDay) {
      //convert hours to number
      if (!data.hourFrom || isNaN(Number(data.hourFrom))) {
        addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.hourFromRequired' }), level: 'error'})
      } else if (/^(0?[0-9]|1[0-9]|2[0-3])$/.test(data.hourFrom) === false) {
        addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.hourFromNotValid' }), level: 'error'})
      }
      if (!data.hourTo || isNaN(Number(data.hourTo))) {
        addError('hourTo', { message: formatMessage({ id: 'app.importLeaves.errors.hourToRequired' }), level: 'error'})
      } else if (/^(0?[0-9]|1[0-9]|2[0-3])$/.test(data.hourTo) === false) {
        addError('hourTo', { message: formatMessage({ id: 'app.importLeaves.errors.hourToNotValid' }), level: 'error' })
      }
      // validate hours
      if (data.hourFrom && data.hourTo) {
        if (Number(data.hourFrom) > Number(data.hourTo)) {
          addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.hourFromAfterHourTo' }), level: 'error' })
        }
        if (Number(data.hourTo) - Number(data.hourFrom) > workingHours) {
          addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.hourRange' }, { hours: 2 }), level: 'error' })
        }
      }
      if (data.dateFrom !== data.dateTo) {
        addError('isHalfDay', { message: formatMessage({ id: 'app.importLeaves.errors.halfDay' }), level: 'error'})
      }
    }
    return { dateFrom, dateTo }
  }

  return { validateUser, validateApprover, validateLeaveType, validateDates, validateUserLeavePolicy }
}

