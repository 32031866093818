import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Popover, Button, DatePicker } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../../util/IntlMessages'
import { IPeriodFilterProps } from './types'
import dayjs, { Dayjs } from 'dayjs'

const { RangePicker } = DatePicker

const ReportPeriodFilter: React.FC<IPeriodFilterProps> = ({ defaultDates, dateFormat, onFilterChange }) => {
  const [selectedDates, setSelectedDates] = useState<[Dayjs, Dayjs] | null>(null)
  const [visible, setVisible] = useState(false)
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (!selectedDates) {
      setSelectedDates(defaultDates || [
        dayjs().startOf('year'),
        dayjs().endOf('year'),
      ])
    }
  }, [])

  const handleChange = (dates: [Dayjs | null, Dayjs | null]) => {
    if (dates && dates[0] && dates[1]) {
      setSelectedDates([dates[0], dates[1]] as [Dayjs, Dayjs])
    }
  }

  const handleApply = () => {
    if (selectedDates) {
      onFilterChange(selectedDates)
    }
    setVisible(false)
  }

  const handleReset = () => {
    const resetDates = defaultDates || [dayjs().startOf('year'), dayjs().endOf('year')]
    setSelectedDates(resetDates)
    onFilterChange(resetDates)
    setVisible(false)
  }

  return (
    <div style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
      <Popover
        content={
          <div>
            <RangePicker
              value={selectedDates || null}
              format="YYYY-MM-DD"
              clearIcon={false}
              onChange={(dates) => handleChange(dates as [Dayjs | null, Dayjs | null])}
              style={{ width: '100%' }}
            />
            <div style={{ marginTop: '10px' }}>
              <Button type="primary" onClick={handleApply} style={{ marginRight: '8px' }}>
                <IntlMessages id="app.apply" />
              </Button>
              <Button onClick={handleReset} style={{ marginRight: '8px' }}>
                <IntlMessages id="app.reset" />
              </Button>
            </div>
          </div>
        }
        title={<IntlMessages id="app.select" />}
        trigger="click"
        placement="bottom"
        open={visible}
        onOpenChange={(open) => setVisible(open)}
      >
        <div className="popover-trigger-container">
          <MinusCircleOutlined />
          <label>
            {`${formatMessage({ id: 'automations.period' })}: ${selectedDates?.[0].format(dateFormat)} - ${selectedDates?.[1].format(dateFormat)}`}
          </label>
        </div>
      </Popover>
    </div>
  )
}

export default ReportPeriodFilter
