
export const importLeavesFields = [
  {
    // Visible in table header and when matching columns.
    label: 'Email',
    // This is the key used for this field when we call onSubmit.
    key: 'email',
    alternateMatches: ['employee email', 'user email'],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'example@email.com',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Email is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldEmail',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Leave Type',
    // This is the key used for this field when we call onSubmit.
    key: 'leaveType',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'Paid Time Off',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Leave Type is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldLeaveType',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Date From',
    // This is the key used for this field when we call onSubmit.
    key: 'dateFrom',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: '10/12/2021, 10/12/21, 10-12-2021, 10-12-21, 10.12.2021, 10.12.21',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Date From is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldStartDate',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Date To',
    // This is the key used for this field when we call onSubmit.
    key: 'dateTo',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: '10/12/2021, 10/12/21, 10-12-2021, 10-12-21, 10.12.2021, 10.12.21',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Date To is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldEndDate',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Is Half Day / Hourly',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['Is Half Day / Hourly', 'Is Half Day', 'Is Hourly'],

    key: 'isHalfDay',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'checkbox',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'yes/no',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Is Half Day field is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldIsHalfDay',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Hour From',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['hour from', 'Hour From', 'Hour From (only hour-no minutes)'],
    // This is the key used for this field when we call onSubmit.
    key: 'hourFrom',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: '10',
    // Can have multiple validations that are visible in Validation Step table.
    description: 'components.imports.descriptionFieldHourFrom',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Hour To',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['hour to', 'Hour To', 'Hour To (only hour-no minutes)'],
    // This is the key used for this field when we call onSubmit.
    key: 'hourTo',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: '12',
    // Can have multiple validations that are visible in Validation Step table.
    description: 'components.imports.descriptionFieldHourTo',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Reason',
    // This is the key used for this field when we call onSubmit.
    key: 'reason',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'Taking a break',
    // Can have multiple validations that are visible in Validation Step table.
    description: 'components.imports.descriptionFieldReason',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Approver Email',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['Approver Email Address'],
    // This is the key used for this field when we call onSubmit.
    key: 'approverEmail',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'john@example.com',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Approver Email is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldApproverEmail',
  },
] as const


export const importUsersDataFields = [
  {
    label: 'Email',
    key: 'email',
    alternateMatches: ['employee email', 'user email'],
    fieldType: { type: 'input' },
    example: 'example@email.com',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Email is required',
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldEmail',
  },
  {
    label: 'Department',
    key: 'department',
    fieldType: { type: 'input' },
    example: 'Product',
    description: 'components.imports.descriptionFieldDepartment',
  },
  {
    label: 'Location',
    key: 'location',
    fieldType: { type: 'input' },
    example: 'Home',
    description: 'components.imports.descriptionFieldLocation',
  },
  {
    label: 'Start Date',
    key: 'startDate',
    fieldType: { type: 'input' },
    example: '10/12/2025, 10/12/25, 10-12-2025, 10-12-25, 10.12.2025, 10.12.25',
    description: 'components.imports.descriptionFieldUserStartDate',
  },
  {
    label: 'End Date',
    key: 'endDate',
    fieldType: { type: 'input' },
    example: '10/12/2025, 10/12/25, 10-12-2025, 10-12-25, 10.12.2025, 10.12.25',
    description: 'components.imports.descriptionFieldUserEndDate',
  },
  {
    label: 'Employee ID',
    key: 'employeeId',
    alternateMatches: ['employee ID', 'employee Id', 'id'],
    fieldType: { type: 'input' },
    example: '1001',
    description: 'components.imports.descriptionFieldEmployeeId',
  },
] as const

export const importInitialBFFields = (hourlyLeaveAccounting: boolean) => {
  return [
    {
      label: 'Email',
      key: 'email',
      alternateMatches: ['employee email', 'user email'],
      fieldType: { type: 'input' },
      example: 'example@email.com',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Email is required',
          level: 'error',
        },
      ],
      description: 'components.imports.descriptionFieldEmail',
    },
    {
      label: 'Leave Type',
      key: 'leaveType',
      fieldType: { type: 'input' },
      example: 'Paid Time Off',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Leave Type is required',
          level: 'error',
        },
      ],
      description: 'components.imports.descriptionFieldLeaveType',
    },
    {
      label: hourlyLeaveAccounting ? 'Hours' : 'Days',
      key: 'amount',
      alternateMatches: ['days', 'hours'],
      fieldType: { type: 'input' },
      example: '40',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Amount is required',
          level: 'error',
        },
        {
          rule: 'regex',
          value: new RegExp(`^([1-9][0-9]{0,2}|${hourlyLeaveAccounting ? 365 * 8 : 365})$`),
          errorMessage: 'The value is invalid',
          level: 'error',
        },
      ],
      description: 'components.imports.descriptionFieldAmount',
    },
  ] as const
}

export const importToilsFields = [
  {
    label: 'Email',
    key: 'email',
    alternateMatches: ['employee email', 'user email'],
    fieldType: { type: 'input' },
    example: 'example@email.com',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Email is required',
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldEmail',
  },
  {
    label: 'Leave Type',
    key: 'leaveType',
    fieldType: { type: 'input' },
    example: 'Paid Time Off',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Leave Type is required',
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldLeaveType',
  },
  {
    label: 'Date From',
    key: 'dateFrom',
    fieldType: { type: 'input' },
    example: '10/12/2021, 10/12/21, 10-12-2021, 10-12-21, 10.12.2021, 10.12.21',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Date From is required',
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldStartDate',
  },
  {
    label: 'Date To',
    key: 'dateTo',
    fieldType: { type: 'input' },
    example: '10/12/2021, 10/12/21, 10-12-2021, 10-12-21, 10.12.2021, 10.12.21',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Date To is required',
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldEndDate',
  },
  {
    label: 'Is Half Day / Hourly',
    alternateMatches: ['Is Half Day / Hourly', 'Is Half Day', 'Is Hourly'],
    key: 'isHalfDay',
    fieldType: { type: 'checkbox' },
    example: 'yes/no',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Is Half Day field is required',
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldIsHalfDay',
  },
  {
    label: 'Hour From',
    alternateMatches: ['hour from', 'Hour From', 'Hour From (only hour-no minutes)'],
    key: 'hourFrom',
    fieldType: { type: 'input'},
    example: '10',
    description: 'components.imports.descriptionFieldHourFrom',
  },
  {
    label: 'Hour To',
    alternateMatches: ['hour to', 'Hour To', 'Hour To (only hour-no minutes)'],
    key: 'hourTo',
    fieldType: { type: 'input' },
    example: '12',
    description: 'components.imports.descriptionFieldHourTo',
  },
  {
    label: 'Reason',
    key: 'reason',
    fieldType: { type: 'input' },
    example: 'Overtime work',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Reason field is required',
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldReasonToil',
  },
  {
    label: 'Approver Email',
    alternateMatches: ['Approver Email Address'],
    key: 'approverEmail',
    fieldType: { type: 'input' },
    example: 'john@example.com',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Approver Email is required',
        level: 'error',
      },
    ],
    description: 'components.imports.descriptionFieldApproverEmail',
  },
] as const