import { ExportType } from './features/export'
import { IQuotaAmount } from './leave-policy'
import { LeaveRequestStatus } from './leave-request'
import { RequestType } from './request'

export interface IExport {
  exportId: string
  reportType: string
  locations: string[]
  teams: string[]
  labels: string[]
  period: string
  companyId: string
  creator: string
  leaveStatus?: LeaveRequestStatus[]
  startDate?: string
  endDate?: string
  exportFormat?: ExportType
}

export interface IExportLeaveHistoryData {
  id: string
  startDate: string
  endDate: string
  isFullDay: boolean
  startHour: number
  endHour: number
  startMinute: number
  endMinute: number
  user: string
  userId: string
  approver: string
  approverId: string
  email: string
  employeeId: string
  reason: string
  status: LeaveRequestStatus
  requestType: RequestType
  duration: IQuotaAmount
  leaveType: string
  leaveTypeId: string
  location: string
  department: string
  labels: string
}

export interface IExportLeaveBalanceData {
  id: string
  userId: string
  user: string
  email: string
  employeeId: string
  leaveType: string
  location: string
  department: string
  labels: string
  broughtForward: IQuotaAmount
  yearlyQuota: IQuotaAmount
  yearlyQuotaBase: IQuotaAmount
  yearlyQuotaSeniority: IQuotaAmount
  yearlyQuotaRoleBased: IQuotaAmount
  accruedAmount: IQuotaAmount
  expiredBroughtForward: IQuotaAmount
  usedAmount: IQuotaAmount
  toil: IQuotaAmount
  toilExpired: IQuotaAmount
  scheduledAmount: IQuotaAmount
  remainingAmount: IQuotaAmount
}

export interface IExportLeaveBalanceCustomData {
  id: string
  userId: string
  user: string
  email: string
  employeeId: string
  leaveType: string
  location: string
  department: string
  labels: string
  usedAmount: IQuotaAmount
}

export interface IExportLeaveBalanceMonthlyData {
  id: string
  userId: string
  user: string
  email: string
  employeeId: string
  leaveType: string
  location: string
  department: string
  labels: string
  amount: IMonthlyReports
}

export type IMonthlyReports = [
  IReportAmountByMonth,
  IReportAmountByMonth,
  IReportAmountByMonth,
  IReportAmountByMonth,
  IReportAmountByMonth,
  IReportAmountByMonth,
  IReportAmountByMonth,
  IReportAmountByMonth,
  IReportAmountByMonth,
  IReportAmountByMonth,
  IReportAmountByMonth,
  IReportAmountByMonth
]

export interface IReportAmountByMonth {
  month: number
  year: number
  raw: {
    days: number
    hours: number
  }
}

export interface IExportLeaveHistoryResponse {
  total: number
  data: IExportLeaveHistoryData[]
}

export interface IExportLeaveBalanceResponse {
  total: number
  data: IExportLeaveBalanceData[] | IExportLeaveBalanceCustomData[]
}

export interface IExportLeaveBalanceMonthlyResponse {
  total: number
  data: IExportLeaveBalanceMonthlyData[]
}

export const leaveRequestReportColumns = [
  'user',
  'leaveType',
  'requestType',
  'startDate',
  'endDate',
  'isFullDay',
  'startHour',
  'startMinute',
  'endHour',
  'endMinute',
  'email',
  'employeeId',
  'approver',
  'reason',
  'status',
  'duration',
  'location',
  'department',
  'labels',
]

export const leaveBalanceReportColumns = [
  'user',
  'email',
  'employeeId',
  'leaveType',
  'location',
  'department',
  'labels',
  'broughtForward',
  'yearlyQuota',
  'yearlyQuotaBase',
  'yearlyQuotaSeniority',
  'yearlyQuotaRoleBased',
  'accruedAmount',
  'expiredBroughtForward',
  'usedAmount',
  'scheduledAmount',
  'toil',
  'toilExpired',
  'remainingAmount',
]

export const leaveBalanceMonthylReportColumns = [
  'user',
  'email',
  'employeeId',
  'leaveType',
  'location',
  'department',
  'labels',
]

export type ReportType = 'leave-history-report' | 'leave-balance-report' | 'monthly-leave-balance-report'
export type ReportPeriod = 'CURRENT_YEAR' | 'LAST_MONTH' | 'PREVIOUS_YEAR' | 'NEXT_YEAR' | 'CUSTOM_DAYS'
export type DateFormat = 'YYYY-MM-DD' | 'MM/DD/YYYY'
export type Display = 'days' | 'hours'
