import React, { useState } from 'react'
import { Form, Button, Tooltip, Alert, Card } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { ImportFormProps } from './types'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import SpreadsheetImportLeaves from '../spreadsheet-import/import-leaves'
import TemplateModal from '../spreadsheet-import/template-modal'
import SpreadsheetImportUsersData from '../spreadsheet-import/import-users-data'
import { ImportType } from '../../routes/ImportPage/types'
import SpreadsheetImportBroughtForward from '../spreadsheet-import/import-brought-forward'
import SpreadsheetImportToil from '../spreadsheet-import/import-toil'
import { importInitialBFFields, importLeavesFields, importToilsFields, importUsersDataFields } from '../spreadsheet-import/fields'

const layout = {
  labelCol: {span: 3},
  wrapperCol: {span: 12},
}

const tailLayout = {
  wrapperCol: {xs: { offset: 0, span: 12 }, lg: {offset: 3, span: 12}},
}

const ImportForm: React.FC<ImportFormProps> = ({
  defaultValue,
  form,
  importType,
  setSpreadSheetOpen,
  spreadSheetOpen,
  onHideModal,
  companyUsers,
  validationData,
  onUpload,
  loadLeaveRequests,
  loadToilRequests,
  templateFolderUrl,
  hourlyLeaveAccounting,
}: ImportFormProps) => {

  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false)

  const renderImportSheet = (): React.ReactElement => {
    if (importType === ImportType.leaves) {
      return <SpreadsheetImportLeaves
        isOpen={spreadSheetOpen}
        onHideModal={onHideModal}
        companyUsers={companyUsers}
        validationData={validationData}
        onUpload={onUpload}
        loadLeaves={loadLeaveRequests}
      />
    }
    if (importType === ImportType.details) {
      return <SpreadsheetImportUsersData
        isOpen={spreadSheetOpen}
        onHideModal={onHideModal}
        companyUsers={companyUsers}
        validationData={validationData}
        onUpload={onUpload}
      />
    }
    if (importType === ImportType.quotas) {
      return <SpreadsheetImportBroughtForward
        isOpen={spreadSheetOpen}
        onHideModal={onHideModal}
        companyUsers={companyUsers}
        validationData={validationData}
        onUpload={onUpload}
        hourlyLeaveAccounting={hourlyLeaveAccounting}
      />
    }
    if (importType === ImportType.toils) {
      return <SpreadsheetImportToil
        isOpen={spreadSheetOpen}
        onHideModal={onHideModal}
        companyUsers={companyUsers}
        validationData={validationData}
        onUpload={onUpload}
        loadToils={loadToilRequests}
      />
    }
    return <></>
  }

  const renderFieldsDescriptions = (): React.ReactElement => {
    let fields: any
    let description: string | undefined
    switch (importType) {
      case ImportType.leaves: fields = importLeavesFields; break
      case ImportType.details: fields = importUsersDataFields; break
      case ImportType.quotas: fields = importInitialBFFields(hourlyLeaveAccounting); description = 'components.imports.bfDescription'; break
      case ImportType.toils: fields = importToilsFields; break
    }
    return (
      <Card>
        {description && <div style={{ fontWeight: 'bold', marginBottom: '20px', textAlign: 'justify' }}><IntlMessages id={description} /></div>}
        <div><IntlMessages id="components.imports.fieldsDescriptions" /></div>
        <br />
        {fields.map((field, index) => (
          <div key={index}>
            <b>{field.label}</b>: <IntlMessages id={field.description} />
          </div>
        ))}
      </Card>
    )
  }

  return (
    <div>
      <Form form={form} initialValues={defaultValue} {...layout}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div id="title" style={{ flex: '1', paddingRight: '20px' }}>
            <IntlMessages id='components.imports.description' />
          </div>
          <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Form.Item {...tailLayout}>
              <Button type='primary' onClick={() => setSpreadSheetOpen(true)} style={{ width: '200px', height: '64px', fontSize: '16px' }}>
                <IntlMessages id='app.importFile' />
              </Button>
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Tooltip title={<IntlMessages id='app.importLeaves.downloadTemplateFileTooltip' values={{ br: () => <br/> }} />}>
                <Button onClick={() => setOpenTemplateModal(true)} icon={<DownloadOutlined />}>
                  <span><IntlMessages id='app.downloadTemplate' /></span>
                </Button>
              </Tooltip>
            </Form.Item>
          </div>
        </div>
      </Form>
      {[ImportType.leaves, ImportType.toils].includes(importType) && <Alert
        style={{ marginBottom: 20 }}
        showIcon
        description={<IntlMessages id="app.importLeaves.leaveStatusWarning" />}
        type="warning"
      />}
      {importType === ImportType.details && <Alert
        style={{ marginBottom: 20 }}
        showIcon
        description={<IntlMessages id="app.importLeaves.importUsersDataWarning" />}
        type="warning"
      />}
      {renderFieldsDescriptions()}
      {renderImportSheet()}
      <TemplateModal isOpen={openTemplateModal} importType={importType} templateFolderUrl={templateFolderUrl} onClose={() => setOpenTemplateModal(false)}  />
    </div>
  )
}

export default ImportForm
