import React, { useState } from 'react'
import { Select } from 'antd'
import IntlMessages from '../../util/IntlMessages'
import { ReportPeriod } from '@vacationtracker/shared/types/export'
import { IBalancePeriodProps } from './types'

const { Option } = Select

const BalancePeriod: React.FC<IBalancePeriodProps> = ({ onChange }) => {

  const [balancePeriod, setBalancePeriod] = useState<ReportPeriod>('CURRENT_YEAR')

  const handleBalancePeriodOpenChange = (period: ReportPeriod) => {
    setBalancePeriod(period)
    onChange(period)
  }

  return (
    <div style={{ marginBottom: '10px' }}>
      <IntlMessages id="app.show" />:&nbsp;
      <Select onChange={handleBalancePeriodOpenChange} defaultValue={balancePeriod}>
        <Option value="CURRENT_YEAR"><IntlMessages id="export.currentYear" /></Option>
        <Option value="LAST_MONTH"><IntlMessages id="export.lastMonth" /></Option>
        <Option value="PREVIOUS_YEAR"><IntlMessages id="export.previousYear" /></Option>
        <Option value="NEXT_YEAR"><IntlMessages id="export.nextYear" /></Option>
        <Option value="CUSTOM_DAYS"><IntlMessages id="app.customDays" /></Option>
      </Select>
    </div>
  )
}

export default BalancePeriod
