import { IPartDay } from '@vacationtracker/shared/types/leave-request'

interface IPartDayRequest {
  isPartDay: boolean
  partDay?: IPartDay
  partDayStartHour?: number
  partDayEndHour?: number
}

export const getPartDayData = (request: IPartDayRequest): IPartDay | undefined => {
  if (!request.isPartDay) {
    return undefined
  }

  if (request.partDay) {
    return request.partDay
  }

  if (request.partDayStartHour && request.partDayEndHour) {
    return {
      startHour: request.partDayStartHour,
      endHour: request.partDayEndHour,
      startMinute: 0,
      endMinute: 0,
    }
  }
  return undefined
}