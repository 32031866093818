import React, { useState, useEffect } from 'react'
import { Tabs, Table, TabsProps } from 'antd'
import dayjs from 'dayjs'
import { useManualQuery } from 'graphql-hooks'
import { getUserHolidays } from '../../graphql/custom-queries'
import { useAppSelector } from '../../store/hooks'
import { selectUserIdSlice } from '../../store/user-id-slice'

import CircularProgress from '../../components/circular-progress'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'

import * as logger from '../../services/logger'
import { IHolidayPage, IHoliday } from '../../types/holidays'
import { IGetUserHolidaysData } from '../../types/custom-queries'
import IntlMessages from '../../util/IntlMessages'
import { selectLocaleSlice } from '../../store/locale-slice'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'


const HolidaysPage = () => {
  const { userId } = useAppSelector(selectUserIdSlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const [currentYear] = useState(Number(dayjs().format('YYYY')))
  const [nextYear] = useState(Number(dayjs().add(1, 'year').format('YYYY')))
  const [holidays, setHolidays] = useState<IHolidayPage[]>([])
  const [holidaysListCurrentYear, setHolidaysListCurrentYear] = useState<IHoliday[]>([])
  const [holidaysListNextYear, setHolidaysListNextYear] = useState<IHoliday[]>([])
  const [holidaysListPastYear, setHolidaysListPastYear] = useState<IHoliday[]>([])
  const [loadedData, setLoadedData] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  
  const [ getUserHolidaysQuery ] = useManualQuery<IGetUserHolidaysData, { id: string }>(getUserHolidays)

  useEffect(() => {
    if (!loadedData && !isLoading) {
      holidays.forEach(holidayData => {
        if (holidayData.year === currentYear) {
          setHolidaysListCurrentYear(holidayData.holidays.sort((a: IHoliday, b: IHoliday) => a.date < b.date ? -1 : 1))
        } else if (holidayData.year === nextYear) {
          setHolidaysListNextYear(holidayData.holidays.sort((a: IHoliday, b: IHoliday) => a.date < b.date ? -1 : 1))
        } else {
          setHolidaysListPastYear(holidayData.holidays.sort((a: IHoliday, b: IHoliday) => a.date < b.date ? -1 : 1))
        }
      })
      setLoadedData(true)
    }
  }, [holidays, loadedData, isLoading, currentYear, nextYear])

  const getInfo = async (id) => {
    setIsLoading(true)
    try {
      const response = await getUserHolidaysQuery({ variables: { id }})
      if (!response.data || response.error) throw response.error

      const newData: IHolidayPage[] = response.data.getUser.location.holidays
      response.data.getUser.location.holidays.forEach((holidayData, index) => {
        const holidaysArray: IHoliday[] = []
        holidayData.holidays.forEach((single => {
          if (single.multiDayId !== null) {
            if (holidaysArray.filter(holiday => single.multiDayId === holiday.multiDayId).length === 0) {
              const filtered = newData[index].holidays
                .filter(holiday => holiday.multiDayId === single.multiDayId)
                .sort((a: IHoliday, b: IHoliday) => dayjs(a.date).format() < dayjs(b.date).format() ? -1 : 1)
              holidaysArray.push({ ...single, endDate: filtered[filtered.length - 1].date })
            }
          } else {
            delete single.multiDayId
            holidaysArray.push(single)
          }
        }))
        newData[index].holidays = holidaysArray
      })

      setHolidays(newData)
      setIsLoading(false)
    } catch (err) { logger.error('error fetching location', err) }
  }

  useEffect(() => {
    if (isLoading) {
      getInfo(userId)
    }
  }, [isLoading, userId])

  const columns = [{
    title: <IntlMessages id="holidays.holidaysName"/>,
    dataIndex: 'name',
    key: 'name',
  }, {
    title: <IntlMessages id="components.leavesColumns.dates" />,
    dataIndex: 'date',
    key: 'date',
    // eslint-disable-next-line react/display-name
    render: (date: string, row: IHoliday) => {
      return (
        <>
          <FormattedDate locale={locale.locale || LocaleEnum.en} value={date} format='YYYY-MM-DD' />
          {' '}{row.isHalfDay && <IntlMessages id="components.leavesColumns.halfDayHoliday" />}{' '}
          {row?.multiDayId && row?.endDate && (
            <> - <FormattedDate locale={locale.locale || LocaleEnum.en} value={row?.endDate} format='YYYY-MM-DD' /></>
          )}
        </>
      )
    },
  }, {
    title: <IntlMessages id="dashboard.days" />,
    dataIndex: 'date',
    key: 'day',
    // eslint-disable-next-line react/display-name
    render: (date: string, row: IHoliday) => {
      return (<>
        <FormattedDate 
          value={date}
          format="dddd"
          locale={locale.locale || LocaleEnum.en}
        /> {row.multiDayId && <>- <FormattedDate
          value={row.endDate as string}
          format="dddd"
          locale={locale.locale || LocaleEnum.en}
        />
        </> }
      </>)
    },
  }]

  const holidaysTabs: TabsProps['items'] = [
    {
      key: '1',
      label: `Holidays for ${currentYear}`, // todo: @slobodan
      children: <Table
        dataSource={holidaysListCurrentYear}
        columns={columns}
        loading={isLoading}
        rowKey={record => record.date}
        pagination={false}
      />,
    },
    {
      key: '2',
      label: `Holidays for ${nextYear}`,
      children: <Table
        dataSource={holidaysListNextYear}
        columns={columns}
        loading={isLoading}
        rowKey={record => record.date}
        pagination={false}
      />,
    },
    {
      key: '3',
      label: 'Past holidays',
      children: <Table
        dataSource={holidaysListPastYear}
        columns={columns}
        loading={isLoading}
        rowKey={record => record.date}
        pagination={false}
      />,
    },
  ]

  return (
    <div className='main-content'>
      {loadedData ?
        <Tabs defaultActiveKey="1" items={holidaysTabs} /> :
        <CircularProgress />
      }
    </div>
  )
}

export default HolidaysPage
