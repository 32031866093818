import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, Checkbox, Select, Tooltip, InputNumber, Row, Col, Typography, Divider, Button } from 'antd'
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import isEqual from 'lodash/isEqual'

import { selectLocaleSlice } from '../../store/locale-slice'
import { useAppSelector } from '../../store/hooks'
import { numberOfDaysInMonth } from '../../util/number-of-days-in-month'
import { filterOptions } from '../../util/filter-options'
import timezone from '@vacationtracker/shared/data/timezone'

import IntlMessages from '../../util/IntlMessages'
import { WorkWeek } from '../../util/WorkWeek'

import { IGetLocationById, IGetUserListShort } from '../../types/locations'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { getUserWorkingHoursPerDay, HOURS_MAX, HOURS_MIN, HOURS_STEP } from '@vacationtracker/shared/functions/work-week'
import YesNoSwitch from '../yes-no-switch'
import { SubscriptionPlan } from '@vacationtracker/shared/types/company'
import { openChat } from '../../util/set-crisp-session-info'

const { Option } = Select
const { Title, Paragraph } = Typography

interface ILocationForm {
  location: IGetLocationById
  users: IGetUserListShort[]
  totalLocation: number
  workdayEnabled: boolean
  plan: SubscriptionPlan
  form: {
    getFieldValue: Function
    setFieldsValue: Function
  }
}

interface IDays {
  value: number
  label: string
}

const LocationForm = ({ location, users, totalLocation, form, workdayEnabled, plan }: ILocationForm): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { locale } = useAppSelector(selectLocaleSlice)
  const generateDaysForMonth = (numberOfDays: number) => {
    const days: IDays[] = []
    for (let i = 1; i <= numberOfDays; i++) {
      days.push({ value: i, label: `${i}` })
    }
    return days
  }
  const [fiscalYearSelectDays, setFiscalYearSelectDays] = useState(generateDaysForMonth(numberOfDaysInMonth(location.yearStartMonth)))
  const [isFiscalYear, setResetQuotesStatus] = useState(() => {
    if (location.resetQuotas === 'USER_YEAR') {
      return 'USER_YEAR'
    } else {
      return 'FISCAL_YEAR'
    }
  })

  const [showWarningInitialRolloverUsers, setShowWarningInitialRolloverUsers] = useState(false)
  const [showWarningInitialRolloverResetQuotes, setShowWarningInitialRolloverResetQuotes] = useState(false)
  const [showWarningInitialRolloverFiscalYear, setShowWarningInitialRolloverFiscalYear] = useState(false)

  const months = [
    { label: 'app.january', value: 1 },
    { label: 'app.february', value: 2 },
    { label: 'app.march', value: 3 },
    { label: 'app.april', value: 4 },
    { label: 'app.may', value: 5 },
    { label: 'app.june', value: 6 },
    { label: 'app.july', value: 7 },
    { label: 'app.august', value: 8 },
    { label: 'app.september', value: 9 },
    { label: 'app.october', value: 10 },
    { label: 'app.november', value: 11 },
    { label: 'app.december', value: 12 },
  ]

  const onChangeMonthFiscalYear = (month: number) => {
    const numberOfDays = numberOfDaysInMonth(month)
    const days = generateDaysForMonth(numberOfDays)

    const yearStartDay = form.getFieldValue('yearStartDay')
    if (yearStartDay > numberOfDays) {
      form.setFieldsValue({yearStartDay: 1})
    }
    setFiscalYearSelectDays(days)

    if (location.id) {
      setShowWarningInitialRolloverFiscalYear(month !== location.yearStartMonth || yearStartDay !== location.yearStartDay  ? true : false)
    }
  }

  const onChangeDayFiscalYear = (day: number) => {
    if (location.id) {
      const yearStartMonth = form.getFieldValue('yearStartMonth')
      setShowWarningInitialRolloverFiscalYear(day !== location.yearStartDay || yearStartMonth !== location.yearStartMonth  ? true : false)
    }
  }

  const onChangeResetQuotes = (status) => {
    setShowWarningInitialRolloverResetQuotes(status !== location.resetQuotas && location.id ? true : false)
    setResetQuotesStatus(status)
  }

  const onChangeWorkday= (value) => {
    const roundedValue = Math.round(value * 2) / 2 // round to the nearest 0.5(if user puts 7.4 for example)
    form.setFieldsValue({ workHours: roundedValue })
  }

  const onChangeUsers = (users) => {
    if (Array.isArray(location.users) && location.users.length > 0) {
      let diferentUsers
      if (typeof location.users[0] === 'string') {
        diferentUsers = isEqual(location.users.sort() as string[], users.sort())
      } else {
        const userList = location.users.map(user => user.id)
        diferentUsers = isEqual(userList.sort(), users.sort())
      }
      setShowWarningInitialRolloverUsers(!diferentUsers)
    } else {
      if (users.length > 0) {
        setShowWarningInitialRolloverUsers(true)
      } else {
        setShowWarningInitialRolloverUsers(false)
      }
    }
  }

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}><IntlMessages id="components.locationForm.settings.nameTitle" /></Title>
          <Paragraph type="secondary"><IntlMessages id="components.locationForm.settings.nameDescription" /></Paragraph>
          <Paragraph type="secondary"><IntlMessages id="components.locationForm.settings.nameDescription2" /></Paragraph>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            name="name"
            className='wrap-label'
            label={<IntlMessages id="app.name" />}
            rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
          >
            <Input maxLength={100} placeholder={`${formatMessage({ id: 'components.locationForm.settings.namePlaceholder' })}`} />
          </Form.Item>
          <Form.Item
            name="users"
            className='wrap-label'
            label={<IntlMessages id="app.users" />}
            extra={showWarningInitialRolloverUsers ?
              <span><WarningOutlined /> <IntlMessages id="components.locationForm.warningInitialRolloverUsers" /></span> : ''
            }
          >
            <Select
              mode="multiple"
              disabled={Boolean(location.id && totalLocation === 1)}
              filterOption={filterOptions}
              onChange={onChangeUsers}
            >
              {users.map(user => <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            className='wrap-label'
            name="timezone"
            label={<IntlMessages id="app.timezone" />}
            rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
          >
            <Select
              showSearch
              filterOption={filterOptions}
            >
              {timezone.map(tz => <Option key={tz.id} value={tz.id}>{tz.text}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}><IntlMessages id="components.locationForm.settings.makeDefaultTitle" /></Title>
          <Paragraph type="secondary"><IntlMessages id="components.locationForm.settings.makeDefaultDescription" /></Paragraph>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <YesNoSwitch
            name="isDefault"
            label={<IntlMessages id="components.locationForm.defaultLocation" />}
            disabled={Boolean(location.id && totalLocation === 1) || Boolean(location.id && location.isDefault)}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}><IntlMessages id="components.locationForm.settings.workWeekTitle" /></Title>
          <Paragraph type="secondary"><IntlMessages id="components.locationForm.settings.workWeekDescription" /></Paragraph>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            name="workWeek"
            className='wrap-label'
            label={<IntlMessages id="app.workweek" />}
            rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
          >
            <Checkbox.Group options={WorkWeek} />
          </Form.Item>
          <Form.Item
            className='wrap-label'
            name="firstDayOfWeek"
            required={true}
            label={<IntlMessages id="notifications.weekStartsOn"/>}
          >
            <Select style={{ width: 130 }}>
              <Select.Option key="0" value={0}><IntlMessages id="app.sunday" /></Select.Option>)
              <Select.Option key="1" value={1}><IntlMessages id="app.monday" /></Select.Option>)
            </Select>
          </Form.Item>
          <Form.Item
            name="workHours"
            className='wrap-label'
            label={<>
              <IntlMessages id="app.workday" />
              <Tooltip className="info-tooltip" title={<IntlMessages id="app.workdayWarning" />} ><InfoCircleOutlined /></Tooltip>
            </>}
          >
            <InputNumber
              step={HOURS_STEP}
              min={HOURS_MIN}
              max={HOURS_MAX}
              style={{ width: 70 }}
              // TODO workday - when we add UI for custom work hours per day per location, change this:
              defaultValue={getUserWorkingHoursPerDay(location.workHours)}
              onChange={onChangeWorkday}
              disabled={!workdayEnabled}
            />&nbsp;<IntlMessages id="app.hours" />
            {!workdayEnabled && plan === 'Complete' && <Button style={{ marginLeft: '12px' }} onClick={() => openChat()}>
              <IntlMessages id="app.contactSupport" />
            </Button>}
          </Form.Item>
          {location.id &&
            <>
              <YesNoSwitch
                required={false}
                name="cancelUserSettings"
                defaultValue={false}
                label={<IntlMessages id="components.locationForm.overrideUserSettings" />}
              />
              <div><IntlMessages id="components.locationForm.overrideUserSettingsInfo" /></div>
            </>
          }
        </Col>
      </Row>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}><IntlMessages id="components.locationForm.settings.quotasTitle" /></Title>
          <Paragraph type="secondary"><IntlMessages id="components.locationForm.settings.quotasDescription" /></Paragraph>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          {isFiscalYear === 'FISCAL_YEAR' &&
            <>
              <Form.Item
                className='wrap-label'
                label={<IntlMessages id="location.fiscalYearStart" />}
                required={true}
                style={{ marginBottom: 0 }}
                extra={showWarningInitialRolloverFiscalYear ? <span><WarningOutlined /> <IntlMessages id="components.locationForm.warningInitialRollover" /></span> : ''}
              >
                <Form.Item name="yearStartMonth" style={{ display: 'inline-block', width: 140 }}>
                  <Select<number> style={{ width: 130, marginRight: 10 }} onChange={(month) => onChangeMonthFiscalYear(month)}>
                    {months.map(month => <Select.Option key={month.value} value={month.value}><IntlMessages id={month.label} /></Select.Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="yearStartDay" className='vr-md-ml-10' style={{ display: 'inline-block', width: 80}}>
                  <Select<number> style={{ width: 80 }} onChange={(day) => onChangeDayFiscalYear(day)}>
                    {fiscalYearSelectDays.map(day => <Select.Option key={day.value} value={day.value}>{day.label}</Select.Option>)}
                  </Select>
                </Form.Item>
              </Form.Item>
            </>
          }
          <Form.Item
            name="resetQuotas"
            className='wrap-label'
            label={(<IntlMessages id="locations.resetQuotas" />)}
            required={true}
            extra={showWarningInitialRolloverResetQuotes ?
              <span style={{ color: 'red', whiteSpace: 'break-spaces'}}><WarningOutlined /> <IntlMessages id="components.locationForm.warningInitialRollover" /></span> : ''
            }
          >
            <Select onChange={onChangeResetQuotes} style={{ width: locale.locale === LocaleEnum.en ? 200 : 300 }}>
              <Select.Option key="fiscalYear" value="FISCAL_YEAR"><IntlMessages id="components.locationForm.fiscalYear" /></Select.Option>)
              <Select.Option key="userYear" value="USER_YEAR"><IntlMessages id="users.employmentDate" /></Select.Option>)
            </Select>
          </Form.Item>
        </Col>
      </Row>
      
    </>
  )
}

export default LocationForm
