import React from 'react'
import { LeaveDisplayFormatEnum } from '../../types/display-in-days-hours'
import { IQuotaBoxProps } from './types'
import { BroughtForwardTooltip, ExpirationTooltip, QuotaTooltip, RemainingDaysTooltip, UsedDaysTooltip } from '../user-leave-quotas/quota-tooltips'
import { getFormattedDaysAndHours } from '../../functions/get-days-or-hours'
import { IQuotaAmount } from '../../types/leave-policy'
import { useIntl } from 'react-intl'

type QuotaContext = 'broughtForward' | 'quota' | 'used' | 'remaining' | 'expired'

const QuotaBox = ({
  title,
  dataTestId,
  value,
  valueUnits,
  footer,
  additionalQuotaDetails,
  doNotShowDisplayInDaysOrHoursTooltip = false,
  hasRequestedDays = false,
  locationName,
  leaveTypeName,
  broughtForwardLimit,
  expirationDate,
  locale,
  scheduledDays,
  workingHours,
}: IQuotaBoxProps): React.ReactElement => {
  const context = (title as JSX.Element).props?.id?.split('.')?.pop() as QuotaContext
  let tooltip
  switch (context) {
    case 'broughtForward':
      tooltip = <BroughtForwardTooltip
        value={valueUnits === 'hours' ? value.raw?.hours : value.raw?.days}
        locationName={locationName}
        leaveTypeName={leaveTypeName}
        broughtForwardLimit={broughtForwardLimit}
        units={valueUnits}
      />
      break
    case 'quota':
      tooltip = <QuotaTooltip
        value={valueUnits === 'hours' ? value.raw?.hours : value.raw?.days}
        quota={additionalQuotaDetails}
        hourlyLeaveAccounting={valueUnits === 'hours'}
        workingHours={workingHours}
        leaveTypeName={leaveTypeName}
      />
      break
    case 'used':
      tooltip = <UsedDaysTooltip 
        used={value}
        scheduled={scheduledDays}
        units={valueUnits}
        leaveTypeName={leaveTypeName}
      />
      break
    case 'remaining':
      tooltip = <RemainingDaysTooltip
        value={valueUnits === 'hours' ? value.raw?.hours : value.raw?.days}
        units={valueUnits}
        leaveTypeName={leaveTypeName}
        broughtForwardLimit={broughtForwardLimit}
        unlimited={additionalQuotaDetails?.hasUnlimitedDays}
      />
      break
    case 'expired':
      tooltip = <ExpirationTooltip expirationDate={expirationDate} locale={locale}/>
      break
    default:
      tooltip = <></>
      break
  }

  return (
    <div className={`quota quota-box ${hasRequestedDays ? 'requested' : ''} ${context}`}>
      <div className="quota-box-title" data-testid={`${dataTestId}-title`}>
        {doNotShowDisplayInDaysOrHoursTooltip ? title : <>{title}{tooltip}</>}
      </div>
      <div className="quota-box-value" data-testid={`${dataTestId}-value`}>{formatValue(value, Boolean(additionalQuotaDetails?.hasUnlimitedDays))}</div>
      <div className="quota-box-footer" data-testid={`${dataTestId}-footer`}>{footer}</div>
    </div>
  )
}

function formatValue(value: IQuotaAmount, isUnlimited: boolean): React.ReactElement {
  if (isUnlimited) {
    return <span>∞</span>
  }
  if (value.days === 0 && value.hours === 0 && value.minutes === 0) {
    return <span>0</span>
  }
  const { formatMessage } = useIntl()
  return <>{getFormattedDaysAndHours({ leave: value, formatMessage, displayFormat: LeaveDisplayFormatEnum.short})}</>
}

export default QuotaBox
