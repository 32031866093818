import React, { useEffect, useState } from 'react'
import { isEmpty, last } from 'lodash'
import { getExportFilters } from '../../../graphql/custom-queries'
import { IBalanceReportQuery, IExportFiltersResponse, reportResultsLimit } from '../../../components/reports/types'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { Display, IExportLeaveBalanceData, ReportPeriod, ReportType } from '@vacationtracker/shared/types/export'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import ExportModal from '../../../components/reports/export-modal'
import useLeaveReportData from '../../../components/reports/useGetExportData'
import LeaveBalanceReport from '../../../components/reports/leave-balance-report'
import { useManualQuery } from 'graphql-hooks'
import dayjs, { Dayjs } from 'dayjs'

const LeaveBalanceReportPage = () => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  
  const reportType: ReportType = 'leave-balance-report'

  const [companyData, setCompanyData] = useState<IExportFiltersResponse>()
  const [display, setDisplay] = useState<Display>(authCompany?.hourlyLeaveAccounting ? 'hours' : 'days')
  const [openDownloadModal, setOpenDownloadModal] = useState(false)
  const [getExportFiltersQuery] = useManualQuery<IExportFiltersResponse>(getExportFilters)
  const defaultDates: [Dayjs, Dayjs] = [dayjs().startOf('year'), dayjs().endOf('year')]

  const defaultQuery: IBalanceReportQuery = {
    page: 1,
    limit: reportResultsLimit,
    period: 'CURRENT_YEAR',
    startDate: defaultDates[0].format('YYYY-MM-DD'),
    endDate: defaultDates[1].format('YYYY-MM-DD'),
    userStatuses: ['ACTIVE'],
  }

  const [reportQuery, setReportQuery] = useState<IBalanceReportQuery>(defaultQuery)
  const { exportData, total, loading, getResults, clearCache } = useLeaveReportData()

  useEffect(() => {
    getCompanyData()
  }, [])

  useEffect(() => {
    getResults(reportQuery, 'leave-balance-report')
  }, [reportQuery, getResults])

  const getCompanyData = async () => {
    const data = await getExportFiltersQuery()
    setCompanyData(data.data)
  }

  const handleFiltersChange = (filters) => {
    clearCache()
    const existingStartDate = reportQuery.startDate
    const existingEndDate = reportQuery.endDate
    const existingPeriod = reportQuery.period
    const query: IBalanceReportQuery = isEmpty(filters)
      ? (() => { //on clearing filters we need to remove userStatuses from default query
        const newQuery = { ...defaultQuery }
        delete newQuery.userStatuses
        return newQuery
      })()
      : { ...reportQuery, ...filters, page: 1 }
    query.lastId = undefined
    query.startDate = existingStartDate
    query.endDate = existingEndDate
    query.period = existingPeriod
    setReportQuery(query)
  }

  const handlePeriodChange = (dates: [Dayjs, Dayjs], period?: ReportPeriod) => {
    if (!period) {
      period = reportQuery.period || 'CURRENT_YEAR'
    }
    clearCache()
    const query = { ...reportQuery, page: 1, startDate: dates[0].format('YYYY-MM-DD'), endDate: dates[1].format('YYYY-MM-DD'), period }
    query.lastId = undefined
    setReportQuery(query)
  }

  const handleDisplayChange = (display: Display) => {
    setDisplay(display)
  }

  const loadNext = () => {
    const query = { ...reportQuery, lastId: last(exportData as IExportLeaveBalanceData[])?.userId, page: (reportQuery.page as number) + 1 }
    setReportQuery(query)
  }

  const loadPrevious = () => {
    const query = { ...reportQuery, page: Math.max((reportQuery.page as number) - 1, 1) }
    setReportQuery(query)
  }

  return (
    <div className='main-content' id='report'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id="export.leaveBalanceReport" />
        </div>
      </div>
      {companyData && <LeaveBalanceReport
        companyData={companyData}
        defaultDates={defaultDates}
        onFiltersChange={handleFiltersChange}
        onPeriodChange={handlePeriodChange}
        onDisplayChange={handleDisplayChange}
        display={display}
        loadNext={loadNext}
        loadPrevious={loadPrevious}
        download={() => setOpenDownloadModal(true)}
        loading={loading}
        data={exportData as IExportLeaveBalanceData[]}
        page={reportQuery.page || 1}
        total={total}
      />}
      <ExportModal
        open={openDownloadModal}
        onClose={() => setOpenDownloadModal(false)}
        total={total}
        reportQuery={reportQuery}
        reportType={reportType}
        title='export.leaveBalanceReport'
        display={display}
        authUser={authUser}
      />
    </div>
  )
}

export default LeaveBalanceReportPage
