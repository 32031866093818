export enum FrontendUrls {
  connect = '/connect',
  signin = '/signin',
  signup = '/signup',
  slackPostInstall = '/slack/post-installation',
  installSlackBot = '/slack/install-bot',
  kats = '/kats-kingdom',
  externalConnect = '/external-connect',
  dashboard = '/app/dashboard',
  outOfOffice = '/app/out-of-office',
  createCompany = '/create-company',
  createCompanyStep1 = '/create-company/step1',
  createCompanyStep2 = '/create-company/step2',
  createCompanyStep3 = '/create-company/step3',
  microsoftSaasCreateCompany = '/microsoft-saas/create-company',
  microsoftSaasCreateCompanyWelcome = '/microsoft-saas/create-company/welcome',
  microsoftSaasCreateCompanyDetails = '/microsoft-saas/create-company/company-details',
  microsoftSaasCreateCompanyAssignLicenses = '/microsoft-saas/create-company/assign-licenses',
  microsoftSaasCreateCompanySetupBot = '/microsoft-saas/create-company/setup-ms-bot',
  subscription = '/subscription',
  users = '/users',
  importEmailUsers = '/users/import-email-users',
  googlePermissions = '/vt-google-permissions',
  accountSettings = '/app/settings/personal',
  calendarPage = '/app/calendar',
  calendarSyncPage = '/app/calendar/sync',
  calendarSyncPageIcalCreate = '/app/calendar/sync/ical/create',
  calendarSyncPageIcalSummary = '/app/calendar/sync/ical/summary',
  calendarSyncPageGoogleConnect = '/app/calendar/sync/google/connect',
  calendarSyncPageGoogleCreate = '/app/calendar/sync/google/create',
  calendarSyncPageGoogleUpdate = '/app/calendar/sync/google/update',
  calendarSyncPageOutlookConnect = '/app/calendar/sync/outlook/connect',
  calendarSyncPageOutlookCreate = '/app/calendar/sync/outlook/create',
  calendarSyncPageOutlookUpdate = '/app/calendar/sync/outlook/update',
  calendarSyncPageGoogleReconnect = '/app/calendar/sync/google/reconnect',
  calendarSyncPageOutlookReconnect = '/app/calendar/sync/outlook/reconnect',
  aiAssistant = '/app/ai-assistant',
  reports = '/app/reports',
  export = '/app/reports/export',
  companyLogs = '/app/reports/company-logs',
  scheduledReports = '/app/reports/scheduled-reports',
  addons = '/app/add-ons',
  locations = '/app/settings/locations',
  departments = '/app/settings/departments',
  leaveTypes = '/app/settings/leave-types',
  imports = '/app/imports',
  import = '/app/import',
}